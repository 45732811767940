@use '../abstracts/variables_new.scss' as *;

.default-attachments-table {
  max-width: 100%;
  min-width: auto;

  .checkbox-v2__label {
    text-wrap: nowrap;
  }

  &__column {
    &.checkbox {
      width: 72px;
    }

    &.size {
      width: 100px;
    }

    &.mode {
      width: 200px;
    }

    &.name {
      min-width: 300px;
    }

    &.conditions {
      width: 230px;
    }

    &.date {
      width: 130px;
    }

    &.weight {
      width: 100px;
    }

    &.actions {
      width: 160px;
    }
  }
}