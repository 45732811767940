@use '../abstracts/variables' as *;

.statuses {

  h6 {
    margin-bottom: 19px;
  }

  ul {

    > li {
      display: flex;
      align-items: center;
      color: $secondary-text-color;
      font-size: 0.8125rem;
      margin-bottom: 4px;
      padding: 10px 9px 9px 12px;
      cursor: pointer;
      transition: background-color .3s ease;
      width: calc(100% + 18px);
      margin-left: -9px;
      border-radius: 3px;

      &:last-child {
        margin-bottom: 0;
      }

      &:hover,
      &.active {
        background-color: $primary-light-color;
      }

      span {
        background-color: $status-color;
        width: 10px;
        height: 10px;
        border-radius: 50%;
        margin-right: 20px;
        display: block;
      }

      &.ready,
      &.ok,
      &.COMPLETED {

        span {
          background-color: $primary-color;
        }
      }
      &.draft {

        span {
          background-color: $tertiary-color;
        }
      }
      &.in-progress,
      &.attente,
      &.SIGNING_PENDING {

        span {
          background-color: $status-color-3;
        }
      }
      &.closed {

        span {
          background-color: $quinary-color;
        }
      }
      &.expired {

        span {
          background-color: $senary-color;
        }
      }
      &.cancelled {

        span {
          background-color: $secondary-color;
        }
      }
    }
  }
}