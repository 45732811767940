@use '../abstracts/variables' as *;

.variables__text {
  white-space: pre-wrap !important;
}

.document-detail-variables {
  padding: 39px 36px;
  // padding-bottom: 800px;

  @media (max-width: $break-sm-max) {
    padding: 30px 15px;
  }

  &__head {
    margin-top: 32px;

    &_top {
      margin-bottom: 16px;
      display: flex;
      align-items: center;
      justify-content: space-between;

      .form-input {
        // width: 140px;
        margin-bottom: 0;
      }

      .doc-variable-el {
        display: inline;
        margin: 0 10px 0 0;

        &.tooltip-wrapper {
          display: inline-block;
        }
      }

      .doc-variable-el--with-icon {
        display: inline-block;

        .icon {
          right: 0px !important;
        }
      }

      @media (max-width: $break-sm-max) {
        display: block;

        h2 {
          margin-bottom: 10px;
        }
      }
    }

    h3 {
      color: $primary-text-color;
      font-size: 1.125rem;
      font-weight: 500;
    }
  }

  &__body {

    > div.variables:first-child {

      .document-detail-variables__head {
        margin-top: 0;
      }
    }

    section {
      margin-bottom: 50px;
    }

    h4 {
      color: $primary-text-color;
      font-weight: 700;
      margin-bottom: 16px;

      span {
        border-bottom: 2px solid $primary-dark-color;
      }
    }
  }
  .select_input_wrapper {
    margin-left: 10px;
    margin-right: 10px;

    &.multiselect {

      div {
        display: flex;
        align-items: center;
      }
    }
  }
  .textarea-el {

    .textarea-lines {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      cursor: pointer;
    }
  }
  .date_picker {
    margin-left: 10px;
    margin-right: 10px;
    position: relative;

    .date-lines {
      width: 100%;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      left: 0;
      padding-left: 27px;
      color: #5561FE;
      font-weight: 700;
      font-size: 0.875rem;
      cursor: pointer;
    }

    &.not-empty {
      padding-right: 20px;
      
      button.clear-date-btn {
        position: absolute;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
        background-color: transparent;
        border: 0;
        outline: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 16px;
        height: 16px;
        color: $primary-color;
        cursor: pointer;
        
        svg {
          font-size: 1.25rem;
        }
      }
    }
  }

  &__foot {
    margin-top: 37px;

    h4 {
      width: calc(100% + 72px);
      margin-left: -36px;
      border-bottom: 1px solid #98BDFF;
      padding-left: 60px;
      margin-bottom: 20px;

      span {
        padding: 5px 7px 5px 11px;
        display: inline-block;
        background-color: #D9E8F5;
        border: 1px solid #98BDFF;
        border-radius: 5px 5px 0 0;
        font-size: 0.75rem;
        color: #204B91;
        line-height: 1;
        border-bottom: 0;
      }
    }
  }

  table {
    width: 100%;
    border-collapse: collapse;
  }

  table, th, td {
    border: 1px solid #41444d;
  }

  thead {
    background-color: #41444d;
  }

  thead td {
    color: white;
  }
  
  td {
    padding: 8px;
  }

  td .form-input {
    margin: 0;
  }

  td .tooltip-wrapper {
    max-width: 80%;
  }

  .row-count-input-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 15px;
    justify-content: flex-end;
  }
  .row-count-input-label {
    margin-right: 10px;
    color: #41444d;
  }
  .row-count-button.button {
    background-color: #41444d;
  }
  .row-count-input {
    width: 20px;
    margin-bottom: 0;
    margin-left: 10px;
    margin-right: 10px;
    text-align: center;
    color: #41444d;
    font-weight: bold;
  }
}