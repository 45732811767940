@use '../abstracts/variables_new.scss' as *;

.attachments-upload {
  padding: 20px;

  @media (max-width: $break-md-max) {
    background-color: #fff;
    height: 100%;
  }




}

.file-upload-v2 {
  background-color: #fff;
  border-radius: 5px;
  border: 2px dashed $primary-color;
  box-shadow: 0 0 15px 0 rgba($shadow-color-1, 0.16);
  height: 210px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 33px;
  margin-top: 8px;

  @media (max-width: $break-md-max) {
    height: 202px;
  }

  &.active {
    // background-color: rgba($primary-color, 0.2);
  }

  &__info {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    p {
      margin-top: 8px;
      font-size: 14px;
      font-weight: 400;
      line-height: 21px;
      color: $color-indigo;

      &.disabled {
        color: $text-purple;
      }
    }

    input {
      display: none;
    }
  }
}

.paste-attachments {
  margin-bottom: 33px;
}

.upload-from-url {

  p {
    font-weight: 500;
    line-height: 16px;
    font-size: 12px;
    color: $color-indigo;

    &.disabled {
      color: $text-purple;
    }

    @media (max-width: $break-md-max) {
      font-size: 0.9375rem;
    }
  }

  .input-v2 {

    &__field {
      background-color: #fff;
    }
  }
}

.attachment-reorder-icon {
  svg {
    fill: #9f88bc !important;
  }

  &:hover {
    svg {
      fill: #fff !important;
    }
  }
}

.no-files-added {
  color: $text-purple;
  font-size: 14px;
  width: fit-content;
  font-weight: 400;
}

.attachment-list-item {
  svg {
    width: 18px;
  }
}