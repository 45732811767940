@use '../abstracts/variables_new.scss' as *;

.dashboard-actions {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  min-height: 40px;
  background-color: $bg-hover;
  margin-bottom: 16px;
  margin-top: -16px;
  padding: 0px 24px;
  z-index: 3;
  width: calc(100% + 48px);
  margin-left: -24px;
  justify-content: space-between;
  font-size: 14px;
  font-weight: 600;

  @media (max-width: $break-lg-max) {
    padding: 4px 8px;
  }

  @media (max-width: $break-md-max) {
    margin-top: 0px;
  }

  &--documents,
  &--signatures {
    position: sticky;
    top: 0px;

    @media (max-width: $break-md-max) {
      position: static;
      top: 0;
    }
  }

  &--attachments {
    position: sticky;
    top: 172px;
    margin-right: -24px;

    @media (max-width: $break-md-max) {
      position: static;
      top: 0;
    }
  }

  @media (max-width: $break-md-max) {
    flex-direction: column;
    padding-left: 0;
    width: 100%;
    height: initial;
    margin-bottom: 0;
    margin-left: 0;
    position: relative;
    height: 40px;
    margin-bottom: 16px;
  }

  &__selected {
    display: flex;
    align-items: center;
    padding: 4px 8px 4px 8px;
    border-radius: 4px 0px 0px 0px;
    gap: 8px;

    @media (max-width: $break-md-max) {
      margin-right: 0;
      height: 40px;
      width: 100%;
      padding: 0 74px 0 16px;
    }

    .number {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 23px;
      height: 23px;
      border-radius: 50%;
      background-color: $primary-color;
      color: #fff;
      margin-right: 16px;
      font-size: 0.875rem;
      font-weight: 300;
      line-height: 1.36;

      @media (max-width: $break-md-max) {
        width: 31px;
        min-width: 31px;
        height: 31px;
        font-size: 1.1875rem;
        margin-right: 10px;
      }
    }

    p {
      font-size: 14px;
      font-weight: 600;
      line-height: 1.25;
      white-space: nowrap;
      color: $color-indigo;

      @media (max-width: $break-xl-max) {
        span {
          display: none;
        }
      }

      @media (max-width: $break-lg-max) {
        font-size: 11px;
      }

      @media (max-width: $break-md-max) {
        line-height: 1.06;
        max-width: none;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
      }
    }
  }

  &__buttons_parent {
    background: rgb(0 0 0 / 22%);
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  &__buttons_overlay {
    background: rgb(0 0 0 / 22%);
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: none;
    z-index: 1;

    &.active {
      display: flex;
    }
  }

  &__buttons {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    align-self: stretch;
    gap: 12px;
    margin: auto;

    @media (max-width: $break-xl-max) {
      padding-right: 0px;
    }

    @media (max-width: $break-lg-max) {
      padding-right: 0px;
    }

    @media (max-width: $break-md-max) {
      flex-direction: column;
      justify-content: center;
      padding-right: 0;
      position: fixed;
      bottom: 0px;
      left: 0;
      width: 100%;
      background: #fff;
      border-radius: 40px 40px 0 0;
      padding: 36px 24px 24px;
      gap: 8px;
      display: none;
      z-index: 2;

      &.active {
        display: flex;
      }
    }

    a, .action {
      cursor: pointer;
      display: flex;
      align-items: center;
      height: 32px;
      padding: 0 4px;
      border-radius: 4px;
      gap: 4px;
      transition: background-color .3s ease;
      color: $color-indigo;
      //styleName: Header/H4;
      font-size: 14px;
      font-weight: 600;
      line-height: 16px;

      @media (max-width: $break-xl-max) {
        &.icon-move {
          display: none;
        }
      }

      @media (max-width: $break-md-max) {
        width: 100%;
        background: $bg-purple2;
        padding: 0 16px;
        height: 48px;
        gap: 16px;
      }

      &:hover {
        background-color: #fff;
      }

      .svg-icon {
        width: 24px;
      }

      img {
        display: block;
        max-width: 15px;
      }
    }
  }

  &__close {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    padding: 0 16px 0 12px;
    border-radius: 4px;
    height: 32px;
    gap: 8px;

    &:hover {
      background-color: #fff;
    }

    span.text {
      line-height: 1;
      color: $color-indigo;
    }

    @media (max-width: $break-xl-max) {
      span.text {
        display: none;
      }
    }

    @media (max-width: $break-md-max) {
      position: absolute;
      top: 4px;
      right: 16px;
      width: 32px;
      height: 32px;
      padding: 0;

      svg {
        width: 18px;
        height: 18px;
      }

      span.text {
        display: none;
      }

      &:hover {
        background-color: #fff;
      }
    }
  }

  &__more {
    display: flex;
    align-items: center;
    justify-content: center;
    align-self: stretch;
    cursor: pointer;
    display: none;

    &:hover {
      background-color: $color-indigo;

      svg {
        fill: #fff
      }
    }

    &.active {
      background: $color-indigo;

      svg {
        fill: #fff
      }
    }

    @media (max-width: $break-md-max) {
      display: flex;
      position: absolute;
      top: 4px;
      right: 54px;
      width: 32px;
      height: 32px;
      border-radius: 5px;

      svg {
        width: 18px;
        height: 18px;
      }
    }
  }
}