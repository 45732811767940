@use '../abstracts/variables_new.scss' as *;

.radio-group {

  &__label {
    font-size: 1rem;
    font-weight: 600;
    line-height: 1.36;
    margin-bottom: 7px;

    @media (max-width: $break-md-max) {
      font-size: 1.125rem;
      margin-bottom: 12px;
    }
  }

  &__items {
    display: flex;
    gap: 24px;
  }

  &__radio {
    margin-bottom: 7px;

    @media (max-width: $break-md-max) {
      margin-bottom: 10px;
    }

    &:last-child {
      margin-bottom: 0;
    }

    label {
      display: flex;
      align-items: center;

      p {
        line-height: 1.17;
        letter-spacing: -0.14px;
        font-weight: 600;
        font-size: 14px;
        color: #000;
      }
    }

    input {
      display: none;

      &:checked~.radio-group__radio_check {
        border: 2px solid #E8025F;

        .radio-group__radio_check_center {
          display: block;
        }
      }
    }

    &_check {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 20px;
      height: 20px;
      border: 2px solid #78909C;
      background-color: #fff;
      border-radius: 50%;
      margin-right: 6px;

      &_center {
        display: none;
        width: 10px;
        height: 10px;
        border-radius: 50%;
        background-color: #E8025F;
      }

      @media (max-width: $break-md-max) {
        width: 20px;
        height: 20px;
        margin-right: 8px;
      }
    }
  }
}