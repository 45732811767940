@use '../abstracts/variables_new.scss' as *;

.template-box-mobile {
  border-radius: 5px;
  box-shadow: 0 0 2px 0 rgba(#000, 0.16);
  padding: 14px 74px 13px 20px;
  position: relative;
  margin-bottom: 7px;
  background-color: #fff;

  h3 {
    font-size: 1.125rem;
    font-weight: 600;
    line-height: 1;
    color: $primary-color;
    margin-bottom: 2px;
  }

  p {
    font-size: 1rem;
    line-height: 1.31;
    color: $text-color-1;
  }

  .preview {
    position: absolute;
    top: 12px;
    right: 12px;
    width: 24px;
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;

    svg {
      width: 16px;
    }
  }
}