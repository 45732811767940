@use '../abstracts/variables_new.scss' as *;

.alert-v2 {
  color: $color-indigo;

  &__head {
    text-align: center;
    padding: 36px 32px 12px 32px;

    h3 {
      font-weight: 600;
      font-size: 1.125rem;
      color: $color-indigo;
      word-break: break-word;
      @media (max-width: $break-md-max) {
        font-size: 1.25rem;
      }
    }
  }

  &__body {
    padding: 0 32px;

    p {
      font-size: 0.85rem;
      line-height: 1.33;
      text-align: center;
      white-space: pre-wrap;

      @media (max-width: $break-md-max) {
        font-size: 0.9375rem;
      }
    }
  }

  &__foot {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 24px 32px;
    gap: 10px;

    @media (max-width: $break-md-max) {
      flex-wrap: wrap;
    }
  }
}