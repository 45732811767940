@use '../abstracts/variables_new.scss' as *;
@use '../abstracts/mixins.scss' as *;

.settings-content {
  @include collectionContent;
  position: relative;
  overflow: auto;
  @include hideScrollbar();

  &__nav {
    @media (max-width: $break-md-max) {}

    ul {

      li {
        margin-bottom: 8px;

        a {
          display: flex;
          align-items: center;
          color: $color-indigo;
          height: 40px;
          border-radius: 8px;
          padding: 8px 8px 8px 12px;
          position: relative;
          transition: background-color .3s ease;
          font-size: 14px;
          font-weight: 600;
          gap: 8px;

          &.active,
          &:hover {
            background-color: $bg-hover;
          }
        }
      }
    }
  }
}