@use '../abstracts/variables_new.scss' as *;

.signature-modal-v2 {
  font-size: 0.875rem;

  .modal-v2__content {
    border-radius: 8px;
    max-width: 1191px;
  }

  .signature-modal-v2-inner {

    &__tabs {
      display: flex;

      @media (max-width: $break-md-max) {
        flex-direction: column;
      }

      .select-label {
        //styleName: Body/Body M - Bold;
        font-size: 12px;
        font-weight: 500;
        line-height: 16px;
        text-align: left;
        padding-left: 8px;
        margin-bottom: 4px;
        color: $color-indigo;
      }

      .label {
        font-size: 14px;
        font-weight: 600;
        line-height: 20px;
        color: $color-indigo;
      }

      .form-text {
        font-size: 12px;
        font-weight: 500;
        line-height: 16px;
        color: $color-indigo;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        flex-wrap: wrap;
        gap: 10px;
      }
    }

    &__head {
      background: rgba($bg-hover, 0.33);
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-bottom: 3px solid #fff;
      padding: 32px 32px;
      height: 112px;

      @media (max-width: $break-md-max) {
        flex-direction: column;
        height: initial;
        align-items: flex-start;
      }
    }

    &__sidebar {
      padding: 32px 16px 32px 32px;
      width: 278px;
      flex: 0 0 278px;
      background: rgba($bg-hover, 0.33);

      @media (max-width: $break-md-max) {
        padding: 20px 32px;
        width: calc(100%);
        flex: 0 0 100%;
      }

      ul {
        padding-left: 0px;

        li {
          padding-bottom: 8px;
          text-align: center;
          cursor: pointer;
          margin: 0 0px 16px;
          position: relative;
          flex-grow: 1;
          font-size: 14px;
          font-weight: 600;
          line-height: 21px;
          text-align: left;
          color: $bg-default;
          display: flex;
          align-items: center;
          gap: 16px;

          span {
            display: flex;
            background: $bg-default;
            width: 32px;
            height: 32px;
            border-radius: 50px;
            align-items: center;
            justify-content: center;
            color: #fff;
          }

          @media (max-width: $break-md-max) {
            font-size: 0.9375rem;
          }

          &:last-child {
            margin-bottom: 0;
          }

          &.no-cursor {
            cursor: default;
          }

          &.active {
            color: $color-indigo;

            span {
              background: $color-indigo;
            }
          }
        }
      }
    }

    &__body {
      padding: 15px 20px 0;

      @media (max-width: $break-sm-max) {
        padding: 15px 10px 0;
      }

      .signature-form {

        &__subtitle {
          font-size: 0.9375rem;
          font-weight: 600;
          margin-bottom: 10px;
          display: flex;
          align-items: center;
          justify-content: space-between;
          // max-width: 711px;
          position: relative;

          @media (max-width: $break-md-max) {
            font-size: 1rem;
          }

          &--2 {
            margin-bottom: 10px;
          }

          &--3 {
            margin-bottom: 5px;
          }

          .icon-button {
            width: 50px;
            height: 38px;
            align-self: flex-end;
          }

          .users-dropdown {
            min-width: 200px;
            max-height: 185px;
            overflow-y: auto;

            @media (max-width: $break-md-max) {
              max-width: 290px;
            }

            .input-v2 {
              position: sticky;
              top: 0;
              width: 100%;
              margin-bottom: 0;
              // margin-left: -10px;
              // margin-top: -10px;
              // width: calc(100% + 20px);
              // padding: 10px;
              background-color: #fff;

              &__field {
                height: auto;
              }
            }

            p {
              padding: 5px;
              border-radius: 2px;
              cursor: pointer;
              transition: background-color .3s ease;

              @media (max-width: $break-md-max) {
                font-size: 0.9375rem;
              }

              &:hover {
                background-color: $bg-color-1;
              }

              &.no-data {
                cursor: default;

                &:hover {
                  background-color: transparent;
                }
              }
            }
          }
        }

        &__field-groups {
          margin-bottom: 15px;
        }

        &__field-group {
          display: flex;

          @media (max-width: $break-md-max) {
            flex-wrap: wrap;
          }

          .input-v2 {
            width: 50%;
            min-width: 50%;

            &:first-child {
              padding-right: 5px;
            }

            &:last-child {
              padding-left: 5px;
            }

            @media (max-width: $break-md-max) {
              width: 100%;
              min-width: 100%;

              &:first-child {
                padding-right: 0;
              }

              &:last-child {
                padding-left: 0;
              }
            }
          }
        }

        &__group-actions {
          display: flex;
          align-items: center;

          .align-right {
            margin-left: auto;
          }
        }

        &__actions {
          display: flex;
          align-items: center;
          justify-content: center;
          padding-bottom: 20px;

          .credits-wrapper {
            margin-left: 10px;
            display: flex;
            align-items: center;

            @media (max-width: $break-md-max) {
              font-size: 0.9375rem;
            }

            .loader-v2 {
              margin-left: 4px;

              @media (max-width: $break-md-max) {
                margin-left: 8px;
              }
            }
          }
        }
      }
    }

    &__section {
      margin-bottom: 30px;

      &--checkbox {
        margin-bottom: 16px;
      }
    }
  }

  &__template {

    &_choose {
      display: flex;
      align-items: center;

      .select-v2 {
        max-width: 340px;
        margin-right: 10px;
      }

      a {
        color: $primary-color;

        @media (max-width: $break-md-max) {
          font-size: 0.9375rem;
        }
      }
    }

    &_update {
      display: flex;
      align-items: center;
      flex-wrap: wrap;

      .btn {
        margin-right: 8px;

        @media (max-width: $break-md-max) {
          margin-bottom: 8px;
        }
      }
    }
  }

  .checkbox-wrapper {
    margin-bottom: 15px;
  }

  .signature-positions-v2 {
    display: flex;
    margin-bottom: 10px;

    &__page {
      display: flex;
      align-items: center;
      height: 23px;
      padding: 0 10px;
      border-radius: 12px;
      margin-right: 8px;
      background-color: $bg-color-1;
      text-transform: capitalize;

      @media (max-width: $break-md-max) {
        font-size: 0.9375rem;
      }
    }
  }

  .message-v2 {
    margin-bottom: 15px;
    white-space: pre-wrap;
  }
}

.signature-heading {
  display: flex;
  align-items: center;
  gap: 16px;

  &--title {
    gap: 8px;
    display: flex;
    flex-direction: column;
  }

  h1 {
    font-size: 24px;
    font-weight: 600;
    line-height: 32px;

  }

  h2 {
    font-size: 14px;
    font-weight: 600;
    line-height: 16px;
    text-align: left;

  }

  @media (max-width: $break-md-max) {
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;

    button {
      margin-left: 0;
      padding: 0;
    }
  }
}

// START OF CODES BY SHAYAN
.tab-content {
  padding: 20px 32px 60px;
  color: #000;
}

.title-text {
  display: flex;
  margin-bottom: 1rem;
  color: $color-indigo;
  font-size: 18px;
  font-weight: 600;
}

.react-tel-input {
  .flag-dropdown {
    background-color: $bg-hover;
    border: 0;
    border-radius: 3px 0 0 3px;
  }

  .selected-flag:hover,
  .selected-flag:focus {
    background-color: $bg-hover;
    cursor: default;
  }

  .form-control {
    border: 2px solid $bg-hover;
    width: 100%;
    color: $text-color-1;
    //styleName: Body/Body L - Bold;
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
  }
}



.heading {
  min-height: 40px;
  padding: 8px 8px 8px 16px;
  margin-bottom: 16px;
  display: flex;
  align-items: center;
  cursor: pointer;
  background: $bg-default;
  justify-content: space-between;
  border-radius: 4px;

  .icon {
    display: flex;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background: rgb(209 224 254);
    align-items: center;
    justify-content: center;
    display: inline-block;
    padding: 7px;

    svg {
      width: 15px;
      height: 15px;
    }
  }

  .text {
    //styleName: Header/H3;
    font-size: 18px;
    font-weight: 600;
    line-height: 24px;
    color: $color-indigo;
  }
}

.error-message {
  border: 1px solid red;
  color: red;
  padding: 3px;
  text-align: center;
  margin: 2rem 0;
}

.top-text {
  margin-bottom: 16px;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  color: $text-color-1;
}

.chevron {
  transform: rotate(90deg);
  transition: all 0.3s;
  width: 8.5px;
  margin: 6px;
  text-align: center;
  display: inline-block;

  &.open {
    transform: rotate(-90deg);
  }
}

.custom-radio {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: 1rem;
  gap: 16px;
  //styleName: Component/Action;
  font-size: 14px;
  font-weight: 600;
  line-height: 21px;
  color: #000;
  padding: 4px;

  div {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 8px;
  }

  [type="radio"]:checked,
  [type="radio"]:not(:checked) {
    position: absolute;
    left: -9999px;
  }

  [type="radio"]:checked+label,
  [type="radio"]:not(:checked)+label {
    position: relative;
    padding-left: 36px;
    cursor: pointer;
    line-height: 20px;
    display: inline-block;
    color: #000;
  }

  [type="radio"]+label:before,
  [type="radio"]+label:before {
    content: '';
    position: absolute;
    left: 2px;
    top: 0;
    width: 20px;
    height: 20px;
    border: 2px solid #78909C;
    border-radius: 100%;
    background: #fff;
  }

  [type="radio"]:checked+label:before {
    content: '';
    border: 2px solid #E8025F;
  }

  [type="radio"]:checked+label:after,
  [type="radio"]:not(:checked)+label:after {
    content: '';
    width: 10px;
    height: 10px;
    background: #E8025F;
    position: absolute;
    top: 5px;
    left: 7px;
    border-radius: 100%;
    -webkit-transition: all 0.2s ease;
    transition: all 0.2s ease;
  }

  [type="radio"]:not(:checked)+label:after {
    opacity: 0;
    -webkit-transform: scale(0);
    transform: scale(0);
  }

  [type="radio"]:checked+label:after {
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

.table-responsive {
  display: block;
  width: 100%;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: -ms-autohiding-scrollbar;
}

table {
  width: 100%;
  border-collapse: collapse;
  border-radius: 8px;

  thead {
    position: relative;
    background: $bg-purple1;

    th {
      padding: 8px 8px;
      height: 56px;
      text-align: left;
      color: $color-indigo;
      font-size: 14px;
      font-weight: 600;
      position: relative;

      div {
        display: flex;
        align-items: center;
      }
    }

    @media (max-width: $break-md-max) {
      display: none;
    }
  }

  tbody {
    tr {

      &:nth-of-type(even) {
        background-color: $bg-purple2;
      }

      td {
        height: 64px;
        padding: 4px 8px;
        //styleName: Body/Body L - Bold;
        font-size: 14px;
        font-weight: 500;
        line-height: 20px;
        color: $text-color-1;

        .field-label {
          display: none;
        }

        @media (max-width: $break-md-max) {
          width: 100%;
          height: auto;

          .field-label {
            display: block;
            font-size: 14px;
            font-weight: 600;
            line-height: 20px;
            margin-bottom: 4px;
            color: $color-indigo;
          }
        }
      }

      @media (max-width: $break-md-max) {
        display: flex;
        flex-wrap: wrap;
        flex-direction: row;
        align-items: center;
        margin-bottom: 30px;
      }
    }
  }
}

.actions-column {
  display: flex;
  gap: 8px;
  padding: 0 12px;

  .order-btn {
    display: flex;
    align-items: center;

    svg {
      fill: rgba($color-indigo, 0.5);
    }
  }

  .delete-btn {
    color: $color-indigo;
    width: 24px;

    svg {
      width: 18px;
    }
  }
}

.phone-column {
  display: flex;
  align-items: center;

  &.invalid {
    .react-tel-input {
      border: 2px solid #D5000033;

      border-radius: 6px;
    }
  }
}

.action-delete {
  &--small {
    display: flex !important;
    justify-content: center;
    align-items: center;
    @media (max-width: $break-md-max) {
      display: none !important;
    }
  }
  &--large {
    display: none !important;
    @media (max-width: $break-md-max) {
      display: flex !important;
    }
  }
}

.delete {
  // height: 20px;
  // background: none;
  // border: none;
  // cursor: pointer;

  svg {
    fill: rgb(208, 42, 42);
  }

  span {
    svg {
      fill: $color-indigo;
    }
  }
}

.custom-select {
  border: solid 2px $bg-hover;
  font-size: 100%;
  font-weight: inherit;
  outline: none;
  padding: 10px 16px;
  height: 40px;
  vertical-align: middle;
  width: 100%;
  max-width: 350px;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
  -webkit-appearance: button;
  -moz-appearance: button;
  appearance: button;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  //styleName: Body/Body L - Bold;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;

  &.invalid {
    border: 2px solid #D5000033;
  }
}

.email-parent {
  display: flex;
  align-items: center;

  div {
    width: 100%;
  }
}

.custom-input {
  border: solid 2px $bg-hover;
  font-size: 100%;
  font-weight: inherit;
  outline: none;
  padding: 10px 16px;
  height: 40px;
  vertical-align: middle;
  width: 100%;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
  -webkit-appearance: button;
  -moz-appearance: button;
  appearance: button;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  //styleName: Body/Body L - Bold;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  color: $text-purple;

  &.number {
    width: min-content;
    max-width: 70px;
    min-width: 50px;
  }

  &.invalid {
    border: 2px solid #D5000033;
  }

  &:focus {
    border-color: #E8025F;
  }

}

.not-valid {
  display: block;
  margin-top: 4px;
  color: #D63F3F;
}

.info {
  width: 15px;
  min-width: 15px;
  display: inline-block;
  margin: 0 0 0 0.5rem;
}

.browse {
  width: 20px;
  padding: 5px;
  height: 20px;
  display: inline-block;
  margin: auto;
  cursor: pointer;


}

.search {
  li {


    >div {
      display: inline-block;
      align-items: center;

      >span {
        cursor: pointer;
        padding: 3px 0;
        min-height: 40px;
        display: flex;
        align-items: center;
      }
    }

    span.svg-icon {
      width: 10px;
      margin-right: 10px;
    }
  }

  .search-input {
    display: inline-block;
    margin-left: 10px;
    position: relative;

    button {
      position: absolute;
      left: 5px;
      width: 30px;
      border: 0;
      background: none;
      top: 12px;
      cursor: pointer;
    }

    input {
      padding-left: 35px;
    }
  }
}

.form-group {
  label {
    display: flex;
    align-items: center;
    margin: 1rem 0;
  }

  input,
  textarea {
    max-width: 500px;
  }
}

.preview {
  margin-bottom: 20px;
  display: flex;
  gap: 30px;
  flex-wrap: wrap;

  >div {
    padding: 0px 8px 8px;
    margin-bottom: 10px;
    border-radius: 5px;
    width: 176px;
    position: relative;
    background: $bg-purple2;
    cursor: pointer;

    &.selected {
      background: $bg-selected;

      .react-pdf__Document {
        border: solid 2px #4467da;
      }

      &.over-size {
        background: #ffe4ee;

        .react-pdf__Document {
          border: solid 2px #ff5d5d;
        }
      }
    }

  }

  .index {
    position: absolute;
    top: 10px;
    left: 10px;
  }

  .delete {
    position: absolute;
    top: 10px;
    right: 20px;
    cursor: pointer;
  }

  footer {
    height: 40px;
    padding: 10px 0px;

    .file-name {
      font-size: 12px;
      color: $text-purple;
    }
  }
}

.pdf-preview-wrapper {
  display: flex;
  height: 100%;

  .react-pdf__Document {
    height: 200px;
    width: 100%;
    position: relative;
    background: #fff;
    border: solid 2px #ddd;
    border-radius: 4px;
  }

  .document-preview-dummy {
    height: 200px;
    width: 100%;
    position: relative;
    background: #fff;
    border: solid 2px #ddd;
    border-radius: 4px;
  }
}

.upload-text {
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  text-align: left;
  color: $text-color-1;
  margin-bottom: 40px;
}

.information-box {
  background: $bg-purple2;
  padding: 16px;
  border-radius: 8px;
  color: $color-indigo;
  display: flex;
  gap: 6px;
  font-size: 14px;
  font-weight: 500;
  margin: 0 !important;
}

.rpv-core__inner-pages {
  overflow: hidden;
}

span.over-size {
  color: red;
}

.btn-separator {
  width: 2px;
  height: 16px;
  background: #ECE8F7;
  border: 0;
  margin: 0 10px;

  @media (max-width: $break-md-max) {
    display: none;
  }
}

.buttons-row {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  column-gap: 8px;
  row-gap: 16px;

  @media (max-width: $break-md-max) {
    margin-top: 16px;
    flex-wrap: wrap;
    justify-content: flex-start;
    width: 100%;
  }
}

// END OF CODES BY SHAYAN

.users-dropdown {
  display: flex;
  flex-direction: column;
  row-gap: 10px;
  max-height: 250px;
  overflow: scroll;
  align-items: flex-start;

  p {
    text-align: end;
    cursor: pointer;
  }
}

.search-row-wrapper {
  padding: 16px 0;
  position: relative;
  width: 100%;
}

.search-row-content {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;

  @media (max-width: $break-md-max) {
    display: flex;
    flex-direction: column;
    gap: 8px;

    .search-input {
      width: 100%;
      margin-left: 0;
    }
    input: {
      width: 100%;
    }
  }
}

.signature-attachments-table {
  max-width: 100%;
  min-width: auto;

  .checkbox-v2__label {
    text-wrap: nowrap;
  }

  &__column {

    &.size {
      width: 100px;
    }

    &.mode {
      width: 200px;
    }

    &.actions {
      width: 50px;
    }
  }

  @media (max-width: $break-md-max) {
    
    &__column {
      &.size {
        width: 100%;
      }

      &.mode {
        width: 100%;
      }

      &.actions {
        width: 100%;
      }
    }
  }
}