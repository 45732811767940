@use '../abstracts/variables_new.scss' as *;
@use '../abstracts/mixins.scss' as *;

.signatures-status-panel {
  position: fixed;
  top: 100px;
  right: -328px;
  height: calc(100% - 100px - 24px);
  width: 328px;
  background-color: $primary-color;
  transition: right .3s ease;
  border-radius: 8px 8px 0 0;

  @media (max-width: $break-md-max) {
    top: 134px;
    height: calc(100% - 110px - 24px);
    background-color: #fff;
    width: 100%;
    z-index: 99;
    right: -100%;
  }

  &__inner {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow-y: auto;
    padding: 19px 16px 24px 24px;

    @media (max-width: $break-md-max) {
      padding: 26px;
    }
  }

  &__head {
    margin-bottom: 24px;
    display: flex;
    align-items: center;

    @media (max-width: $break-md-max) {
      display: none;
    }

    h2 {
      font-weight: 600;
      font-size: 24px;
      line-height: 32px;
      color: $color-indigo;

      span {
        display: inline-block;
        padding-bottom: 1px;
      }
    }
  }

  &__head-mobile {
    display: none;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin-bottom: 37px;
    text-align: center;
    padding: 0 20px;
    position: relative;

    @media (max-width: $break-md-max) {
      display: flex;
    }

    .close {
      position: absolute;
      top: 5px;
      left: -7px;
      width: 20px;
      height: 20px;
      display: flex;
      align-items: center;
      justify-content: center;

      svg {
        width: 14px;
      }
    }

    h2 {
      font-weight: 600;
      font-size: 1.3125rem;
      line-height: 1.33;
    }

    p {
      font-size: 1rem;
      line-height: 1.13;
    }
  }

  &__body {
    padding-top: 16px;

    .recipients-list {

      li {
        position: relative;
        padding-left: 30px;
        margin-bottom: 24px;

        @media (max-width: $break-md-max) {
          padding-left: 52px;
          margin-bottom: 44px;
        }

        &:last-child {
          margin-bottom: 0;
        }

        .letter-circle-v2 {
          position: absolute;
          top: 0;
          left: 0;

          @media (max-width: $break-md-max) {
            width: 38px;
            height: 38px;
            font-size: 1.25rem;
            line-height: 1;
          }
        }

        h4 {
          font-size: 0.875rem;
          font-weight: 600;
          line-height: 1.17;
          color: $primary-color;

          @media (max-width: $break-md-max) {
            font-size: 1.125rem;
            margin-bottom: 4px;
          }
        }

        p {

          &.email {
            line-height: 1.17;
            margin-bottom: 4px;

            @media (max-width: $break-md-max) {
              font-size: 1.0625rem;
              margin-bottom: 13px;
            }
          }

          &.user-status {
            display: flex;
            align-items: center;
            font-size: 0.75rem;
            line-height: 1.27;

            @media (max-width: $break-md-max) {
              font-size: 1.125rem;
            }

            span {
              width: 7px;
              height: 7px;
              display: block;
              border-radius: 100%;
              margin-right: 6px;
              background-color: $primary-color;

              @media (max-width: $break-md-max) {
                width: 13px;
                height: 13px;
                margin-right: 9px;
              }
            }

            @include signatureStatuses;
          }
        }
      }
    }
  }

  .status-item {
    display: flex;
    margin-bottom: 2rem;
    padding: 16px;
    border: 1px solid $bg-hover;
    border-radius: 8px;
    margin-bottom: 16px;

    .type {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 22px;
      flex: 0 0 22px;
      height: 22px;
      background: #1d5083;
      color: #fff;
      font-size: 0.75rem;
      text-transform: uppercase;
      border-radius: 100%;
      margin-right: 12px;
    }

    .name {
      font-weight: 600;
    }

    .sign-status {
      padding: 4px 8px;
      background-color: #FFBC00;
      border-radius: 50px;
      margin-right: 4px;
      font-size: 12px;
      font-weight: 500;
      line-height: 16px;
      display: inline-block;

      &.green {
        color: #fff;
        background-color: $highlighted-color-2;
      }
    }

    .email {
      font-size: 12px;
      font-weight: 500;
      line-height: 16px;
      color: $text-purple;
    }

    .options {
      margin-top: 8px;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      font-size: 14px;
      font-weight: 600;
      line-height: 21px;
      cursor: pointer;
      gap: 8px;
      color: $color-indigo;
      padding: 0px 4px;
      height: 24px;

      span {
        padding: 1px;
      }

      svg {
        width: 18px;
      }
    }
  }

}