@use '../abstracts/variables_new.scss' as *;
@use '../abstracts/mixins.scss' as *;

.input-v2 {
  &__inner {
    position: relative;

    .input-lines {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      padding: 3px 6px;
      font-weight: 400;
      color: $primary-color;
      font-family: $ff-primary;
      cursor: pointer;

      @media (max-width: $break-md-max) {
        font-size: 0.9375rem;
      }

      span {
        position: relative;
        top: -6px;
        left: 4px;
      }
    }

    .pencil {
      position: absolute;
      right: 8px;
      top: 50%;
      transform: translateY(-50%);
      width: 18px;
      color: #E8025F;
    }
  }

  &.error {

    .input-v2__field {
      border-color: #D5000033;
    }
  }

  &.with-icon {

    .input-v2__field {
      padding-left: 24px;
    }
  }

  &__icon {
    position: absolute;
    left: 5px;
    top: 50%;
    transform: translateY(-50%);

    svg {
      display: block;
      width: 15px;
    }
  }

  &__label {
    display: block;
    margin-bottom: 4px;
    color: $color-indigo;
    font-weight: 600;
    font-size: 12px;
    padding-left: 8px;
  }

  &__field {
    outline: 0;
    border: 2px solid $bg-hover;
    border-radius: 4px;
    background-color: transparent;
    height: 40px;
    color: $text-color-1;
    padding: 10px 16px;
    width: 100%;
    display: block;
    //styleName: Body/Body L - SemiBold;
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;

    &.more-right-padding {
      padding-right: 25px;
    }

    @include placeholder {
      color: $text-placeholder;
    }

    &:hover {
      background-color: $bg-hover;
    }

    &:focus {
      border: 2px solid #E8025F;
    }

    &.display-nc {
      @include placeholder {
        color: rgba($color: $text-purple, $alpha: 0.7);
      }
    }
  }

  &__error {
    color: $highlighted-color-3;
    padding: 5px 0;
  }

  &.disabled {
    input {
      // background-color: $bg-color-4 !important;
      color: rgba($color: $text-purple, $alpha: 0.7);
    }
  }

  &.spacing-5 {
    input {
      letter-spacing: 5px;
    }
  }
}