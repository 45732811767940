// @use '../abstracts/variables' as *;
@use '../abstracts/variables_new.scss' as *;

.dashboard-column {
  position: relative;
  display: flex;
  align-items: center;

  .sort {
    display: inline-flex;
    align-items: center;
    color: $quaternary-text-color;
    font-size: 0.875rem;
    cursor: pointer;
    transition: color .3s ease;

    &:hover {
      color: $secondary-text-color;
    }

    svg {
      display: block;
    }

    &.active {
      font-weight: 700;
      color: $primary-text-color;
    }
  }

  &.col-1 {
    flex: 1;
    padding-left: 33px;
    position: relative;

    .checkbox {
      position: absolute;
      top: 50%;
      left: 0;
      transform: translateY(-50%);

      .unchecked {
        color: $senary-bg-color;
      }

      .checked {
        color: $primary-color;
      }
    }

    .icon {
      position: absolute;
      top: 50%;
      left: 0;
      transform: translateY(-50%);
      color: $quinary-text-color;

      svg {
        display: block;
      }
    }
  }

  &.col-2 {
    width: 100px;
    margin-right: 20px;
    font-size: 0.75rem;
  }

  &.col-3 {
    width: 100px;
    margin-right: 20px;
    text-align: center;

    &.doc-date-col {
      width: 150px;
    }
  }

  &.col-4 {
    width: 100px;
    text-align: right;

    &.doc-date-col {
      width: 150px;
    }
  }

  &.signatures-col-2,
  &.signatures-col-3,
  &.signatures-col-4,
  &.signatures-col-5 {
    width: 100px;
    margin-right: 50px;
    font-size: 0.875rem;
  }

  &.signatures-col-4 {
    width: 125px;
  }

  &.signatures-col-5 {
    justify-content: center !important;
    width: 120px;
  }

  &:last-child {
    margin-right: 0 !important;
  }

  &.last {
    margin-right: 0;
    justify-content: flex-end;
  }

  &.center {
    justify-content: center;
    text-align: center;
  }

  &.start {
    justify-content: flex-start;
  }

  &__main {
    cursor: pointer;

    p {

      &.title {
        font-size: 0.9375rem;
        font-weight: 500;
      }

      &.progress {
        margin-top: 3px;
        font-size: 0.75rem;
        color: $quaternary-text-color;
      }
    }
  }

  &--actions {
    display: flex;
    align-items: center;

    a {
      display: block;
      position: relative;
      color: $primary-color;
      margin-left: 20px;
      cursor: pointer;

      svg {
        display: block;
      }

      &.delete {

        .tooltip {
          background-color: $secondary-color;

          &::after {
            border-bottom-color: $secondary-color;
          }
        }
      }

      .tooltip {
        position: absolute;
        top: calc(100% + 5px);
        right: -15px;
        background-color: $primary-color;
        color: $primary-light-color;
        height: 39px;
        border-radius: 3px;
        width: 88px;
        display: none;
        justify-content: center;
        align-items: center;
        z-index: 10;

        &::after {
          bottom: 100%;
          right: 20px;
          border: solid transparent;
          content: " ";
          height: 0;
          width: 0;
          position: absolute;
          pointer-events: none;
          border-color: rgba($primary-color, 0);
          border-bottom-color: $primary-color;
          border-width: 6px;
          margin-left: -6px;
        }
      }

      &.delete {
        color: $secondary-color;
      }

      &:hover {

        .tooltip {
          display: flex;
        }
      }
    }

    .icon-button {
      margin-left: 20px;

      &:hover {
        background-color: $primary-light-color;
      }
    }

    .more-actions {
      position: relative;

      .dropdown {
        position: absolute;
        top: calc(100% + 2px);
        right: 0;
        background-color: $primary-light-color;
        width: 120px;
        padding: 10px;
        border-radius: 6px;
        box-shadow: 0 0 10px rgba($shadow-color, 0.2);
        z-index: 10;

        ul {

          li {
            display: flex;
            align-items: center;
            font-size: 0.875rem;
            color: $nonary-text-color;
            cursor: pointer;

            svg {
              font-size: 1.125rem;
              margin-right: 5px;
            }
          }
        }
      }
    }
  }

  &__dropdown {
    position: absolute;
    right: 0;
    top: calc(100% + 15px);
    min-width: 252px;
    white-space: nowrap;
    background-color: $bg-purple1;
    border-radius: 8px;
    border: 8px solid #fff;
    padding: 8px;
    z-index: 1;
    gap: 8px;
    display: flex;
    flex-direction: column;
    border-radius: 14px;

    &::after,
    &::before {
      bottom: 100%;
      left: 90%;
      border: solid transparent;
      content: " ";
      height: 0;
      width: 0;
      position: absolute;
      pointer-events: none;
    }

    &::after {
      border-color: rgba($primary-light-color, 0);
      border-bottom-color: $primary-light-color;
      border-width: 6px;
      margin-left: -6px;
    }

    &::before {
      border-color: rgba($senary-border-color, 0);
      border-bottom-color: $senary-border-color;
      border-width: 7px;
      margin-left: -7px;
    }

    li {
      font-size: 14px;
      font-weight: 500;
      color: $color-indigo;
      background: #fff;
      min-height: 40px;
      width: 220px;
      cursor: pointer;
      display: flex;
      align-items: center;
      padding: 8px 10px 8px 16px;
      border-radius: 4px;
      gap: 16px;
      &.mobile {
        display: none;

        @media (max-width: $break-md-max) {
          display: flex;
        }
      }

      &.duplicate {
        color: #ffa846 !important;

        svg {
          fill: #ffa846;
        }
      }

      &.archive {
        color: #E8025F !important;

        svg {
          fill: #E8025F;
        }
      }

      &.trash {
        color: $danger-color !important;

        svg {
          fill: $danger-color;
        }
      }

      .icon {
        width: 24px;
        height: 24px;
        display: flex;
        align-items: center;
        justify-content: center;
        &.template-box {
          min-width: 24px;
          height: 20px;
          svg {
            fill: $color-indigo;
          }
        }

        &.add {
          background: linear-gradient(180deg, #482982 0%, #E8025F 49%, #F0463C 67.71%, #FFBC00 100%);
          border-radius: 50%;
          margin: 4px 0px 4px 2px;
          width: 20px;
          height: 20px;
          svg {
            fill: #fff;
            width: 20px;
            height: 20px;
          }
        }

        &.rotate-180 {
          transform: rotate(180deg);
        }
      }

      span {
      }

    }
  }

  .progress-el {
    margin-top: 15px;
  }
}