@use '../abstracts/variables_new.scss' as *;

.main-sidebar {
  background-color: $bg-color-1;
  display: flex;
  flex-direction: column;
  overflow-x: hidden;

  .horizontal-line {
    height: 1px;
    background: rgba(#fff, 0.2);
    margin: 16px auto;
    max-width: 198px;
    width: 100%;
  }

  ul {
    gap: 8px;
    display: flex;
    flex-direction: column;
    li {
      margin: 0 16px;

      a {
        display: flex;
        align-items: center;
        height: 40px;
        border-radius: 8px;
        color: #fff;
        font-size: 14px;
        font-weight: 600;
        line-height: 16px;
        position: relative;
        padding-left: 40px;
        transition: background-color .3s ease;

        span.icon {
          position: absolute;
          top: 50%;
          left: 20px;
          transform: translate(-50%, -50%);
          color: #fff;

          svg {
            display: block;
            fill: #fff;
          }
        }

        &.active {
          background-color: $bg-hover !important;
          color: $color-indigo !important;

          span.icon {
            color: $color-indigo;

            svg {
              fill: $color-indigo;
            }
          }
        }

        &:hover {
          background-color: rgba(#fff, 0.1);
        }
      }
    }
  }

  &__bottom {
    margin-top: auto;
    padding: 15px 0px;
  }

  &--shrinked {
    background-color: $bg-color-1;
    width: 75px !important;
    ul {

      li {
        a {
          width: 40px;
          height: 40px;
          padding-left: 0;

          span {

            // &.icon {
            //   left: 12px;
            //   transform: translate(0, -50%);
            // }

            &.text {
              display: none;
            }
          }

          &.active {
            background-color: $bg-hover;

            span.icon {
              color: $text-color-1;

              svg {
                fill: $text-color-1;
              }
            }
          }
        }
      }
    }
  }
}