@use '../abstracts/variables_new.scss' as *;

.document-main {

  &__actions {
    padding: 0px 24px 0px 24px;
    color: #fff;
    display: flex;
    align-items: center;
    position: sticky;
    top: 0px;
    z-index: 20;
    flex-wrap: wrap;
    background: #fff;
    gap: 8px;

    @media (max-width: $break-md-max) {
      top: 0;
      padding: 24px 16px 24px;
      position: relative;
    }

    border: 2px solid;

    button {

      &.hide-on-800 {

        @media (max-width: 800px) {
          display: none;
        }
      }
    }

    .right-buttons {
      gap: 16px;

      @media (max-width: $break-md-max) {
        gap: 8px;

      }
    }

    &_more {
      position: relative;

      .icon-btn {

        .svg-icon svg {
          width: 7px;
        }
      }

      .more-dropdown {
        position: absolute;
        top: calc(100% + 5px);
        right: 0;
        border-radius: 8px;
        border: 8px solid #fff;
        border-radius: 4px;
        box-shadow: 0 0 10px rgba(#000, 0.16);
        max-width: 280px;

        ul {
          display: flex;
          flex-direction: column;
          gap: 8px;

          li {
            background: #fff;

            &.show-on-800 {
              display: none;

              @media (max-width: 800px) {
                display: block;
              }
            }

            button {
              background-color: transparent;
              outline: 0;
              border: 0;
              display: flex;
              align-items: center;
              border-radius: 4px;
              padding: 0 16px;
              height: 40px;
              font-size: 14px;
              font-weight: 500;
              color: $color-indigo;
              min-width: 0;
              max-width: 100%;
              width: 100%;
              cursor: pointer;
              transition: background-color .3s ease, color .3s ease;
              white-space: nowrap;

              span.text {
                white-space: nowrap;
                text-overflow: ellipsis;
                overflow: hidden;
                display: block;
              }

              svg {
                width: 24px;
              }

              &:hover {
                background-color: $bg-hover;
              }

            }

            &.duplicate {
              span {
                color: #ffa846 !important;
              }

              svg {
                fill: #ffa846;
              }
            }

            &.trash {
              span {
                color: $danger-color !important;
              }

              svg {
                fill: $danger-color;
              }
            }
          }
        }
      }
    }

    .btn-text {

      @media (max-width: $break-md-max) {
        padding: 5px 5px;
        min-width: 32px;
        height: 32px;
        border-radius: 4px;

        span:not(.svg-icon) {
          display: none;
        }

        .svg-icon {
          margin-right: 0;
        }
      }
    }
  }

  &__body {
    margin: 24px 24px;
    border: 1px solid $bg-hover;
    border-radius: 8px;
    flex: 1;
    display: flex;
    flex-direction: column;

    &--attachments {
      border: none;
    }

    @media (max-width: $break-md-max) {
      padding: 23px 14px;
      margin: 0px 16px 80px;
    }
  }

  &__editor-toolbar {
    display: flex;
    align-items: center;
    column-gap: 5px;

    button {
      width: 40px;
      height: 40px;
    }

    @media (max-width: $break-md-max) {
      background: none;
      top: 0px;
      padding: 0px;
      margin-right: 8px;

      button {
        width: 32px;
        height: 32px;
        margin-right: 0;
        background: $bg-hover;
        border-radius: 4px;
      }
    }

    // border-bottom: $primary-color 1px solid;
  }
}

.layout-text {
  //styleName: Header/H3;
  font-size: 18px;
  font-weight: 600;
  line-height: 24px;
  margin-bottom: 24px;
  color: $color-indigo;
}

.layout-label {
  font-size: 14px;
  font-weight: 600;
  line-height: 16px;
  text-align: left;
  gap: 16px;
  align-items: center;
}