@use '../abstracts/variables_new.scss' as *;

.single-document-attachments {

  flex: 1;
  display: flex;
  flex-direction: column;

  .table-responsive {
    display: block;
    width: 100%;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar;
  
    table {
      min-width: 700px;
    }
  }
  
  table {
    width: 100%;
    border-collapse: collapse;
    border-radius: 8px;
    overflow-x: hidden;
    table-layout: fixed;
  
    thead {
  
      th {
        padding: 8px 12px;
        height: 56px;
        text-align: left;
        color: $color-indigo;
        font-size: 14px;
        font-weight: 600;
        position: relative;
        div {
          display: flex;
          align-items: center;
        }
      }
    }
  
    tbody {
      tr {
        position: relative;
        &:nth-of-type(even) {
          background-color: $bg-purple2;
        }
  
        td {
          height: 64px;
          padding: 8px 12px;
          //styleName: Body/Body L - Bold;
          font-size: 14px;
          font-weight: 500;
          line-height: 20px;
          color: $text-color-1;
          word-wrap: break-word;
        }
      }
    }
  }
  
  .actions-column {
    display: flex;
    gap: 8px;
    padding: 0 12px;
  
    .order-btn {
      display: flex;
      align-items: center;
  
      span {
        width: 24px;
        display: flex;
        justify-content: center;
      }
  
      svg {
        fill: rgba($color-indigo, 0.5);
        width: 18px;
      }
  
  
    }
  
    .delete-btn {
      color: $color-indigo;
      width: 24px;
  
      svg {
        width: 18px;
      }
    }
  }

  @media (max-width: $break-md-max) {
    padding-bottom: 130px;
  }

  &__head {
    // margin-bottom: 15px;
    width: 100%;

    h2 {
      
      font-size: 18px;
      color: $color-indigo;
      font-weight: 600;
      // background: $bg-hover;
      padding: 12px 12px;
    }
  }

  &__actions {
    display: flex;

    @media (max-width: $break-md-max) {
      position: fixed;
      bottom: 133px;
      left: 0;
      width: 100%;
      z-index: 10;
      box-shadow: 0 0 28px 0 rgba(#000, 0.1);
    }
  }

  &__body {

    .attachments-table {

      &__head {
        margin-bottom: 11px;

        @media (max-width: $break-md-max) {
          display: none;
        }

        .attachments-table-column {
          cursor: pointer;

          &:nth-child(1) {
            padding-bottom: 3px;

            .svg-icon {
              margin-left: 0px;
            }
          }

          p {
            font-weight: 600;
            font-size: 0.9375rem;
            line-height: 1.36;
            text-align: center;
            border-bottom: 1px solid transparent;

            &.active {
              border-bottom-color: $highlighted-color-1;
            }
          }

          .svg-icon {
            margin-left: 8px;

            svg {
              width: 16px;
            }
          }
        }
      }

      &__body {

        .attachments-table-row {
          border-radius: 5px;
          box-shadow: 0 0 2px 0 rgba(#000, 0.16);
          background-color: #fff;
          height: 30px;
          align-items: center;
          margin-bottom: 3px;
          position: relative;

          @media (max-width: $break-md-max) {
            // height: auto;
            // display: block;
            // padding: 9px 24px 11px 48px;
            // margin-bottom: 10px;
            // position: relative;
          }

          &.selected {
            background-color: $bg-color-5;
          }
        }

        .attachments-table-column {
          min-width: 0;

          p {
            line-height: 1.27;
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
          }

          &:nth-child(1) {
            z-index: 2;

            @media (max-width: $break-md-max) {
              position: absolute;
              top: 11px;
              left: 11px;
              width: auto;
              min-width: 1px;
            }
          }

          &:nth-child(2) {
            padding-right: 10px;
            min-width: 50px;

            @media (max-width: $break-md-max) {
              padding-right: 0;
              min-width: 1px;
              margin-bottom: 6px;
            }

            p {
              line-height: 1.17;

              @media (max-width: $break-md-max) {
                white-space: normal;
                overflow: visible;
                text-overflow: unset;
                font-size: 1.125rem;
                font-weight: 300;
                line-height: 1.33;
              }
            }
          }
        }
      }

      .attachments-table-row {
        display: flex;
        align-items: center;

        @media (max-width: $break-md-max) {
          display: block;
          padding: 9px 24px 11px 48px;
          margin-bottom: 10px;
          position: relative;
          height: auto;
        }
      }

      .attachments-table-column {
        display: flex;
        align-items: center;
        justify-content: center;
        user-select: none;

        &:nth-child(1) {
          width: 60px;
          min-width: 60px;

          @media (max-width: $break-md-max) {
            position: absolute;
            top: 11px;
            left: 11px;
            width: auto;
            min-width: 1px;
          }
        }

        &:nth-child(2) {
          flex: 1;
          justify-content: flex-start;
        }

        &__check {
          width: 18px;
          height: 18px;
          border-radius: 4px;
          border: 2px solid $color-indigo;
          display: flex;
          align-items: center;
          justify-content: center;
          cursor: pointer;

          @media (max-width: $break-md-max) {
            width: 19px;
            height: 19px;
          }

          svg {
            width: 9px;

            @media (max-width: $break-md-max) {
              width: 11px;
            }
          }

          &.checked {
            background-color: #E8025F;
            border: 2px solid #E8025F;
          }
        }

        &.actions-column {
          padding-right: 4px;

          .icon-btn {
            margin-left: 4px;
          }

          @media (max-width: $break-md-max) {
            justify-content: flex-start;

            .icon-btn {
              margin-right: 4px;
              margin-left: 0;

              &:first-child {
                margin-left: -8px;
              }

              svg {
                width: 16px;
              }
            }
          }
        }
      }
    }
  }

  &__foot {
    margin-top: 15px;
    padding-bottom: 15px;
    text-align: center;
  }


  .attachment-model-heading {
    display: flex;
    justify-content: space-between;
    margin-bottom: 3rem;
  }

  .files-list {
    li {
      margin-bottom: 1rem;
      display: flex;
      justify-content: space-between;

      .row-with-checkbox {
        align-items: center;
      }
    }
  }

  .return-icon {
    width: 8px;
    margin-right: 8px;
  }

  .footer-buttons {
    gap: 10px;
    display: flex;
    justify-content: flex-end;
  }

  .file-name {
    padding-bottom: 1rem;
    border-bottom: 1px solid $primary-color;
    margin-bottom: 2rem;
  }

  .select {
    width: 200px;

    >div {
      overflow: hidden;
      border: 1px solid $primary-color;
      border-radius: 12px;
      width: 100%;
      font-size: 0.8125rem;
    }
  }

  .status {
    background-color: red;
    &--filled {
      background-color: $highlighted-color-2;
      color: white;
    }

    &--empty {
      background-color: $highlighted-color-3;
      color: white;
    }
  }

  .input-hidden {
    display: none;
  }

  .footer-filler {
    flex-grow: 1;
  }

  .queta {
    font-size: 12px !important;
    font-weight: 500 !important;
    line-height: 16px !important;
    text-align: right;
    color: $text-purple !important;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: sticky;
    bottom: 24px;
    background: white;
    left: 0;
    right: 0;
    height: 52px;

    b {
      color: $color-indigo !important;
      font-weight: 600 !important;
    }
  }

  .table-empty {
    height: 56px;
    display: flex;
    justify-content: center;
    align-items: center;
    p {
      font-size: 12px;
      font-weight: 600;
      line-height: 16px;
      text-align: center;
      color: #9D8FBD;
    }
  }
}
