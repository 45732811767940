@use '../abstracts/variables' as *;

.selected-documents {
  position: relative;
  margin-bottom: 33px;
  
  &__selected {
    width: calc(100% + 36px);
    margin-left: -18px;
    background-color: $quinary-bg-color;
    padding: 0 18px;
    height: 35px;
    display: flex;
    align-items: center;

    svg {
      color: $quaternary-text-color;
      font-size: 1.5rem;
      display: block;
    }

    span {
      display: block;
    }

    .text {
      font-size: 0.875rem;
      font-weight: 700;
      color: $tertiary-text-color;
      margin-left: 11px;
    }

    .arrow {
      margin-left: auto;
      cursor: pointer;
    }
  }

  &__dropdown {
    position: absolute;
    right: 0;
    top: calc(100% + 5px);
    white-space: nowrap;
    background-color: $primary-light-color;
    box-shadow: 0 0 8px rgba($shadow-color, 0.5);
    border-radius: 3px;
    z-index: 1;

    li {
      display: flex;
      align-items: center;
      padding: 8px;
      color: $nonary-text-color;
      font-size: 0.875rem;
      transition: color;
      cursor: pointer;

      span {
        margin-right: 5px;

        svg {
          display: block;
        }
      }

      &:hover {
        color: $primary-color;
      }
    }
  }
}