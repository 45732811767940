// Colors
$primary-color: #F7F5FC;

$bg-color-1: #2B184E;
$bg-color-2: #e0e0e0;
$bg-color-3: #fcfcfc;
$bg-color-4: #cbcbcb;
$bg-color-5: #f5faff;
$bg-color-6: #f1f1f1;
$bg-color-7: #bedeff;
$bg-purple1: #FBF9FD;
$bg-purple2: #FBF9FD;

$text-color-1: #2B184E;
$text-purple: #9D8FBD;
$bg-hover: #F0EBF9;
$bg-default: #E2D8F3;
$bg-selected: #FFEAF3;
$color-indigo: #482982;
$text-placeholder: #9D8FBD;

// old colors
$secondary-text-color: #34353B;
$primary-light-color: #fff;
$quinary-text-color: #bbb;
$shadow-color: #b2b1b1;
$nonary-text-color: #767676;
$tertiary-bg-color: #f3f3f3;
$shadow-color-6: #878787;
$quaternary-text-color: #7e7e7e;
$primary-text-color: #000;
$senary-bg-color: #c3c2c2;
$secondary-color: #FA6D6D;
$secondary-bg-color: #1B1D24;
$secondary-color-light: #ed5534;
$senary-border-color: #dbdbdb;
$tertiary-color-light: #bdf4ff;
$quinary-bg-color: #e0e0e0;
$tertiary-color: #31DDFE;
$quinary-color-light: #E3F7E7;
$septenary-color: #2CB23C;
$bg-color-16: #FF3D65;
$shadow-color-4: #a7a7a7;
$shadow-color-7: #7F7F7F;
$primary-dark-color: #000;
$danger-color: #FA4040;
$primary-light-color: #fff;
$primary-bg-color: #f4f4f4;
$primary-border-color: #e5e5e5;
$octonary-color: #CCC;
$nonary-border-color: #DADCE0;
$denary-border-color: #f1f1f1;
$tertiary-text-color: #2E2F35;



$border-color-1: #E2D8F3;

$highlighted-color-1: #87e490;
$highlighted-color-2: #12874A;
$highlighted-color-3: #D63F3F;
$highlighted-color-4: #FFBC00;

$error-color: #D63F3F;

$shadow-color-1: #767676;

// Fonts
$ff-primary: 'Poppins';

// Breakpoints
$break-xxs-max: 375px;
$break-xxs-min: 376px;
$break-xs-max: 480px;
$break-xs-min: 481px;
$break-sm-max: 575px;
$break-sm-min: 576px;
$break-md-max: 767px;
$break-md-min: 768px;
$break-lg-max: 991px;
$break-lg-min: 992px;
$break-xl-max: 1199px;
$break-xl-min: 1200px;
$break-xxl-max: 1440px;
