@use '../abstracts/variables' as *;
@use '../abstracts/animations' as *;

.dashboard-row {
  display: flex;
  align-items: center;
  padding: 16px 15px 15px 20px;
  border-bottom: 1px solid $primary-border-color;
  min-height: 65px;
  position: relative;

  @media (max-width: $break-sm-max) {
    padding-left: 12px;
    padding-right: 12px;
  }

  &:hover {
    background-color: $primary-bg-color;

    .status-el {
      // color: $primary-color;
      // background-color: rgba($primary-color, 0.1);
    }

    .progress-el {
      background-color: rgba($primary-color, 0.2);

      &__inner {
        background-color: $primary-color;
      }
    }
  }

  .more {
    // display: none;
    right: 15px;
    top: 10px;

    @media (max-width: $break-md-max) {
      display: block;
      position: absolute;
    }

    .dropdown {
      position: absolute;
      top: calc(100% + 5px);
      right: 0;
      width: 120px;
      background-color: $primary-light-color;
      border-radius: 3px;
      box-shadow: 0 4px 8px rgba($shadow-color, 0.5);
      z-index: 1;
      padding: 5px;

      li {
        font-size: 0.875rem;

        a {
          display: flex;
          align-items: center;
          color: $nonary-text-color;
          padding: 5px;

          svg, i {
            font-size: 1.25rem;
            margin-right: 5px;
          }

          &.rotate-180 {

            svg, i {
              transform: rotate(180deg);
            }
          }
        }
      }
    }
  }

  &.document-row {

    .dashboard-column {

      &.col-2 {
        justify-content: center;
      }

      &.col-3, &.col-4 {
        font-size: 0.75rem;
        font-weight: 400;
        color: $secondary-text-color;
        justify-content: center;

        span {
          color: $nonary-text-color;
          margin-left: 4px;
        }
      }
    }

    @media(max-width: $break-md-max) {
      flex-wrap: wrap;

      .dashboard-column {

        &.col-1 {
          width: 100%;
          flex: 0 auto;
          margin-bottom: 10px;
          padding-right: 30px;
        }
        &.col-2,
        &.col-3,
        &.col-4 {
          display: block;
          width: 100%;
          padding-left: 33px;
          margin-right: 0;
          margin-bottom: 10px;
          text-align-last: left;
          font-size: 0.875rem;

          &::before {
            content: attr(data-label);
            font-weight: 700;
            float: left;
            margin-right: 10px;
          }
        }
        // &.col-2 {
        //   width: 50%;
        //   margin-right: 0;
        //   padding-right: 20px;
        //   padding-left: 33px;
        //   justify-content: flex-start;
        // }
        // &.col-3 {
        //   width: 50%;
        //   justify-content: flex-end;
        // }
      }
    }
  }

  &.template-row {

    .show-on-hover {
      display: none;
    }

    &:hover {

      .show-on-hover {
        display: flex;
        align-items: center;

        > .button {
          margin-right: 11px;
        }
      }

      .col-2, .col-3 {
        display: none;
      }
    }

    @media (max-width: $break-md-max) {
      flex-wrap: wrap;

      .dashboard-column {
        
        &.col-1 {
          width: 100%;
          flex: 0 auto;
          margin-bottom: 10px;
          padding-right: 30px;
        }
        &.col-2,
        &.col-3 {
          display: block;
          text-align-last: left;
          width: 100%;
          margin-right: 0;
          margin-bottom: 10px;
          padding-left: 33px;
          display: block !important;

          &::before {
            content: attr(data-label);
            float: left;
            font-weight: 700;
            margin-right: 10px;
          }
        }
        &.show-on-hover {
          display: flex !important;
          width: 100%;
          padding-left: 33px;

          .button {
            margin-right: 11px;
          }

          .icon-button {
            margin-left: auto;
          }
        }
      }
    }

    // @media (max-width: $break-sm-max) {
    //   .dashboard-column {
    //     &.show-on-hover {
          
    //       .icon-button {
    //         margin-right: 3px;
    //       }
    //     }
    //   }
    // }

    // @media (max-width: $break-xs-max) {
    //   .dashboard-column {
    //     &.col-2 {
    //       width: 100%;
    //       margin-bottom: 20px;
    //     }
    //     &.show-on-hover {
    //       width: 100%;
    //       justify-content: space-between;
    //       padding-left: 33px;
    //     }
    //   }
    // }
  }

  .dashboard-column {

    @media (max-width: $break-md-max) {
      &.col-1 {

        .checkbox {
          display: none !important;
        }
        .icon {
          display: block !important;
        }
      }
    }

    &:last-child {
      margin-right: 0;
    }
  }

  &.signatures-row {

    .signatures-col-2,
    .signatures-col-3,
    .signatures-col-4,
    .signatures-col-5 {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .signatures-col-2 {
      // flex-direction: row;

      p:not(:last-child) {
        margin-bottom: 5px;
      }

      .tooltip-wrapper:not(:last-child) {
        margin-right: 5px;
      }
    }

    .signatures-col-4 {
      text-align: center;
      font-size: 12px;
      color: $nonary-text-color;
      width: 125px;
    }

    .signatures-col-5 {
      margin-right: 0;
      // justify-content: flex-end;
      display: flex;
      align-items: center;

      .status {
        cursor: pointer;
        margin-right: 5px;
      }

      .refresh-icon {
        width: 22px;
        height: 22px;
        border-radius: 50%;
        background-color: $primary-color;
        color: $primary-light-color;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;

        svg {
          display: block;
          font-size: 1rem;
        }

        &.refreshing {
          animation: spin .7s linear infinite;
        }

        &.refreshed {
          background-color: $senary-bg-color;
        }
      }
    }

    @media (max-width: $break-md-max) {
      display: block;

      .dashboard-column {
        display: block;
        width: auto !important;

        &::before {
          content: attr(data-label);
          font-weight: 700;
          margin-right: 10px;
          float: left;
        }
        
        &.col-1 {
          margin-bottom: 10px;
          padding-right: 30px;

          &::before {
            display: none;
          }
        }
        &.col-2,
        &.col-3,
        &.col-4,
        &.col-5 {
          max-width: 100%;
          margin-right: 0;
          padding-right: 30px;
          padding-left: 33px;
          margin-bottom: 10px;
          text-align: left;
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: flex-start !important;
        }
        &.col-4 {
          font-size: 0.875rem;
          color: $primary-dark-color;
        }
        // &.col-3 {
        //   width: 100%;
        //   justify-content: flex-end;
        //   margin-right: 0;
        //   margin-bottom: 20px;
        // }
        // &.col-4 {
        //   width: 50%;
        //   margin-right: 0;
        //   padding-right: 20px;
        //   padding-left: 33px;
        //   justify-content: flex-start;
        // }
        // &.col-5 {
        //   width: 50%;
        //   justify-content: flex-end;
        //   margin-right: 0;
        // }
      }
    }
  }

  &.models-row {

    .show-on-hover {
      display: none;

      @media (max-width: $break-lg-max) {
        display: block;
      }
    }

    &:hover {

      .dashboard-column {

        &.col-1 {

          .icon {
            display: block;
          }
        }
      }

      .show-on-hover {
        display: flex;
        align-items: center;

        .button {
          margin-right: 0;
        }
      }
    }

    @media (max-width: $break-md-max) {
      flex-wrap: wrap;

      .show-on-hover {
        width: 100%; 
        padding-left: 33px;
        margin-top: 20px;
      }
    }
  }

  &.attachment-row {

    .col-1 {

      .checkbox {
        display: none;
      }
    }

    &:hover,
    &.active {

      .col-1 {

        .icon {
          display: none;
        }
        .checkbox {
          display: block;
        }
      }
    }

    &.active {

      p.title {
        color: $primary-color;
      }
    }
  }

  &.folder-row {

    &.hover {
      background-color: darken($tertiary-bg-color, 10);
    }
  }

  .date-col {
    font-size: 0.75rem !important;
    font-weight: 400;
    color: $secondary-text-color;
    justify-content: center;

    span {
      color: $nonary-text-color;
      margin-left: 4px;
    }
  }

  .doc-date-col {
    width: 150px;
  }
}