@use '../abstracts/variables' as *;

.response-loader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  z-index: 10000;
  background-color: rgba($primary-light-color, 0.5);
  
  &--dark {
    background-color: rgba($primary-dark-color, 0.5);

    .response-loader__content {
      background-color: $primary-light-color;
      padding: 50px;
      border-radius: 4px;
      box-shadow: 0 0 20px rgba($primary-dark-color, 0.2);
      max-width: 100%;
      margin: 0 15px;

      .loader {
        width: 40px;
        height: 40px;
      }

      .response-loader__text {
        min-width: auto;
        padding: 0;
        color: $primary-color;
        background-color: transparent;
        border-radius: 0;
      }

      @media (max-width: $break-xs-max) {
        .response-loader__text {
          min-width: auto;
        }
      }
    }
  }

  &__content {
    text-align: center;
  }

  &__text {
    color: $primary-light-color;
    background-color: $primary-color;
    padding: 15px;
    min-width: 300px;
    text-align: center;
    margin-bottom: 20px;
    border-radius: 3px;
  }
}