@use '../abstracts/variables' as *;

.settings-sidebar {
  position: relative;
  height: 100%;
  background-color: $secondary-dark-color;
  padding: 11px 18px;

  h3 {
    color: $primary-light-color;
    margin-bottom: 30px;
  }

  section {
    margin-bottom: 40px;

    &:last-child {
      margin-bottom: 0;
    }

    h6 {
      margin-bottom: 9px;
      color: $primary-light-color;
    }

    ul {
      width: calc(100% + 36px);
      margin-left: -18px;

      li {

        a {
          display: flex;
          align-items: center;
          color: $nonary-text-color;
          font-size: 0.875rem;
          padding: 8px 18px;
          
          &.active {
            color: $primary-light-color;
            background-color: $tertiary-text-color;
          }

          svg {
            display: block;
            margin-right: 15px;
          }
        }
      }
    }
  }
}