@use '../abstracts/variables' as *;

@mixin statusDot {
  span {
    display: block;
    width: 16px;
    height: 16px;
    min-width: 16px;
    position: relative;
    border: 1px solid $senary-border-color;
    border-radius: 50%;
    margin-right: 6px;

    &::after {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 10px;
      height: 10px;
      background-color: $quaternary-border-color;
      content: '';
      border-radius: 50%;
    }
  }

  &.ready {

    span::after {
      background-color: $tertiary-color;
    }
  }

  &.draft {

    span {
      border: 0;

      &::after {
        background-color: $senary-color;
      }
    }
  }

  &.in-progress {

    span::after {
      background-color: $quaternary-color;
    }
  }

  &.closed {

    span::after {
      background-color: $quinary-color;
    }
  }

  &.expired {

    span::after {
      background-color: $primary-color;
    }
  }

  &.cancelled {

    span::after {
      background-color: $secondary-color;
    }
  }
}

.custom-select {
  position: relative;

  &__selected {
    font-size: 0.8125rem;
    line-height: 1.23;
    color: $quinary-text-color;
    cursor: pointer;
    display: flex;
    align-items: center;

    @include statusDot();
  }

  .select-dropdown {
    position: absolute;
    top: calc(100% + 21px);
    left: 0;
    min-width: 152px;
    border-radius: 5px;
    background-color: $primary-light-color;
    box-shadow: 0 3px 8px 0 rgba($shadow-color-5, 0.5);
    padding: 11px 0;
    z-index: 10;

    ul {

      li {
        padding: 7px 12px;
        font-size: 0.875rem;
        line-height: 1.142;
        transition: background-color .3s ease;
        display: flex;
        align-items: center;
        cursor: pointer;
        white-space: nowrap;

        &:hover,
        &.active {
          background-color: $tertiary-bg-color;
        }

        &.disabled {
          display: none;
        }

        @include statusDot();
      }
    }
  }

  &--form {
    margin-bottom: 22px;

    .custom-select__selected {
      background-color: $form-el-bg-color-1;
      border: 1px solid $form-el-border-color-1;
      color: $primary-dark-color;
      border-radius: 2px;
      height: 36px;
      font-size: 0.9375rem;
      font-weight: 400;
      padding: 0 30px 0 14px;
      position: relative;
      transition: border .3s ease;

      &.opened {
        border-color: $primary-color;
      }

      &.not-selected {
        color: $form-el-text-color-1;
      }

      .icon {
        position: absolute;
        top: 50%;
        right: 5px;
        transform: translateY(-50%);
        color: $primary-color;

        svg {
          display: block;
        }
      }
    }

    .select-dropdown {
      top: calc(100% + 5px);
      min-width: 100%;
    }
  }
}