@use '../abstracts/variables' as *;

.send-by-email {

  &__head {

    ul {
      display: flex;
      justify-content: space-between;
      align-items: center;

      li {
        flex: 1;
        color: rgba($tertiary-text-color, 0.69);
        border-bottom: 1px solid rgba($septenary-text-color, 0.69);
        font-size: 1.0625rem;
        padding-bottom: 12px;
        text-align: center;
        cursor: pointer;

        &.active {
          color: $primary-color;
          border-color: $primary-color;
        }

        &:first-child {
          margin-right: 13px;
        }
      }
    }
  }

  &__body {
    padding: 30px 0 0;

    .form__group:first-child {
      padding-right: 40px;
    }

    .qr-code-section {

      &__inner {
        display: flex;
        align-items: center;
      }

      &__left {
        flex: 1;
        margin-right: 15px;
        text-align: center;

        p {
          font-size: 0.875rem;
          margin-bottom: 30px;
        }

        .button {
          color: $primary-text-color;
        }
      }

      &__right {
        flex: 1;

        p {
          font-size: 0.875rem;
          line-height: 2;
          margin-bottom: 10px;
          text-align: center;
        }

        canvas {
          display: block;
          margin: 0 auto;
        }

        img {
          display: block;
          margin: 0 auto;
        }
      }
    }

    .checkbox-add-watermark {
      margin-top: 30px;

      label {
        display: flex;
        align-items: center;
      }
    }
  }
}