@use '../abstracts/variables_new.scss' as *;

.button {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border: 0;
  outline: 0;
  font-weight: 600;
  font-size: 14px;
  height: 32px;
  padding: 0 16px;
  border-radius: 50px;
  cursor: pointer;
  background-color: $color-indigo;
  color: #fff;
  transition: background-color .3s ease;
  gap: 8px;
  text-wrap: nowrap;
  &--small {
    padding: 12px !important;
  }

  svg {
    fill: #fff;
  }

  &:disabled {
    opacity: 0.4;

    &:hover {
      cursor: default;
    }
  }

  &.rounded-sm {
    border-radius: 5px;
  }

  &.height-40 {
    height: 40px;
  }

  &:hover {
    background-color: $bg-hover;
    color: $color-indigo;

    svg {
      fill: $color-indigo;
    }
  }

  &:active {
    background-color: $bg-selected;
  }

  .icon {
    display: block;

    svg {
      display: block;
    }
  }

  .arrow {

    svg {
      display: block;
    }
  }

  &--with-icon {
    padding-left: 12px;
  }

  &--drop-down {
    padding-right: 7px;
  }

  &--bold {
    font-weight: 700;
  }

  &--regular {
    font-weight: 400;
  }

  &--x-small {
    height: 20px;
    padding: 0 8px;
    font-size: 0.6875rem;
  }

  &--medium {
    height: 40px;
    padding: 0 20px;
  }

  &--with-padding-y {
    padding-top: 12px;
    padding-bottom: 12px;
    height: auto;
  }

  &--primary {
    background-color: $primary-color;
    color: $color-indigo;

    &:hover {
      background-color: darken($primary-color, 10%);
    }

    &:disabled {
      cursor: not-allowed;
    }

    &:active {
      background-color: darken($primary-color, 20%);
    }

    svg {
      fill: $color-indigo;
    }

  }

  &--secondary-light {
    background-color: $quinary-color-light;
    color: $septenary-color;

    &:hover {
      background-color: darken($quinary-color-light, 10%);
    }

    &:disabled {
      background-color: $quinary-color-light;
    }

    &:active {
      background-color: darken($quinary-color-light, 20%);
    }
  }

  &--outline-primary {
    background-color: transparent;
    border: 2px solid $color-indigo;
    color: $color-indigo;

    transition: color .3s ease, background-color .3s ease;

    svg {
      width: 18px;
      fill: $color-indigo;
    }

    .svg-icon {
      margin-right: 4px;

      svg {
        width: 18px;
        fill: $color-indigo;
      }

      @media (max-width: $break-md-max) {
        margin-right: 8px;

        svg {
          width: 16px;
        }
      }
    }

    &:hover {
      background-color: $color-indigo;
      color: #fff;
      border-color: $color-indigo;

      svg {
        fill: #fff;
      }
    }

    &:active {
      color: $color-indigo;
      background-color: $bg-selected;
    }
  }

  &--hover-light-pink {
    &:hover {
      background-color: $bg-selected;
      border-color: $bg-selected;
      color: $color-indigo;

      svg {
        fill: $color-indigo;
      }
    }
  }

  &--outline-primary-light {
    background-color: $primary-light-color;
    border: 2px solid $bg-hover;
    color: $color-indigo;
    transition: color .3s ease, background-color .3s ease;

    &:disabled {
      &:hover {
        background-color: $primary-light-color;
        border: 2px solid $bg-hover;
        color: $color-indigo;

        svg {
          fill: $color-indigo;
        }
      }
    }

    svg {
      fill: $color-indigo;
    }

    .svg-icon {
      @media (max-width: $break-md-max) {
        svg {
          width: 16px;
        }
      }
    }

    &.success {
      color: $primary-light-color;
      transition: background-color .3s ease;

      &:hover {
        background-color: darken($danger-color, 10%);
        color: $primary-light-color;
      }

      &:active {
        background-color: darken($danger-color, 20%);
      }
    }

    &:hover {
      background-color: $color-indigo;
      color: #fff;
      border-color: $color-indigo;

      svg {
        fill: #fff !important;
      }
    }

    &:active {
      color: $color-indigo;
      background-color: $bg-selected;
      border-color: $bg-selected;

      svg {
        fill: $color-indigo;
      }
    }
  }


  &--outline-danger {
    background-color: transparent;
    border: 2px solid $highlighted-color-3;
    color: $highlighted-color-3;
    transition: color .3s ease, background-color .3s ease;

    svg {
      fill: $highlighted-color-3;
    }

    &:hover,
    &:active {
      background: rgba($highlighted-color-3, 0.1);
      color: $highlighted-color-3;

      svg {
        fill: $highlighted-color-3;
      }
    }


  }

  &--outline-success {
    background: none;
    border: 2px solid #13AA5B;
    color: #13AA5B;
    transition: color .3s ease, background-color .3s ease;

    svg {
      fill: #13AA5B;
    }

    &:hover {
      background-color: rgba(#13AA5B, 0.05);
      color: #13AA5B;
      border-color: #13AA5B;

      svg {
        fill: #13AA5B;
      }
    }

  }

  &--outline-pink {
    background-color: transparent;
    border: 1px solid $bg-color-16;
    color: $bg-color-16;
    font-weight: 400;
    transition: color .3s ease, background-color .3s ease;


    &:hover {
      background-color: $bg-color-16;
      color: $primary-light-color;
    }

    &:active {
      background-color: darken($bg-color-16, 10%);
    }
  }

  &--white {
    background-color: $primary-light-color;
    color: $primary-color;
    box-shadow: 0 2px 4px 0 rgba($shadow-color-4, 0.5);

    &:active {
      background-color: darken($primary-light-color, 10%);
    }
  }

  &--primary-light {
    background-color: $bg-default;
    color: $color-indigo;
    transition: background-color .3s ease;

    svg {
      fill: $color-indigo;
    }

    @media (hover: hover),
    (-moz-touch-enabled: 0),
    (pointer:fine) {
      &:hover {
        background-color: $bg-hover;
        // color: #fff;

        // svg {
        //   fill: #fff;
        // }
      }
    }

    &:active {
      color: $color-indigo;
      background-color: $bg-selected;
      fill: $color-indigo;

      svg {
        fill: $color-indigo;
      }
    }
  }

  &--danger {
    background-color: $danger-color;
    color: $primary-light-color;
    transition: background-color .3s ease;

    &:hover {
      background-color: darken($danger-color, 10%);
      color: $primary-light-color;
    }

    &:active {
      background-color: darken($danger-color, 20%);
    }
  }

  &--success {
    background-color: $danger-color;
    color: $primary-light-color;
    transition: background-color .3s ease;

    &:hover {
      background-color: darken($danger-color, 10%);
      color: $primary-light-color;
    }

    &:active {
      background-color: darken($danger-color, 20%);
    }
  }

  &--transparent {
    background-color: transparent;
    color: $color-indigo;

    svg {
      fill: $color-indigo;
    }

    &:hover {
      background-color: $bg-hover;
    }

    &:active {
      background-color: $bg-selected;
      color: $color-indigo;
    }
  }

  &--full-width {
    width: 100%;
  }

  &--smaller-icon-margin {

    .icon {
      margin-right: 5px;
    }
  }

  &--txt-small {
    font-size: 0.6875rem;
    text-transform: uppercase;
    font-weight: 700;
    padding: 0 8px;

    span {
      display: block;
    }

    .icon {
      margin-right: 6px;
    }
  }

  &--text-uppercase {
    text-transform: uppercase;
    letter-spacing: 0.3px;
  }

  &--font-small {
    font-size: 0.75rem;
  }
}

.button-group {
  border-radius: 6px;
  overflow: hidden;
  width: fit-content;

  &--small {
    // height: 24px;
    font-size: 0.75rem;
  }

  &--button {
    border-radius: 0;
    border: none;
    background-color: lightgray;
    color: #333;
    padding: 5px 8px;
    cursor: pointer;

    &.primary {
      background-color: $color-indigo;
      color: $primary-light-color;
    }
  }
}


.action-btn {
  background-color: #fff !important;
  border-radius: 30px !important;
  color: $color-indigo !important;
  padding: 6px 14px 6px 10px;
  position: relative;
  height: 36px;
  text-wrap: nowrap;
  gap: 8px;

  >span {
    display: flex;
    align-items: center;
    z-index: 2;
    gap: 8px;
  }

  @media (max-width: $break-md-max) {
    height: 28px;
  }

  &.bg-pink {
    background-color: #faf8fd !important;
  }

  &:hover:not(:disabled) {
    color: #fff !important;
    background: none !important;

    svg {
      width: 18px !important;
      fill: #fff !important;
    }

    &::after {
      display: none;
    }
  }

  &::before {
    content: "";
    background:
      linear-gradient(90deg, #482982 -2.5%, #E8025F 50.29%, #FFBC00 96.93%);
    position: absolute;
    left: 50%;
    top: 50%;
    width: calc(100% + 4px);
    height: calc(100% + 4px);
    border-radius: 50px;
    transform: translate(-50%, -50%);
    z-index: 0;

  }

  &::after {
    content: "";
    background: #fff;
    position: absolute;
    left: 50%;
    top: 50%;
    width: calc(100% - 0px);
    height: calc(100% - 0px);
    border-radius: 50px;
    transform: translate(-50%, -50%);
    z-index: 1;

  }

  &:disabled {
    background-color: #f1f1f1 !important;
    opacity: 1;
  }

  svg {
    width: 18px !important;
    fill: $color-indigo !important;
  }

  &.small-icon {
    .svg-icon {
      svg {
        width: 6px !important;
      }
    }
  }


}