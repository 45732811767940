@use '../abstracts/variables' as *;

.checkbox {

  label{
    display: block;
    height: 24px;
  }

  input {
    display: none;
  }

  &__btn {
    width: 24px;
    height: 24px;
    cursor: pointer;

    &.unchecked {
      color: $senary-bg-color;
    }
    
    &.checked {
      color: $primary-color;
    }
    
    svg {
      display: block;
    }
  }

  &__label {
    font-size: 0.875rem;
    font-weight: 500;
    margin-left: 5px;
    color: $septenary-text-color;
  }
}

.checkbox-inline {
  display: inline-block;
}