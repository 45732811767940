@use '../abstracts/variables_new.scss' as *;

.switch-el-v2 {

  label {
    cursor: pointer;

    input {
      display: none;
    }

    input:checked ~ .switch-el-v2__btn {
      background-color: $highlighted-color-2;
      border-color: $highlighted-color-2;

      &::after {
        left: 19px;
        background-color: #fff;
      }
    }
  }

  &__btn {
    width: 40px;
    height: 24px;
    border-radius: 16px;
    background-color: #CFD8DC;
    position: relative;
    transition: background-color .3s ease, border .3s ease;

    &::after {
      content: '';
      position: absolute;
      top: 4px;
      left: 4px;
      width: 16px;
      height: 16px;
      border-radius: 8px;
      background-color: #fff;
      transition: left .3s ease;
    }
  }
}