@use '../abstracts/variables' as *;

.history {

  h2 {
    display: flex;
    align-items: center;
    color: $septenary-text-color;
    font-size: 1.25rem;
    font-weight: 400;
    margin-bottom: 43px;

    svg {
      margin-right: 12px;
    }
  }

  &__section {
    padding-bottom: 50px;
    position: relative;

    &::after {
      content: '';
      width: 2px;
      height: 100%;
      position: absolute;
      top: 0;
      left: 55px;
      background-color: $denary-bg-color;
    }

    h3 {
      margin-bottom: 30px;
      display: inline-flex;
      justify-content: center;
      padding: 9px 21px;
      border-radius: 18px;
      background-color: $nonary-text-color;
      color: $primary-light-color;
      font-size: 0.75rem;
      font-weight: 400;
      text-transform: uppercase;
      min-width: 120px;
      position: relative;
      z-index: 1;
    }

    &:last-child {
      padding-bottom: 0;
    }
  }
}