@use '../abstracts/variables_new.scss' as *;

.history-modal-v2 {
  font-size: 0.875rem;

  h2 {
    display: flex;
    align-items: center;
    font-size: 1.125rem;
    font-weight: 600;
    color: $color-indigo;

    @media (max-width: $break-md-max) {
      font-size: 1.25rem;
    }

    svg {
      margin-right: 8px;
      width: 18px;

      @media (max-width: $break-md-max) {
        width: 18px;
        margin-right: 10px;
      }
    }
  }

  &__section {
    padding-bottom: 40px;
    position: relative;

    &::after {
      content: '';
      width: 1px;
      height: 100%;
      position: absolute;
      top: 0;
      left: 45px;
      background-color: $border-color-1;
    }

    h3 {
      margin-bottom: 20px;
      display: inline-flex;
      justify-content: center;
      padding: 6px 10px;
      border-radius: 14px;
      background-color: $bg-default;
      color: $color-indigo;
      font-size: 0.875rem;
      font-weight: 400;
      text-transform: uppercase;
      min-width: 120px;
      position: relative;
      z-index: 1;

      @media (max-width: $break-md-max) {
        font-size: 0.9375rem;
      }
    }

    p {
     color: $color-indigo !important;
      // font-weight: 500;
    }

    &:last-child {
      padding-bottom: 0;
    }
  }

  .template-info {

    margin-bottom: 20px;

    h1 {
      font-size: 1.4rem;
      font-weight: 600;
      margin-bottom: 10px;
      color: $primary-color;

      @media (max-width: $break-md-max) {
        font-size: 1.25rem;
      }
    }
    p {
      color: $text-color-1;
      white-space: pre-wrap;
    }
  }
}