@use '../abstracts/variables_new.scss' as *;

.resource-not-found {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  padding: 20px;

  &__head {
    
    h2 {
      font-weight: 600;
      font-size: 1.125rem;
    }
  }

  &__body {
    margin-top: 20px;

    p {
      font-size: 0.875rem;
    }
  }
}