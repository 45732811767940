@use '../abstracts/variables_new.scss' as *;
@use '../abstracts/mixins.scss' as *;

.sidebar-filters-section {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}

.registered-mail-content {
  @include collectionContent;
  overflow: scroll;
  @include hideScrollbar();

  .filters-section-sort {
    margin-right: initial;
  }

  &__nav {
    padding: 0 6px;

    ul {
      li {
        a {
          display: flex;
          align-items: center;
          color: $color-indigo;
          font-size: 14px;
          font-weight: 600;
          border-radius: 8px;
          padding: 8px 12px;
          position: relative;
          transition: background-color .3s ease;
          margin-bottom: 8px;

          svg {
            width: 24px;
            margin-right: 8px;
          }

          &:hover {
            background-color: $bg-hover;
          }

          &.active {
            background-color: $bg-selected;
          }
        }
      }
    }
  }

  &__header {
    width: 100%;
    padding: 16px 24px 0;
  }

  &__main {
    padding: 0 24px 16px;
    height: 100%;

    @media (max-width: $break-md-max) {
      padding: 16px;
    }

    &_actions {
      @include collectionContentMainActions;
    }

    &_mobile {
      @include collectionContentMainMobile;

      .filters-item {
        width: 100%;
        min-width: 100%;
      }
    }
  }

  table {
    // font-size: 0.9375rem;
    line-height: 1.36;
    text-align: center;
    width: 100%;
    border-collapse: collapse;

    thead {
      height: 40px;

      border-bottom: 5px solid #fff th {
        text-align: left;
        font-size: 13px;
        font-weight: 700;
      }
    }

    tbody>tr {
      &:nth-child(even) {
        background-color: #fff;
      }

      &:nth-child(odd) {
        background-color: $bg-purple2;
      }

      height: 40px;
      text-align: left;
    }

    .table-buttons-container {
      display: flex;
      justify-content: space-around;
      align-items: center;
    }
  }
}

.add-members-modal {
  &__title {
    margin-bottom: 20px;
  }

  &__input {
    margin-bottom: 20px;
    width: 200px;
  }

  &__create-btn {
    margin-bottom: 250px;
  }

  &__btn {
    display: block;
    margin: 0 auto;
  }

  &__match-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding-top: 10px;
    padding-bottom: 10px;
    border-bottom: 0.5px #1d5083 solid;
  }
}

.status-value {
  padding: 4px 8px;
  border-radius: 50px;
  font-size: 12px;
  line-height: 16px;
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: center;
  &.verified {
    color: #fff;
    background-color: $highlighted-color-2;
  }

  &.non-verified {
    color: #fff;
    background-color: $highlighted-color-3;
  }

  &.verification-pending {
    color: #fff;
    background-color: $highlighted-color-3;
  }

  &.invitation-pending {
    color: $color-indigo;
    background-color: #f0f5ff;
  }

  &.confirmation-pending {
    color: $color-indigo;
    background-color: #f0f5ff;
  }

  &.api-access-pending {
    color: $color-indigo;
    background-color: #f0f5ff;
  }
}

.refresh-status {
  // position: absolute;
  // top: 50%;
  // right: 0;
  // transform: translateY(-50%);
  // width: 20px;
  // height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  @media (max-width: $break-md-max) {
    display: none;
  }

  svg {
    width: 18px;
  }

  &.refreshing {

    svg {
      animation: spin .7s linear infinite;
    }
  }
}

.sender-status-title {
  // font-size: 16px;
  font-weight: bold;
}