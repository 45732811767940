@use '../abstracts/mixins' as *;
@use '../abstracts/variables_new.scss' as *;

.radio-v2 {

  &__choices {

    > div {
      position: relative;
  
      input {
        display: none;
      }
  
      input:checked ~ div {
        border-color: $primary-color;
  
        &::after {
          opacity: 1; 
        }
      }

      input:disabled ~ div {
        opacity: 0.5;
      }

      input:disabled ~ p {
        opacity: 0.5;
      }

      &:hover {
        
        .radio__choices_btn::after {
          opacity: 1;
        }
      }
    }

    &_label {
      display: flex;
      cursor: pointer;
      align-items: center;
      color: $primary-color;

      p {
        font-size: 14px;
        font-weight: 600;

        @media (max-width: $break-md-max) {
          font-size: 0.9375rem;
        }
      }
    }

    &_btn {
      width: 16px;
      min-width: 16px;
      height: 16px;
      border: 1px solid $color-indigo;
      border-radius: 50%;
      position: relative;
      margin-right: 10px;

      &::after {
        content: '';
        width: 8px;
        height: 8px;
        background-color: $primary-color;
        border-radius: 50%;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        opacity: 0;
        transition: opacity .3s ease;
      }
    }
  }
}