@use '../abstracts/variables_new.scss' as *;

.variables-v2 {
  font-size: 14px;
  line-height: 1.33;
  color: $color-indigo;
  display: block;
  width: 100%;

  @media (max-width: $break-md-max) {
    font-size: 0.9375rem;
  }

  h1 {
    font-weight: bold;
    font-size: 1rem;
    color: $color-indigo;
    line-height: 1.25;

    @media (max-width: $break-md-max) {
      font-size: 1.125rem;
    }
  }

  h2 {
    font-weight: bold;
    font-size: 14px;
    line-height: 1.33;
    width: 100%;

    @media (max-width: $break-md-max) {
      font-size: 0.9375rem;
    }
  }

  .input-v2 {
    margin-bottom: 0;

    &__field {
      // background-color: $bg-color-12 !important;
      // padding: 3px 6px !important;

      // &:focus {
      //   border-color: $bg-color-13 !important;
      // }
    }

    &.not-empty {

      .input-v2__field {
        // background-color: rgba($bg-color-15, 0.08) !important;
        // color: $bg-color-14 !important;
        max-width: 100% !important;

        // &:focus {
        //   border-color: $bg-color-14 !important;
        // }
      }
    }
  }

  .textarea {

    textarea {
      // background-color: $bg-color-12 !important;
      // padding: 3px 6px !important;

      // &:focus {
      //   border-color: $bg-color-13 !important;
      // }
    }

    &.not-empty {

      // textarea {
      //   background-color: rgba($bg-color-15, 0.08) !important;
      //   color: $bg-color-14 !important;

      //   &:focus {
      //     border-color: $bg-color-14 !important;
      //   }
      // }
    }
  }

  .date_picker .react-datepicker-wrapper .react-datepicker__input-container input {
    // background-color: $bg-color-12 !important;
    // padding-top: 3px !important;
    // padding-bottom: 3px !important;

    // &:focus {
    //   border-color: $bg-color-13 !important;
    // }
    // &:disabled {
    //   background-color: gainsboro !important;
    // }
  }

  .date_picker.not-empty .react-datepicker-wrapper .react-datepicker__input-container input {
    // background-color: rgba($bg-color-15, 0.08) !important;
    // color: $bg-color-14 !important;

    // &:focus {
    //   border-color: $bg-color-14 !important;
    // }
  }

  .question-el {

    p {
      color: $highlighted-color-3;
      font-weight: 600;

      &.question-answered {
        color: $color-indigo;
      }
    }

    .radio {
      border-color: $highlighted-color-3;

      &::after {
        background-color: $highlighted-color-3;
      }
    }

    .cancel-btn {
      svg {
        fill: $highlighted-color-3;
        transition: fill .3s ease;
      }
    }

    .variables-v2__btn {
      background: rgba($highlighted-color-3, .1);
      border-color: $highlighted-color-3;
      color: $highlighted-color-3;
      text-align: left;
      svg {
        fill: $highlighted-color-3;
        transition: fill .3s ease;
      }

      &:hover {
        background-color: $highlighted-color-3;
        color: #fff;

        svg {
          fill: #fff;
        }
      }
    }

    .variables-v2__head:hover {
      background-color: rgba($highlighted-color-3, 0.07);
    }

    .radio-v2__choices {

      &_label {

        &:hover {
          background-color: $bg-selected;
        }
      }

      &_btn {
        // border-color: $bg-color-16;

        &::after {
          // background-color: $bg-color-16;
        }
      }
    }

    &.selected {

      p {
        &.selected {
          padding: 6px 32px 6px 12px;
          background: #13AA5B0D;
          color: $highlighted-color-2;
          border-radius: 4px;
        }
      }

      .radio {
        border-color: $text-color-1;

        &::after {
          background-color: $text-color-1;
        }
      }

      .variables-v2__head {

        &:hover {
          background-color: rgba($highlighted-color-1, 0.07);



          .variables-v2__head_text {

            .radio {
              // border-color: $bg-color-18;

              &::after {
                // background-color: $bg-color-18;
              }
            }
          }

          .variables-v2__head_selected {

            .button {
              // background-color: rgba($bg-color-16, 0.07);
              border-color: transparent;
              padding: 0 5px;

              .icon {
                margin-right: 0;
              }

              &:hover {
                // background-color: $bg-color-16;
              }
            }
          }
        }
      }

      .radio-v2__choices {
        p {
          color: $text-color-1;
        }

        &_btn {
          border-color: $color-indigo;
        }

        &_label {

          &:hover {
            // background-color: rgba($bg-color-17, 0.07);

            p {
              // color: $text-color-13;
            }

            .radio-v2__choices_btn {
              border-color: $color-indigo;

              &::after {
                // background-color: $bg-color-18;
              }
            }
          }
        }

        &_btn {
          border-color: $color-indigo;

          &::after {
            background-color: $text-color-1;
          }
        }
      }
    }

    &.opened-2 {
      .radio-v2__choices_selected {
        background: $bg-selected;
        border-radius: 5px;
      }

      .radio-v2__choices {

        input:checked~.radio-v2__choices_btn {
          border-color: #f31360;

          &::after {
            background-color: #f31360;
          }
        }
      }
    }
  }

  .page-number {
    // width: 100%;
    // text-align: right;
    // display: block;
    // font-size: 14px;
    // font-weight: 600;
    // line-height: 16px;
    display: none;
  }

  .doc-variable-el {

    &__span {
      position: absolute;
      opacity: 0;
      top: 0;
      left: 0;
      z-index: -1;
      min-width: 50px;
      // max-width: 100% !important;
      white-space: nowrap;
      // text-overflow: ellipsis;
      font-size: 14px;
      font-weight: 600;
      line-height: 20px;
      padding: 4.75px 12px;
      border: 2px solid #F0EBF9;
      @media (max-width: $break-md-max) {
        font-size: 0.9375rem;
      }

      &--number {
        // padding: 0 20px;
      }
    }

    &__select {
      padding: 2px 0;
      max-width: 100%;

      label.MuiInputLabel-formControl {
        position: relative;
        margin-right: 24px;
        white-space: normal;

        +.MuiInput-formControl {
          margin-top: 0;
        }
      }
    }

    &--with-icon {
      position: relative;
      padding-right: 30px;

      .icon {
        position: absolute;
        top: 50%;
        right: 8px;
        width: 24px;
        height: 24px;
        margin-top: -12px;
        border-radius: 4px;
        // background-color: rgba($primary-color, 0.2);
        color: $primary-color;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;

        svg {
          width: 16px;
        }

        @media (max-width: $break-md-max) {
          width: 30px;
          height: 30px;
          margin-top: -15px;

          svg {
            width: 22px;
          }
        }

        &:hover {
          background-color: $primary-color;
          color: #fff;

          svg {
            fill: #fff;
          }
        }
      }
    }
  }

  .radio {
    width: 16px;
    min-width: 16px;
    height: 16px;
    border-radius: 50%;
    display: block;
    border: 1px solid $primary-color;
    position: relative;
    margin-right: 10px;

    &::after {
      width: 8px;
      height: 8px;
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      background-color: $primary-color;
      border-radius: 50%;
    }
  }

  &.opened {

    >.variables-v2__head {
      // color: $quinary-text-color;

      .radio {
        // border-color: $quinary-text-color;

        &::after {
          // background-color: $quinary-text-color;
        }
      }
    }

    >.variables-v2__body {
      display: block;
    }
  }

  &.opened-2 {

    >.variables-v2__body {
      display: block;
    }
  }

  &__head {
    display: flex;
    align-items: center;
    font-weight: 500;
    padding: 3px 0;
    flex-wrap: wrap;

    @media (max-width: $break-md-max) {
      display: block;

      .variables-v2__head_buttons {
        margin-top: 15px;
      }
    }

    &.select {

      .variables-v2__head_text {

        p {
          // padding: 3px 0 2px;
        }
      }
    }

    &_text {
      display: flex;
      align-items: center;
      cursor: pointer;

      &.cursor-default {
        cursor: default;
      }

      p {
        padding: 8px 0;
      }

      p.selected {
        margin-right: 10px;
        font-weight: 700;
        text-transform: uppercase;
        margin-left: 10px;
      }
    }

    p {
      font-weight: 500;
    }

    &_buttons {
      display: flex;
      align-items: center;
      flex-wrap: wrap;

      .button {
        margin-left: 10px;
        min-height: 25px;
        height: auto;
        margin-top: 2px;
        margin-bottom: 2px;
        padding-top: 3px;
        padding-bottom: 3px;
      }
    }

    &_selected {
      display: none;
      align-items: center;
      // margin-left: 10px;

      p {
        margin-right: 10px;
        font-weight: 700;
        text-transform: uppercase;
      }

      .button {
        height: 25px;
      }
    }

    // &:hover {

    //   .variables-v2__head_buttons {
    //     display: flex;
    //   }
    // }
  }

  &__body {
    // border-left: 1px solid $primary-border-color;
    // padding-left: 18px;
    // margin-left: 8px;
    display: none;
    position: relative;

    .variables-v2__head {
      padding: 4px 24px;
      margin-bottom: 8px;

      &:hover {
        background-color: rgba($primary-color, 0.12);
      }
    }


    .variables-v2__body {
      margin-left: 13px;
    }

    .radio-v2 {

      &__choices {

        &_label {
          padding: 9px 12px;
          min-height: 36px;
          border-radius: 4px;

          &:hover {
            background-color: rgba($primary-color, 0.12);
          }

          p {
            color: $text-color-1;
            line-height: 1.5;
          }
        }
      }
    }

    .choices {
      padding-bottom: 20px;
      position: absolute;
      top: -12px;
      background: #fff;
      box-shadow: 0px 1px 8px 0px #00000026;
      z-index: 2;
      padding: 16px;
      border-radius: 4px;

      &__inner {
        // border-bottom: 1px solid $primary-border-color;
      }

      &.hide {
        display: none;
      }
    }

    .fields {
      display: none;

      &__box {
        display: flex;
        align-items: center;
        flex-wrap: wrap;

        p {
          font-size: 1rem;
          // color: $primary-text-color;
          line-height: 32px;
        }

        .input-v2 {
          // max-width: 140px;
          margin-bottom: 0;
          margin-left: 5px;
        }
      }
    }

    &.fields-opened {

      .fields {
        display: block;
      }
    }

    .multiselect {
      // color: $primary-dark-color;
    }
  }

  &__text {
    // display: flex;
    // align-items: center;
    // flex-wrap: wrap;
    line-height: 2;
    // color: $primary-text-color;
    margin-bottom: 8px;
    word-break: break-word;
    white-space: pre-wrap !important;
    padding: 0 24px;

    //styleName: Body/Body L - Bold;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    color: $text-color-1;

    .date-input__body {
      &:hover {
        svg {
          fill: $color-indigo !important;
        }

        input {
          background: $bg-hover !important;
          color: $text-color-1 !important;
        }
      }

      .svg-icon {
        top: 40%;
        z-index: 1;
      }

      &.clearable {
        padding-right: 0px;
      }

      .clear-date-btn {
        top: 40% !important;

        svg {
          fill: #f31360 !important;
        }
      }
    }

    .input-v2__field {
      padding: 4.75px 12px;
      height: 32px;
    }

    .input-v2,
    .select-v2,
    .date_picker-v2 {
      // max-width: 140px;
      margin: 0 5px;
    }

    .select-v2 {
      height: 32px;
    }

    .tooltip-wrapper {
      display: inline-block;
      max-width: 100%;
      /* SCROLLING TASK */
      // display: block; 
      /* SCROLLING TASK */
    }

    .textarea-el {
      display: block;
    }

    .variables-v2__head_text.tooltip-wrapper {
      display: flex;
    }

    .customer-input {
      min-width: 200px;
    }

    .doc-variable-el__select {
      margin: 0 10px 0 0;

      >label {
        z-index: 1;
        padding: 0 4px;
        font-size: 0.875rem;
        transform: translate(0, 21px) scale(1);

        &.Mui-focused,
        &.MuiFormLabel-filled {
          transform: translate(0, 0) scale(0.75);
        }
      }

      .MuiInputBase-formControl {
        background-color: #FFECCA;
        padding: 3px 6px;
        border-radius: 3px;

        &.Mui-focused {
          background-color: #FFECCA;
        }

        .MuiSelect-selectMenu {
          min-height: auto;
          padding: 0;
          font-size: 0.875rem !important;
        }
      }
    }
  }

  &.selected {

    .variables-v2__head {

      &_text {
        // color: $quinary-text-color;

        .radio {
          // border-color: $quinary-text-color;

          &::after {
            // background-color: $quinary-text-color;
          }
        }
      }

      &_selected {
        // color: $quinary-text-color;
        display: flex;
        align-items: center;
        position: absolute;
        right: 16px;
        top: 50%;
        transform: translateY(-50%);

        .button {
          display: none;
        }
      }

      &_buttons {
        display: none;
      }

      &:hover {

        .variables-v2__head {

          &_text {
            color: $primary-color;

            .radio {
              border-color: $primary-color;

              &::after {
                background-color: $primary-color;
              }
            }
          }

          &_selected {
            color: $primary-color;

            button {
              display: inline-block;
            }
          }
        }
      }
    }

    .variables-v2__body {

      .choices {
        display: none;
      }
    }
  }

  &.selected-2 {

    .variables-v2__body {

      .choices {
        display: block;
      }
    }
  }

  &__btn {
    background-color: transparent;
    border: 1px solid $primary-color;
    color: $primary-color;
    transition: color .3s ease, background-color .3s ease;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
    cursor: pointer;
    // font-size: 0.75rem;
    margin: 2px 4px 2px 0;
    padding: 3.5px 6px;

    svg {
      display: block;
      width: 14px;
    }

    &:hover {
      background-color: $primary-color;
      color: #fff;

      svg {
        fill: #fff;
      }
    }

    &:active {
      background-color: darken($primary-color, 10);
      color: #fff;
    }
  }
}

.manual-line {
  width: 100%;
  height: 1px;
  background-color: darkgray;
  margin-top: 16px;
}

.manual-lines-block {
  margin-bottom: 36px;
}