@use '../abstracts/variables_new.scss' as *;
@use '../abstracts/mixins.scss' as *;


.template-row {

  .folders-popup {
    bottom: auto;
    top: calc(100% + 15px);
    right: 0;
    left: auto;
    transform: translateX(0);

    &::after {
      top: auto;
      bottom: 100%;
      left: auto;
      right: 10px;
      border: solid transparent;
      content: " ";
      height: 0;
      width: 0;
      position: absolute;
      pointer-events: none;
      border-color: rgba($primary-light-color, 0);
      border-bottom-color: $primary-light-color;
      border-width: 6px;
      margin-left: 0;
  
      @media (max-width: $break-md-max) {
        left: auto;
        right: 26px;
      }
    }
  }
}

.folders-popup {
  position: absolute;
  bottom: calc(100% + 9px);
  left: 50%;
  transform: translateX(-50%);
  width: 253px;
  background-color: $primary-light-color;
  border-radius: 4px;
  box-shadow: 0 2px 6px 0 rgba(131,130,130, 0.5);
  z-index: 150;
  user-select: none;

  &.wide {
    width: auto;
  }

  @media (max-width: $break-md-max) {
    left: auto;
    transform: translateY(0);
    right: 0;
  }

  &::after {
    top: 100%;
    left: calc(50% - 3px);
    border: solid transparent;
    content: " ";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
    border-color: rgba($primary-light-color, 0);
    border-top-color: $primary-light-color;
    border-width: 6px;
    margin-left: -6px;

    @media (max-width: $break-md-max) {
      left: auto;
      right: 26px;
    }
  }

  &__head {
    padding: 11px 18px;
    border-bottom: 2px solid $primary-bg-color;

    &.root {

      .back {
        display: none;
      }
    }

    &.inner {

      .main {
        display: none;
      }
    }

    .main,
    .back {
      display: flex;
      align-items: center;

      span {
        margin-right: 12px;

        &.delete-category {
          margin-left: auto;
          margin-right: 0;
          cursor: pointer;

          svg {
            color: $secondary-color;
          }
        }
      }
    }

    .back {

      h5 {
        max-width: 150px;
        text-overflow: ellipsis;
        overflow: hidden;
      }
      
      span.icon {
        cursor: pointer;
        position: relative;
        transition: background-color .3s ease;

        &:hover {
          background-color: $primary-bg-color;
        }

        &::after {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          content: '';
        }
      }

      &__actions {
        margin-left: auto;
        height: 24px;
        display: flex;
        align-items: center;

        .tooltip-wrapper {
          height: 24px;
        }
      }
    }

    svg {
      display: block;
      color: $secondary-text-color;
      min-width: 20px;
    }

    h5 {
      font-size: 0.8125rem;
      margin-bottom: 0;
      padding-top: 4px;
      color: $secondary-text-color;
      text-transform: uppercase;
      white-space: pre-wrap;
    }
  }

  &__body {
    height: 153px;
    overflow-y: auto;
    position: relative;
    @include hideScrollbar();

    p.no-data {
      font-size: 0.875rem;
      padding: 7px 18px;
    }

    ul {
      padding-top: 1px;

      &.root {

        li.inner {
          display: none;
        }
      }

      &.inner {

        li.root {
          display: none;
        }
      }

      li {

        .root {
          display: flex;
          align-items: center;
          padding: 7px 18px;
          cursor: pointer;
          transition: background-color .3s ease;

          &:hover {
            background-color: $primary-bg-color;
          }

          .delete-template {
            cursor: pointer;

            svg {
              color: $secondary-color;
            }
          }
        }

        &.hover {
          background-color: darken($tertiary-bg-color, 10);
        }

        svg {
          display: block;
          color: $color-indigo;
        }

        p {
          font-size: 12px;
          font-weight: 600;
          color: $secondary-text-color;
          margin-left: 12px;
          text-overflow: ellipsis;
          overflow: hidden;
        }
  
        span {
          margin-left: auto;
          display: block;
          color: $primary-border-color;
        }

        .sublevel {
          position: absolute;
          left: 0;
          top: 0;
          width: 100%;
          height: 100%;
          background-color: $primary-light-color;

          ul {
            position: relative;
          }
        }
      }
    }
  }

  &__foot {
    padding: 11px 11px 11px 18px;
    display: flex;
    align-items: center;
    box-shadow: 0 -1px 4px 0 rgba(195, 194, 194, 0.5);
    z-index: 1;
    position: relative;

    .new {
      margin-right: auto;
      color: $color-indigo;
      cursor: pointer;
      transition: background-color .3s ease;
      padding: 0 4px;
      margin-left: -4px;

      &:hover {
        background-color: $primary-bg-color;
      }

      svg {
        display: block;
      }
    }

    .button {
      margin-left: 10px;
      height: 28px;
    }
  }

  .cancel-btn {
    margin-left: auto;
  }
}

.manage-link {
  margin-left: auto;
  text-decoration: underline;
  cursor: pointer;
}