@use '../abstracts/variables' as *;
@use '../abstracts/animations' as *;

.tooltip {
  position: absolute;
  background-color: $secondary-text-color;
  color: $primary-light-color;
  font-size: 0.875rem;
  font-weight: 500;
  line-height: 1.285;
  padding: 5px 10px;
  width: 120px;
  text-align: center;
  backface-visibility: hidden;
  display: none;
  z-index: 100;
  border-radius: 3px;
  box-shadow: 0 3px 7px 0 rgba($shadow-color-8, 0.4);

  &--small {
    font-size: 11px;
    padding: 5px 10px;
    width: auto;
    white-space: nowrap;
  }

  &--top {
    bottom: calc(100% + 5px);
    left: 50%;
    // transform: translateX(-50%);

    &::after {
      top: calc(100% - 1px);
      left: 50%;
      border: solid transparent;
      content: "";
      height: 0;
      width: 0;
      position: absolute;
      pointer-events: none;
      border-color: rgba(255, 255, 255, 0);
      border-top-color: $secondary-text-color;
      border-width: 6px;
      margin-left: -6px;
    }
  }

  &--bottom {
    top: calc(100% + 5px);
    left: 50%;
    // transform: translateX(-50%);

    &::after {
      bottom: calc(100% - 1px);
      left: 50%;
      border: solid transparent;
      content: "";
      height: 0;
      width: 0;
      position: absolute;
      pointer-events: none;
      border-color: rgba(255, 255, 255, 0);
      border-bottom-color: $secondary-text-color;
      border-width: 6px;
      margin-left: -6px;
    }
  }

  &--left {
    right: calc(100% + 10px);
    top: 50%;
    // transform: translateY(-50%);

    &::after {
      left: calc(100% - 1px);
      top: 50%;
      border: solid transparent;
      content: "";
      height: 0;
      width: 0;
      position: absolute;
      pointer-events: none;
      border-color: rgba(255, 255, 255, 0);
      border-left-color: $secondary-text-color;
      border-width: 6px;
      margin-top: -6px;
    }
  }

  &--right {
    left: calc(100% + 10px);
    top: 50%;
    // transform: translateY(-50%);

    &::after {
      right: calc(100% - 1px);
      top: 50%;
      border: solid transparent;
      content: "";
      height: 0;
      width: 0;
      position: absolute;
      pointer-events: none;
      border-color: rgba(255, 255, 255, 0);
      border-right-color: $secondary-text-color;
      border-width: 6px;
      margin-top: -6px;
    }
  }

  &--nowrap {
    white-space: nowrap;
    width: auto;
  }
}

.tooltip-wrapper {
  position: relative;

  &:hover {

    .tooltip--top {
      display: block;
      animation-duration: 0.5s;
      animation-name: tooltip;
      animation-fill-mode: forwards;
    }

    .tooltip--bottom {
      display: block;
      animation-duration: 0.5s;
      animation-name: tooltip;
      animation-fill-mode: forwards;
    }

    .tooltip--left {
      display: block;
      animation-duration: 0.5s;
      animation-name: tooltipLeft;
      animation-fill-mode: forwards;
    }

    .tooltip--right {
      display: block;
      animation-duration: 0.5s;
      animation-name: tooltipLeft;
      animation-fill-mode: forwards;
    }
  }
}