@use '../abstracts/variables' as *;

.side-menu {
  width: 52px;
  background-color: $secondary-bg-color;
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  z-index: 1002;
  display: flex;
  flex-direction: column;

  &__bg {
    position: fixed;
    width: 100vw;
    height: 100vh;
    left: 0;
    top: 0;
    background-color: rgba($secondary-bg-color, 0.5);
    display: none;
  }

  &--long {
    width: 281px;
    left: -281px;
    z-index: 1001;
    transition: left .3s ease;

    &.active {
      left: 0;
      z-index: 1003;

      .side-menu__bg {
        display: block;
      }
    }

    .side-menu__list {

      li {

        a {
          justify-content: flex-start;
        }
      }
    }

    .side-menu__bottom {
      justify-content: flex-start;

      a {
        display: flex;
        width: 100%;
        align-items: center;
        justify-content: flex-start;

        .icon {
          width: 52px;
          display: flex;
          align-items: center;
          justify-content: center;
          margin-right: 10px;
        }
      }
    }
  }

  &__list {

    li {
      position: relative;

      &:first-child {

        a {
          background-color: $quaternary-bg-color;
          color: $octonary-text-color;
          transition: color .3s ease;

          .logo {
            width: 30px;
            height: 30px;
            background-color: $primary-color;
            color: $primary-light-color;
            border-radius: 2px;
            font-size: 0.875rem;
            font-weight: 900;
            display: flex;
            align-items: center;
            justify-content: center;
          }
        }
      }
      
      a {
        display: block;
        color: $septenary-text-color;
        height: 48px;
        display: flex;
        justify-content: center;
        align-items: center;
        transition: color .3s ease;

        svg {
          font-size: 2.0625rem;
        }

        i {
          font-size: 1.625rem;
        }

        &.active,
        &:hover {
          color: $primary-light-color;
        }

        .icon {
          width: 52px;
          height: 100%;
          text-align: center;
          margin-right: 10px;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }

      // .tooltip {
      //   position: absolute;
      //   left: calc(100% + 10px);
      //   top: 50%;
      //   transform: translateY(-50%);
      //   white-space: nowrap;
      //   height: 24px;
      //   font-size: 0.75rem;
      //   background-color: $primary-dark-color;
      //   color: $primary-light-color;
      //   border-radius: 3px;
      //   align-items: center;
      //   justify-content: center;
      //   padding: 0 8px;
      //   display: none;

      //   &::after {
      //     right: 100%;
      //     top: 50%;
      //     border: solid transparent;
      //     content: " ";
      //     height: 0;
      //     width: 0;
      //     position: absolute;
      //     pointer-events: none;
      //     border-color: rgba($primary-dark-color, 0);
      //     border-right-color: $primary-dark-color;
      //     border-width: 6px;
      //     margin-top: -6px;
      //   }
      // }

      // &:hover {

      //   .tooltip {
      //     display: flex;
      //   }
      // }
    }
  }

  &__bottom {
    display: flex;
    justify-content: center;
    align-items: center;
    // height: 48px;
    margin-top: auto;
    flex-direction: column;

    > div {
      width: 100%;
    }

    a {
      color: $septenary-text-color;
      transition: color .3s ease;
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 48px;

      &.active {
        color: $primary-light-color;
      }

      &:hover {
        color: $primary-light-color;

        // .tooltip {
        //   display: flex;
        // }
      }

      svg {
        font-size: 1.5rem;
      }

      // .tooltip {
      //   position: absolute;
      //   left: calc(100% + 10px);
      //   top: 50%;
      //   transform: translateY(-50%);
      //   white-space: nowrap;
      //   height: 24px;
      //   font-size: 0.75rem;
      //   background-color: $primary-dark-color;
      //   color: $primary-light-color;
      //   border-radius: 3px;
      //   align-items: center;
      //   justify-content: center;
      //   padding: 0 8px;
      //   display: none;

      //   &::after {
      //     right: 100%;
      //     top: 50%;
      //     border: solid transparent;
      //     content: " ";
      //     height: 0;
      //     width: 0;
      //     position: absolute;
      //     pointer-events: none;
      //     border-color: rgba($primary-dark-color, 0);
      //     border-right-color: $primary-dark-color;
      //     border-width: 6px;
      //     margin-top: -6px;
      //   }
      // }
    }
  }
}