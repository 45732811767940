@use '../abstracts/variables_new.scss' as *;


.single-document-variable-head {
  margin-bottom: 16px;

  h1 {
    //styleName: Header/H3;
    font-size: 18px;
    font-weight: 600;
    line-height: 24px;
    text-align: left;
    background: $bg-purple2;
    padding: 20px 24px;
    color: $text-color-1;
  }

  h2 {
    font-size: 18px;
    font-weight: 600;
    line-height: 24px;
    text-align: left;
    background: $bg-purple1;
    padding: 8px 24px;
  }

  h3 {
    font-size: 18px;
    font-weight: 600;
    line-height: 24px;
    padding: 0 24px;
  }

  &__top {
    // margin-bottom: 10px;
  }
}