@use '../abstracts/variables_new.scss' as *;

.notary-box-v2 {
  position: relative;
  padding: 15px;
  padding-right: 80px;
  margin-bottom: 10px;

  &:hover {
    background-color: rgba($primary-color, 0.1);
  }

  h4 {
    font-size: 0.875rem;
    font-weight: 600;
    color: $primary-color;
    margin-bottom: 10px;

    @media (max-width: $break-md-max) {
      font-size: 1rem;
    }
  }

  .address {  
    display: flex;
    align-items: center;
    font-size: 0.75rem;
    margin-bottom: 5px;

    svg {
      width: 12px;
      margin-right: 5px;
    }

    @media (max-width: $break-md-max) {
      font-size: 0.9375rem;

      svg {
        width: 16px;
      }
    }
  }

  .phone-email {
    display: flex;
    align-items: center;
    flex-wrap: wrap;

    p {
      font-size: 0.75rem;
      display: flex;
      align-items: center;
      margin-right: 15px;
      word-break: break-all;

      svg {
        width: 12px;
        margin-right: 5px;
      }

      &:last-child {
        margin-right: 0;
      }

      @media (max-width: $break-md-max) {
        font-size: 0.9375rem;
  
        svg {
          width: 16px;
        }
      }
    }
  }

  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    cursor: pointer;
  }

  .edit-btn {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 20px;
    z-index: 2;
  }
}