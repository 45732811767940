.folder-modal {

  h2 {
    text-align: center;
  }

  .form {
    &__actions {
      padding-top: 0px;
    }
  }
}