@use '../abstracts/variables_new.scss' as *;

.draggable-ghost-el-v2 {
  font-size: 0.75rem;
  padding: 10px 20px;
  display: block;
  position: absolute;
  top: -1000px;
  left: 0;
  background-color: $primary-color;
  color: #fff;
  box-shadow: 0 0 100px rgba(#000, 0.9);
  display: flex;
  align-items: center;
  min-width: 120px;
  max-width: 300px;
  width: auto;
  cursor: all-scroll;
}