@use '../abstracts/variables_new.scss' as *;

.icon-btn {
  width: 24px;
  min-width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: $bg-hover;
  border-radius: 50%;
  border: 0;
  outline: 0;
  padding: 0;
  cursor: pointer;
  transition: background-color .3s ease;
  position: relative;
  color: $color-indigo;

  &:disabled {
    opacity: 0.3;
    cursor: default;

    &:hover {
      background: none;
    }
  }

  // .svg-icon {

    svg {
      width: 14px;
      fill: $color-indigo;
    }
  // }

  // @media (max-width: $break-md-max) {
  //   width: 32px;
  //   min-width: 32px;
  //   height: 32px;

  //   .svg-icon svg {
  //     width: 14px;
  //   }
  // }

  &:hover:not(:disabled) {
    background-color: $color-indigo;
    color: #fff;

    .icon-btn__popover {
      display: flex;
    }

    // .svg-icon {
      svg {
        fill: #fff;
      }
    // }
  }

  &:active {
    background-color: $bg-selected;
    color: $color-indigo;

  }

  &--md {
    width: 40px;
    min-width: 40px;
    height: 40px;

    // .svg-icon {

      svg {
        width: 20px;

        @media (max-width: $break-md-max) {
          width: 24px;
        }
      }
    // }

    &.icon-btn--svg-sm {

      // .svg-icon {

        svg {
          width: 16px;

          @media (max-width: $break-md-max) {
            width: 20px;
          }
        }
      // }
    }

    @media (max-width: $break-md-max) {
      width: 44px;
      height: 44px;
      min-width: 44px;
    }
  }

  &--not-clickable {
    cursor: default;
  }

  &--active {
    background-color: darken($primary-color, 5);
  }

  &__popover {
    display: none;
    position: absolute;
    top: 100%;
    left: 50%;
    transform: translateX(-50%);
    white-space: nowrap;
    align-items: center;
    padding: 10px;
    border-radius: 4px;
    background-color: #fff;
    border: 1px solid $primary-color;
    box-shadow: 0 0 5px rgba(#000, 0.16);

    button {
      background-color: transparent;
      border: 1px solid $primary-color;
      border-radius: 2px;
      outline: 0;
      color: $primary-color;
      text-transform: uppercase;
      font-size: 0.75rem;
      font-weight: 600;
      margin: 0 2px;
      cursor: pointer;
      transition: background-color .3s ease, color .3s ease;

      &:hover {
        background-color: $primary-color;
        color: #fff;
      }
    }
  }

  &--svg-sm {

    // .svg-icon {

      svg {
        width: 12px;

        @media (max-width: $break-md-max) {
          width: 20px;
        }
      }
    // }
  }

  &--svg-md {

    // .svg-icon {

      svg {
        width: 14px;

        @media (max-width: $break-md-max) {
          width: 24px;
        }
      }
    // }
  }

  &--svg-lg {

    // .svg-icon {

      svg {
        width: 16px;
      }
    // }
  }

  &--svg-xl {

    // .svg-icon {

      svg {
        width: 18px;

       
      }
    // }
  }

  &--svg-xxl {

    // .svg-icon {

      svg {
        width: 24px;

        @media (max-width: $break-md-max) {
          width: 28px;
        }
      }
    // }
  }

  &--transparent {
    background-color: transparent;

    // .svg-icon {

      svg {
        fill: $color-indigo;
      }
    // }

    &:hover {
      background-color: $bg-hover;
    }

    &:active {
      background-color: $bg-selected;
    }
  }

  &--ghost {
    background-color: rgba(#fff, 0.5);
    border-radius: 4px;

    // .svg-icon {

      svg {
        fill: rgba($color-indigo, 0.5);
      }
    // }

    &:hover {
      background-color: rgba(#fff, 1);

      // .svg-icon {

        svg {
          fill: rgba($color-indigo, 1);
        }
      // }
    }

    &:active {
      background-color: $bg-selected;
    }
  }

  &--white {
    background-color: #fff;

    // .svg-icon {

      svg {
        fill: $color-indigo;
      }
    // }

    .MuiSvgIcon-root {
      fill: $color-indigo;
    }

    &:hover {
      background-color: darken(#fff, 10);
    }
  }

  &--placeholder {
    pointer-events: none;
    cursor: default;
  }
}