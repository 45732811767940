@use '../abstracts/variables_new.scss' as *;

.main-layout {
  display: flex;
  flex-direction: column;
  min-height: 100dvh;
  height: 100vh;

  &--sidebar-shrinked {

    .main-layout__main {
      padding-left: 75px;

      @media (max-width: $break-md-max) {
        height: initial;

        padding-left: 0;
        background: $primary-color;
      }
    }
  }

  &__fix {
    height: 84px;
    width: 100%;

    @media (max-width: $break-md-max) {
      height: 0px;
    }
  }

  &__main {
    height: calc(100% - 0px);
    padding-left: 245px;
    position: relative;

    flex: 1;

    @media (max-width: $break-md-max) {
      height: calc(100% - 74px - 64px);
      padding-left: 0;
    }

    .main-sidebar {
      position: fixed;
      top: 0px;
      left: 0;
      width: 245px;
      height: calc(100% - 24px);
      overflow-y: auto;
      z-index: 1;

      @media (max-width: $break-md-max) {
        display: none;
        height: calc(100% - 74px - 64px);
        top: 74px;
      }

      &--shrinked {

        .logo {
          width: 75px;
          text-align: center;
          display: flex;
          align-items: center;
          justify-content: center;
          height: 89px;
          padding: 0;
          margin: 0;
        }
      }
    }
  }

  &__body {
    display: flex;
    flex-direction: column;
    // flex: 1; 
    height: 100%;

    &_mobile {
      // display: none;
      // margin-left: auto;

      @media (max-width: $break-md-max) {
        display: flex;
        width: 100%;
        flex: 1 1 100%;
        justify-content: space-between;
      }

      .hamburger-menu {
        align-items: center;
        color: $color-indigo;
        display: none;

        @media (max-width: $break-md-max) {
          display: flex;
          margin-left: 16px;
        }

      }

      p {
        font-size: 1.1875rem;
        line-height: 1.32;

        &.highlighted {
          display: flex;
          align-items: center;
          // position: absolute;
          // left: 16px;
          font-size: 24px;
          font-weight: 600;
          line-height: 32px;
          // top: 22px;
          gap: 16px;

          &--hide {
            display: none;
          }

          @media (max-width: $break-lg-max) {
            font-size: 15px;

            span {
              margin-left: 0px !important;
            }
          }

          @media (max-width: $break-md-max) {
            &--hide {
              display: flex;
            }

            font-size: 1rem;
            line-height: 1.25;
          }

          svg {
            display: block;
          }

          span {
            display: flex;
            color: $color-indigo;

            &.svg-icon {
              margin-left: 0px;
              border: 0;
            }
          }
        }
      }
    }

    &_content {
      // display: flex;
      // flex-direction: column;
      // flex: 1;
      // z-index: 0;
      height: calc(100% - 84px);
      overflow: hidden;
      flex-grow: 1;

      @media (max-width: $break-md-max) {
        // padding-top: 74px;
        background: $primary-color;
      }
    }

    .filters-section-parent {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 24px;
      gap: 16px;
      position: relative;
      width: 100%;
      flex-wrap: wrap;
      // min-width: 1507px;

      @media (max-width: $break-md-max) {
        justify-content: end;
        min-width: initial;
        gap: 8px !important;
      }
    }

    .choose-dropdown {
      background-color: #fff;
      border: 0;
      margin-top: 5px;
      border-radius: 4px;
      padding-top: 0;
      left: 0 !important;
      right: auto !important;

      .dropdown-item {
        border-radius: 0;
        border: none;
        padding-top: 2px;
        padding-bottom: 2px;
        position: relative;

        &:hover {
          background: $bg-selected !important;
        }

        &:active {
          background: $bg-selected !important;
        }

        p {
          color: $color-indigo;
          font-size: 14px;
          font-weight: 600;
          line-height: 20px;
          width: 100%;
          justify-content: space-between;

          svg {
            fill: $color-indigo !important;
            float: right;
          }

          &:hover {
            svg {
              fill: $color-indigo;
            }
          }
        }

        ul {
          display: none;
          position: absolute;
          left: calc(100% + 0px);
          width: 220px;
          background: #fff;
          top: 0px;
          box-shadow: 0px 1px 8px 0px #00000026;
          border-radius: 4px;

          li {
            color: $color-indigo;
            width: unset;
            min-width: unset;
            height: unset;
            min-height: 40px;

            font-size: 14px;
            font-weight: 600;
            line-height: 20px;

            &.active {
              background: $bg-selected;
            }
          }
        }

        &:hover {
          ul {
            display: block;
          }
        }
      }
    }

    .sort-dropdown {
      background-color: #fff;
      border: 0;
      margin-top: 5px;
      border-radius: 4px;
      padding-top: 0;

      .dropdown-item {
        border-radius: 0;
        border: none;
        padding-top: 2px;
        padding-bottom: 2px;
        position: relative;

        &:active {
          background: $bg-selected !important;
        }

        p {
          color: $color-indigo;
          font-size: small;
          width: 100%;
          justify-content: space-between;

          svg {
            fill: $color-indigo !important;
            float: right;
          }

          &:hover {
            svg {
              fill: $color-indigo;
            }
          }
        }

        ul {
          display: none;
          position: absolute;
          right: calc(100% + 0px);
          width: 150px;
          background: #fff;
          top: 0px;
          box-shadow: 0px 1px 8px 0px #00000026;
          border-radius: 4px;

          li {
            color: $color-indigo;
            width: unset;
            min-width: unset;

            &.active {
              background: $bg-selected;
            }
          }
        }

        &:hover {
          ul {
            display: block;
          }
        }
      }
    }
  }

  .main-layout__body {

    &_mobile {
      order: 1;
    }
  }

  &.page-dashboard {

    .mobile-menu {
      height: 100%;
      top: 0px;
      display: flex;
      flex-direction: column;
    }
  }

  &.page-documents {

    .main-layout__main {

      // @media (max-width: $break-md-max) {
      //   height: calc(100% - 74px - 60px);
      // }
    }

    .main-layout__body {

      &_mobile {

        p.highlighted svg {
          width: 20px;
        }
      }
    }
  }

  &.page-document {

    .main-layout__body {

      &_mobile {

        p.highlighted svg {
          width: 20px;
        }
      }
    }
  }

  &.page-templates {

    .main-layout__fix {

      @media (max-width: $break-md-max) {
        height: 0px;
      }
    }

    .main-layout__body {

      &_mobile {

        p.highlighted svg {
          width: 30px;
        }
      }
    }
  }

  &.page-signatures {

    .main-layout__body {

      &_mobile {

        p.highlighted svg {
          width: 21px;
        }
      }
    }
  }

  &.page-settings {

    .main-layout__fix {

      @media (max-width: $break-md-max) {
        height: 135px;
      }
    }

    .main-layout__body {

      &_mobile {

        p.highlighted svg {
          width: 22px;
        }
      }
    }
  }
}