@use '../abstracts/variables_new.scss' as *;

.footer-v2 {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 24px;
  background-color: $primary-color;
  color: $text-color-1;
  padding: 4px 22px;
  display: flex;
  align-items: center;
  min-width: 320px;
  z-index: 10;
  box-shadow: 0px 1px 8px 0px #00000026;

  p {
    //styleName: Body/Body M - SemiBold;
    font-size: 12px;
    font-weight: 600;
    line-height: 16px;
    text-align: left;


    a {
      color: $text-color-1;

      &:hover {
        text-decoration: underline;
      }
    }
  }

  @media (max-width: $break-xl-max) {
    .hide-below-tablet {
      display: none;
    }
  }


  @media (max-width: $break-md-max) {
    flex-direction: column;
    padding: 6px 15px;
    height: initial;
    position: sticky;
    z-index: initial;

    p {
      margin: auto;
    }
  }
}