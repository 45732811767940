@use '../abstracts/variables_new.scss' as *;

.folder-modal-v2 {
  h2 {
    font-size: 24px;
    font-weight: 600;
    color: $color-indigo;
  }

  .sub-title {
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    color: $color-indigo;
  }

  &__form {

    &_actions {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  &__heading {
    background: rgba($bg-hover, 0.33);
    padding: 32px 32px;
    min-height: 112px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    column-gap: 4px;
    row-gap: 14px;

    h2 {
      font-size: 24px;
      font-weight: 600;
      line-height: 32px;
      text-align: left;
    }

    .btn-close-icon {
      width: 24px;
      height: 24px;
      padding: 0;
      flex: 0 0 24px;

      @media (max-width: $break-lg-max) {
        position: absolute;
        top: 24px;
        right: 32px;
        transform: translateY(-50%);
      }
    }

    @media (max-width: $break-lg-max) {
      flex-wrap: wrap;
      height: initial;
    }

    .heading-with-return-btn {
      display: flex;
      align-items: center;
      gap: 16px;

      h1 {
        font-size: 24px;
        font-weight: 600;
        line-height: 32px;
      }

      h2 {
        font-size: 14px;
        font-weight: 600;
        line-height: 16px;
        text-align: left;
      }

      @media (max-width: $break-md-max) {
        flex-direction: column;
        align-items: flex-start;
        gap: 8px;
      }
    }
  }

  &__content {
    padding: 24px 32px 40px 32px;
    background: #fff;
    border-radius: 0 0 8px 8px;
    @media (max-width: $break-md-max) {
      padding: 16px 16px;
    }

    .label {
      font-size: 14px;
      font-weight: 600;
      line-height: 16px;
      margin-bottom: 8px;
      display: flex;
      color: $color-indigo;
    }

    p {
      font-size: 12px;
      font-weight: 500;
      line-height: 16px;
      text-align: left;
      color: $text-color-1;
      word-break: break-word;
    }
  }

}