@use "../abstracts/variables_new.scss" as *;
@use "../abstracts/mixins.scss" as *;

.documents-content {
  @include collectionContent;
  overflow: auto;

  @include hideScrollbar();

  &__main {
    padding: 16px 24px;
    // height: 100%;

    @media (max-width: $break-md-max) {
      overflow: auto;
      padding: 16px;
      background: #fff;
      border-radius: 14px 14px 0 0;
      height: 100%;
    }

    &_actions {
      @include collectionContentMainActions;
    }

    &_mobile {
      @include collectionContentMainMobile;
    }
  }
}