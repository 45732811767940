@use '../abstracts/variables_new.scss' as *;

.layout-modal {

  .folder-modal-v2 {
    .hide-on-lg {
      display: none;
    }

    @media (max-width: $break-md-max) {
      background: #fff;
      position: fixed;
      left: 0;
      bottom: 0;
      width: 100%;
      border-radius: 50px 50px 0 0;

      .folder-modal-v2__heading {
        background: none;
        padding: 32px 20px 0px;

        h2 {
          font-size: 18px;
          color: $text-color-1;
        }

        .buttons-row {
          display: none;
        }
      }
     
      .buttons-row {
        display: flex;
        margin-top: 10px;
        gap: 10px;
      }

      .document-share__body {}
    }
  }
}