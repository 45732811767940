@use '../abstracts/variables_new.scss' as *;

.refresh-btn-mobile,
.fold-btn-mobile {
  display: none;
}

.single-document-preview {

  @media (max-width: $break-xl-max) {
    height: 100%;
    overflow: hidden;
  }

  @media (max-width: $break-md-max) {
    margin-bottom: 24px;
  }

  &__head {
    height: 76px;
    padding: 8px 16px 8px 24px;
    display: flex;
    align-items: center;
    position: sticky;
    top: 0;
    background: $primary-color;
    z-index: 100;

    @media (max-width: $break-xl-max) {
      background: $primary-color;

      .fold-btn {
        display: none;
      }

      .fold-btn-mobile {
        display: flex;
      }
    }

    @media (max-width: $break-md-max) {
      background: none;
      height: initial;
      padding: 22px 24px;
      display: block;
      .refresh-btn-mobile {
        margin-top: 16px;
        display: flex;
        width: 100%;
      }

      .refresh-btn {
        display: none;
      }

      span.svg-icon {
        svg {
          width: 18px !important;
        }
      }
    }

    h2 {
      display: flex;
      align-items: center;
      font-size: 24px;
      font-weight: 600;
      line-height: 32px;
      color: $color-indigo;
      justify-content: space-between;
      width: 100%;

      span.text {
        display: inline-block;
      }

      @media (max-width: $break-lg-max) {
        font-size: 18px;
        color: $text-color-1;
      }
      
      @media (max-width: $break-md-max) {
        width: initial;
      }

      span.svg-icon {
        cursor: pointer;

        svg {
          width: 18px;
        }
      }
    }
  }

  &__body {
    padding: 0 24px 24px;

    @media (max-width: $break-xl-max) {
      padding: 0 24px 24px;
      background: $primary-color;
      overflow: auto;
      height: calc(100% - 76px);
      margin-top: -1px;
    }

    @media (max-width: $break-md-max) {
      padding: 0;
      background: #fff;

      &_loader-wrapper {
        padding: 15px;
      }
    }

    .react-pdf__Document {
      max-width: 100%;

      canvas {
        width: 100% !important;
        height: auto !important;
      }

      .react-pdf__Page {
        background-color: #fff;
        margin-bottom: 20px;

        .react-pdf__Page__textContent {
          display: none !important;
        }
      }
    }
  }
}