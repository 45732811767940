@use '../abstracts/variables_new.scss' as *;

.modal {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 9999;
  overflow-y: auto;
  display: flex;
  align-items: center;
  justify-content: center;

  &--bg-2 {

    .modal__content {
      background-color: $bg-hover;
    }
  }

  &--no-padding {

    .modal__content {
      padding: 0;
    }
  }

  &--padding-sm {

    .modal__content {
      padding: 15px;
    }
  }

  &--no-bg {

    .modal__bg {
      background-color: rgba($primary-light-color, 0);
    }

    .modal__content {
      box-shadow: 0 12px 24px 0 rgba($shadow-color-7, 0.5);
    }
  }

  &__bg {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba($bg-color-1, 0.40);
  }

  &__inner {
    max-height: 100%;
    position: relative;
    width: 100%;
  }

  &__content-wrapper {
    padding: 50px 0;
    width: 100%;
  }

  &__content {
    max-width: 1191px;
    width: 90%;
    margin: 0 auto;
    background-color: $primary-light-color;
    position: relative;
    border-radius: 8px;
    z-index: 10;

    &_close {
      position: absolute;
      top: 5px;
      right: 5px;
      width: 34px;
      height: 34px;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      color: $nonary-text-color;
      cursor: pointer;
      transition: background-color .3s ease;
      z-index: 10;

      svg {
        font-size: 1.25rem;
        display: block;
      }

      &:hover {
        background-color: $primary-bg-color;
      }

      &:active {
        background-color: $octonary-color;
      }
    }

    .react-pdf__Page__textContent {
      color: transparent !important;
      opacity: 0.5 !important;
    }

    .react-pdf__Page__textContent ::selection {
      background: #0000ff !important;
    }

    .react-pdf__Page {
      background-color: rgb(82, 86, 89);
      padding: 10px;
      overflow: hidden;
    }

    .react-pdf__Page__canvas {
      width: 100% !important;
      height: auto !important;
    }
  }

  &--small {

    .modal__content {
      max-width: 400px;
    }
  }

  &--medium {

    .modal__content {
      max-width: 481px;
    }
  }

  &--medium-2 {

    .modal__content {
      max-width: 593px;
    }
  }

  &--mid-large {
    .modal__content {
      max-width: 797px;
    }
  }

  &--large {

    .modal__content {
      max-width: 1200px;
    }
  }

  &--xx-large {

    .modal__content {
      max-width: 1400px;
    }
  }

  iframe {

    #container-image {
      display: none;
    }
  }

  &--v2 {
    .modal__content {
      padding: 40px 0 0;
      border-radius: 8px;
      max-width: 846px;
    }

    .modal-inner {

      h2 {
        font-size: 1.4375rem;
        font-weight: 500;
        letter-spacing: 0.29px;
        color: $secondary-text-color;
        text-align: center;
        margin-bottom: 42px;
        padding: 0 10px;
      }

      &__head {
        padding: 0 40px;
        width: calc(100% + 80px);
        margin-left: -40px;

        @media (max-width: $break-sm-max) {
          padding: 0 20px;
          width: calc(100% + 40px);
          margin-left: -20px;
        }

        ul {
          display: flex;
          justify-content: center;
          align-items: center;
          border-bottom: 2px solid $nonary-border-color;

          li {
            color: rgba($tertiary-text-color, 0.69);
            font-size: 0.8125rem;
            font-weight: 500;
            padding-bottom: 13px;
            text-align: center;
            text-transform: uppercase;
            cursor: pointer;
            margin: 0 25px;
            position: relative;

            &.no-cursor {
              cursor: default;
            }

            &.active {
              color: $primary-color;

              &::after {
                content: '';
                position: absolute;
                bottom: -2px;
                left: 0;
                width: 100%;
                height: 2px;
                background-color: $primary-color;
              }
            }
          }
        }
      }

      &__body {
        padding: 30px 40px 0;

        @media (max-width: $break-sm-max) {
          padding: 30px 20px 0;
        }
      }
    }
  }

  &.onespan-placement-modal {

    .modal__content {
      // min-width: 960px;
      // overflow-x: auto;
      overflow: hidden;

      .react-pdf__Page__canvas {
        width: 774px !important;
        height: 1094px !important;
      }
    }
  }
}

.modal-heading {
  background: rgba($bg-hover, 0.33);
  padding: 36px 32px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.modal-content {
  padding: 24px 32px;
}

.signature-placement-modal {
  .iframe-wrapper {
    overflow: hidden;
    height: 775px;
  }

  .iframe-container {
    width: 1200px;
    margin: 0 auto;
    overflow: hidden;
    position: relative;

    .overlay-12 {
      position: absolute;
      top: 167px;
      left: 50%;
      width: 930px;
      height: 1315px;
      transform: translateX(-50%);
      background-color: rgba(0, 0, 0, 0.2);
      overflow-y: scroll;
      display: none;

      .pos-1 {
        position: absolute;
        height: 136px;
        left: 0;
        top: 0;
        width: 202px;
        background-color: rgba(green, 0.2);
      }
    }
  }
}