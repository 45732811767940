@use '../abstracts/variables_new.scss' as *;

.cover-page-v2 {

  @media (max-width: $break-md-max) {
    max-width: 100%;
    padding: 20px 23px;
  }

  h2 {
    font-weight: 600;
    font-size: 1rem;
    line-height: 1.36;
    margin-bottom: 17px;
    color: $color-indigo;

    @media (max-width: $break-md-max) {
      display: none;
    }
  }

  &__section {

    .color-wrapper {
      margin-bottom: 6px;
      display: flex;
      column-gap: 10px;
      align-items: center;

      &.disabled {
        opacity: 0.5;
        // filter: grayscale(1);
        cursor: not-allowed;
        pointer-events: none;
      }

      @media (max-width: $break-md-max) {
        margin-bottom: 20px;
      }
    }

    .checkbox-v2 {
      margin-bottom: 7px;
    }

    .radio-group {
      margin-bottom: 18px;
    }

    .dnd-wrapper {
      @media (max-width: $break-md-max) {
        display: block;
        margin-top: 23px;
      }

      .file-upload-dnd {
        display: flex;
        align-items: center;
        background: $bg-purple2;
        border: 1px solid $bg-hover;
        padding: 16px;
        border-radius: 8px;

        &.disabled {
          opacity: 0.5;
          // filter: grayscale(1);
          cursor: not-allowed;
          pointer-events: none;
        }

        @media (max-width: $break-md-max) {
          width: 100%;
          flex-direction: column;
        }

        &__box {

          button {
            max-width: 197px;

            @media (max-width: $break-md-max) {
              max-width: 239px;
            }
          }
        }
      }
    }
  }

  &__foot {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 24px;
    gap: 6px;
    align-items: center;
    margin-right: 0px;
    max-width: 1426px;

    &.show-preview {
      width: calc(100% - 620px);
    }

    .preview-btn {
      // display: none;

      // @media (max-width: $break-md-max) {
      //   display: flex;
      // }
    }

    @media (max-width: $break-md-max) {
      display: inline-flex;
      justify-content: flex-start;
      margin-top: 25px;
    }
  }
}