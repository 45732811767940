// Colors
$primary-color: #006EFF;
$secondary-color: #FA6D6D;
$tertiary-color: #31DDFE;
$quaternary-color: #EDAA16;
$quinary-color: #38B648;
$senary-color: #BBBBBB;
$septenary-color: #2CB23C;
$octonary-color: #CCC;
$danger-color: #FA4040;

$primary-color-light: #E4EDF9;
$secondary-color-light: #ed5534;
$tertiary-color-light: #bdf4ff;
$quaternary-color-light: #ffb000;
$quinary-color-light: #E3F7E7;

$primary-light-color: #fff;
$secondary-light-color: #BFC4CC;
$primary-dark-color: #000;
$secondary-dark-color: #38393f;
$tertiary-dark-color: #10203E;

$primary-bg-color: #f4f4f4;
$secondary-bg-color: #1B1D24;
$tertiary-bg-color: #f3f3f3;
$quaternary-bg-color: #23252D;
$quinary-bg-color: #e0e0e0;
$senary-bg-color: #c3c2c2;
$septenary-bg-color: #fffbfb;
$octonary-bg-color: #fafafa;
$nonary-bg-color: #f9f9fb;
$denary-bg-color: #e8e8e8;
$bg-color-11: #FBFAFA;
$bg-color-12: #FFECCA;
$bg-color-13: #D5AD65;
$bg-color-14: #5561FE;
$bg-color-15: #0012FF;
$bg-color-16: #FF3D65;
$bg-color-17: #0ACB93;
$bg-color-18: #08BD89;
$bg-color-19: #EFEFF0;

$primary-text-color: #000;
$secondary-text-color: #34353B;
$tertiary-text-color: #2E2F35;
$quaternary-text-color: #7e7e7e;
$quinary-text-color: #bbb;
$senary-text-color: #c1c1c1;
$septenary-text-color: #48494D;
$octonary-text-color: #8D8D8D;
$nonary-text-color: #767676;
$denary-text-color: #cdcdcd;
$text-color-11: #1b1725;
$text-color-12: #454855;
$text-color-13: #07A97A;

$primary-border-color: #e5e5e5;
$secondary-border-color: #f3f3f3;
$tertiary-border-color: #ddd;
$quaternary-border-color: #bbb;
$quinary-border-color: #f0eded;
$senary-border-color: #dbdbdb;
$septenary-border-color: #f0f0f0;
$octonary-border-color: #979797;
$nonary-border-color: #DADCE0;
$denary-border-color: #f1f1f1;

$status-color: #139AE8;
$status-color-2: #2BB23A;
$status-color-2-bg: #E3F7E7;
$status-color-3: #ED5423;
$status-color-3-bg: #FBDACF;

$shadow-color: #b2b1b1;
$shadow-color-2: #b9b9b9;
$shadow-color-3: #969696;
$shadow-color-4: #a7a7a7;
$shadow-color-5: #707070;
$shadow-color-6: #878787;
$shadow-color-7: #7F7F7F;
$shadow-color-8: #a1a1a1;

$modal-overlay: #898989;

$form-el-bg-color-1: #FAFBFC;

$form-el-border-color-1: #DFE1E6;

$form-el-text-color-1: #999;

$notification-success-bg: #EDF7EE;
$notification-success-border: #5DB661;
$notification-success-text: #457C46;
$notification-success-icon: #65BB6A;
$notification-danger-bg: #FDECEB;
$notification-danger-border: #F77A72;
$notification-danger-text: #C0362D;
$notification-danger-icon: #F6554A;
$notification-warning-bg: #FFF4E5;
$notification-warning-border: #DCAD6C;
$notification-warning-text: #B57726;
$notification-warning-icon: #FFA229;
$notification-info-bg: #E9F4FD;
$notification-info-border: #389FF4;
$notification-info-text: #2A75B2;
$notification-info-icon: #389FF4;

$arrow-color: #ccc;


// Fonts
$ff-primary: 'Poppins', sans-serif;

// Breakpoints
$break-xxs-max: 375px;
$break-xxs-min: 376px;
$break-xs-max: 480px;
$break-xs-min: 481px;
$break-sm-max: 575px;
$break-sm-min: 576px;
$break-md-max: 767px;
$break-md-min: 768px;
$break-lg-max: 991px;
$break-lg-min: 992px;
$break-xl-max: 1199px;
$break-xl-min: 1200px;