@use '../abstracts/variables' as *;

.letter-circle {
  display: flex;
  width: 30px;
  height: 30px;
  font-size: 14px;
  font-weight: 700;
  text-transform: uppercase;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  color: $primary-light-color;
  position: relative;
  background-color: $quinary-color;

  &--1 {
    background-color: $primary-color;
  }

  &--2 {
    background-color: $secondary-color;
  }

  &--3 {
    background-color: $tertiary-color;
  }

  &--4 {
    background-color: $quaternary-color;
  }
}