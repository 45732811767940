@use '../abstracts/variables_new.scss' as *;

.custom-tooltip-v2 {
  position: absolute;
  top: 0;
  left: 0;
  font-size: 14px;
  font-weight: 600;
  line-height: 32px;
  padding: 8px 8px;
  text-align: center;
  background-color: #fff;
  max-width: 850px;
  width: max-content;
  color: $color-indigo;
  opacity: 0;
  transition-property: opacity;
  transform-origin: center center;
  text-align: left;
  backface-visibility: hidden;
  border-radius: 8px;
  z-index: 9999999;
  box-shadow: 0px 0px 24px 0px #00000026;

  @media (max-width: $break-md-max) {
    display: none;
  }

  &::after {
    border: solid transparent;
    content: '';
    height: 0;
    width: 0;
    position: absolute;
    border-color: rgba(255,255,255,0);
    border-width: 6px;
  }

  &--top {
    transform: translate(0, calc(-100% - 8px)) scale(1);

    &::after {
      top: calc(100% - 1px);
      left: 50%;
      border-top-color: #fff;
      margin-left: -6px;
    }
  }
  &--top-input {
    transform: translate(-50%, calc(-100% - 10px)) scale(1);
  }
  &--bottom {
    transform: translate(-50%, 15px) scale(1);

    &::after {
      bottom: 100%;
      left: 50%;
      border-bottom-color: #fff;
      margin-left: -6px;
    }
  }
  &--left {
    transform: translate(-15px, -50%) scale(1);

    &::after {
      top: 50%;
      left: 100%;
      border-left-color: #fff;
      margin-top: -6px;
    }
  }
  &--right {
    transform: translate(15px, -50%) scale(1);

    &::after {
      top: 50%;
      right: 100%;
      border-right-color: #fff;
      margin-top: -6px;
    }
  }
  &--top-left {
    transform: translate(0, calc(-100% - 15px)) scale(1);
    text-align: left;

    &::after {
      top: 100%;
      left: 0%;
      border-top-color: #fff;
      margin-left: 10px
    }
  }
  &--top-right {
    transform: translate(-100%, calc(-100% - 15px)) scale(1);
    text-align: right;

    &::after {
      top: 100%;
      right: 0%;
      border-top-color: #fff;
      margin-right: 10px
    }
  }
  &--bottom-left {
    transform: translate(0, 15px) scale(1);

    &::after {
      bottom: 100%;
      left: 0%;
      border-bottom-color: #fff;
      margin-left: 10px
    }
  }
  &--bottom-right {
    transform: translate(-100%, 15px) scale(1);

    &::after {
      bottom: 100%;
      right: 0%;
      border-bottom-color: #fff;
      margin-right: 10px
    }
  }
  &--left-top {
    transform: translate(-15px, 0) scale(1);

    &::after {
      top: 10px;
      left: 100%;
      border-left-color: #fff;
    }
  }
  &--left-bottom {
    transform: translate(-15px, -100%) scale(1);

    &::after {
      bottom: 10px;
      left: 100%;
      border-left-color: #fff;
    }
  }
  &--right-top {
    transform: translate(15px, 0) scale(1);

    &::after {
      bottom: 10px;
      right: 100%;
      border-right-color: #fff;
    }
  }
  &--right-bottom {
    transform: translate(15px, -100%) scale(1);

    &::after {
      bottom: 10px;
      right: 100%;
      border-right-color: #fff;
    }
  }
}