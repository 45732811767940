@use '../abstracts/variables.scss' as *;

.agency-fields-wrapper {
  max-width: 685px;
  display: flex;
  flex-wrap: wrap;

  .agency-field {
    width: 320px;

    &:nth-child(2n+1) {
      margin-right: 45px;
    }

    @media (max-width: $break-xs-max) {
      width: 100%;
      max-width: 320px;

      &:nth-child(2n+1) {
        margin-right: 0;
      }
    }

    &__label {
      font-size: 0.9375rem;
      font-weight: 500;
      color: #34353B;
      margin-bottom: 10px;
    }

    &__date {
      margin-bottom: 34px;

      .react-datepicker-wrapper {
        width: 100%;

        input {
          background-color: #FAFBFC;
          border: 1px solid #DFE1E6;
          color: #000;
          border-radius: 2px;
          height: 36px;
          font-size: 0.9375rem;
          font-weight: 400;
          padding: 0 14px;
          width: 100%;
          display: block;

          &:disabled {
            background-color: gainsboro;
          }
        }
      }

    }
  }
}