@use '../abstracts/variables_new.scss' as *;

.template-preview-mobile {
  position: fixed;
  top: 134px;
  right: -100%;
  display: none;
  width: 100%;
  height: calc(100% - 134px - 64px);
  flex-direction: column;
  transition: right .3s ease;
  z-index: 99;
  background-color: #fff;

  &.opened {
    right: 0;
  }

  @media (max-width: $break-md-max) {
    display: flex;
  }

  &__head {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;
    padding: 24px 46px;
    position: relative;

    .close {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      left: 19px;
      width: 20px;
      height: 20px;
      display: flex;
      align-items: center;
      justify-content: center;

      svg {
        width: 14px;
      }
    }

    h2 {
      font-weight: 600;
      font-size: 1.125rem;
      line-height: 1.33;
      margin-bottom: 2px;
      padding: 0 10px;
    }
    
    p {
      font-size: 1rem;
      line-height: 1.13;
      color: $text-color-1;
      padding: 0 10px;
    }
  }

  &__preview {
    flex: 1;
    overflow-y: auto;
    background-color: $bg-color-1;
    padding: 23px 21px 14px;

    .react-pdf__Page__textContent {
      display: none !important;
    }

    .react-pdf__Page {
      margin-bottom: 10px;
      background-color: #fff;
      box-shadow: 0 3px 21px 0 rgba(#000, 0.16);
      
      canvas {
        max-width: 100%;
        width: 100%;
        height: auto !important;
      }
    }
  }

  &__create-doc {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: $bg-color-1;

    .icon-btn {
      width: 48px;
      height: 48px;
      border-radius: 50%;
      position: relative;
      top: -34px;

      svg {
        width: 20px !important;
        height: auto;
      }
    }
  }
}