@use '../abstracts/variables_new.scss' as *;
@use "../abstracts/mixins.scss" as *;

.single-document-side-narrow-panel {
  position: fixed;
  top: 100px;
  right: 24px;
  width: 57px;
  height: calc(100% - 100px - 24px);
  background-color: $primary-color;
  z-index: 21;
  border-radius: 8px 8px 0 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 16px;
  gap: 16px;

  .fold-btn {
    padding: 0;
    width: 32px;
  }

  .eye-button {
    color: $color-indigo;
    width: 32px;
    height: 32px;
    border-radius: 4px;
    &:hover {
      background-color: $bg-hover;
    }
  }

  @media (max-width: $break-lg-max) {
    display: none;
  }
}

.single-document-side-panel {

  &__toggle {
    margin-left: -13px;
    position: absolute;
    top: 14px;
    left: 0;
    transform: translateX(-100%) rotate(180deg);
    width: 23px;
    height: 23px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    border: 1px solid $highlighted-color-1;
    background-color: #fff;
    cursor: pointer;
    transition: transform .3s ease;

    svg {
      width: 9px;
    }

    @media (max-width: $break-md-max) {
      display: none;
    }
  }

  &__body {
    @include hideScrollbar();
    overflow: auto;
    height: 100%;
    border-radius: 8px;
  }
}