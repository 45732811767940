@use '../abstracts/variables_new.scss' as *;

.signatures-success-v2 {
  padding: 40px;

  &__top {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20px 0;

    h2 {
      font-weight: 600;
      font-size: 1rem;
      margin-bottom: 10px;
      color: $primary-color;
    }
  }
}