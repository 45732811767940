@use '../abstracts/variables_new.scss' as *;

.folders-popup-v2 {
  font-family: $ff-primary;
  color: $primary-color;
  position: absolute;
  top: -500px;
  left: -500px;
  // transform: translateX(-50%);
  width: 253px;
  background-color: $bg-purple2;
  border: 5px solid #fff;
  box-shadow: 0 2px 6px 0 rgba(#000, 0.16);

  border-radius: 4px;
  z-index: 10000;
  user-select: none;

  @media (max-width: $break-md-max) {
    // left: auto;
    // transform: translateY(0);
    // right: 0;
  }

  // &::after {
  //   top: 100%;
  //   left: calc(50% - 3px);
  //   border: solid transparent;
  //   content: " ";
  //   height: 0;
  //   width: 0;
  //   position: absolute;
  //   pointer-events: none;
  //   border-color: rgba(#fff, 0);
  //   border-top-color: #fff;
  //   border-width: 6px;
  //   margin-left: -6px;

  //   @media (max-width: $break-md-max) {
  //     left: auto;
  //     right: 26px;
  //   }
  // }

  &__head {
    padding: 10px;

    &.root {

      .back {
        display: none;
      }
    }

    &.inner {

      .main {
        display: none;
      }
    }

    .main,
    .back {
      display: flex;
      align-items: center;

      h5 {
        font-weight: 600;
        font-size: 14px;
        line-height: 16px;
        color: $color-indigo;
      }

      span.icon {
        margin-right: 6px;
        width: 20px;
        height: 20px;
        display: flex;
        align-items: center;
        justify-content: center;

        svg {
          width: 16px;
        }

        &.delete-category {
          margin-left: auto;
          margin-right: 0;
          cursor: pointer;

          svg {}
        }
      }
    }

    .back {

      h5 {
        max-width: 150px;
        text-overflow: ellipsis;
        overflow: hidden;
      }

      span.icon {
        cursor: pointer;
        position: relative;
        transition: background-color .3s ease;
        border-radius: 4px;

        svg {
          width: 11px;
        }

        &:hover {
          background-color: $bg-color-1;

          svg {
            fill: #fff;
          }
        }

        &::after {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          content: '';
        }
      }

      &__actions {
        margin-left: auto;
        height: 24px;
        display: flex;
        align-items: center;

        .tooltip-wrapper {
          height: 24px;
        }
      }
    }

    svg {}

    h5 {
      margin-bottom: 0;
      text-transform: uppercase;
    }
  }

  &__body {
    height: 153px;
    overflow-y: auto;
    position: relative;

    p.no-data {
      font-size: 0.875rem;
      padding: 7px 18px;
    }

    ul {
      padding: 1px 8px 0;

      &.root {

        li.inner {
          display: none;
        }
      }

      &.inner {

        li.root {
          display: none;
        }
      }

      li {
        margin-bottom: 8px;

        .root {
          display: flex;
          align-items: center;
          padding: 5px 10px;
          cursor: pointer;
          transition: background-color .3s ease;
          background: #fff;

          &:hover {
            background-color: $bg-hover;
          }

          .delete-template {
            cursor: pointer;

            svg {}
          }

          &.data {

            .svg-icon svg {
              width: 14px;
            }
          }
        }

        &.hover {
          background-color: darken($bg-color-1, 10);
        }

        .svg-icon {

          svg {
            width: 17px;
          }
        }

        p {
          margin-left: 10px;
          font-size: 0.875rem;
          color: $color-indigo;
        }

        span {
          display: block;

          &.go-to-folder {
            margin-left: auto;

            svg {
              width: 11px;
            }
          }
        }

        .sublevel {
          position: absolute;
          left: 0;
          top: 0;
          width: 100%;
          height: 100%;
          background-color: #fff;

          ul {
            position: relative;
          }
        }
      }
    }
  }

  &__foot {
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    z-index: 1;
    position: relative;
    gap: 12px;

    .btn {
      margin-left: 5px;
    }
  }

  .cancel-btn {
    margin-left: auto;
  }
}