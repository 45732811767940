@use '../abstracts/variables_new.scss' as *;

.summary-variables {
    user-select: none;
    width: calc(100% + 4px);
    padding-right: 4px;

    @media (max-width: $break-md-max) {
        width: 100%;
        padding-right: 0;
        padding-top: 5px;
    }

    &__section {
        margin-bottom: 16px;

        @media (max-width: $break-md-max) {
            margin-bottom: 8px;
        }

        h4 {
            background-color: $bg-hover;
            padding: 9.5px 12px;
            border-radius: 8px;
            display: flex;
            align-items: center;
            font-weight: 400;
            font-size: 14px;
            color: $color-indigo;
            position: relative;
            cursor: pointer;

            &:hover {
                background-color: $bg-selected;
            }

            span.num {
                width: 16px;
                height: 16px;
                display: flex;
                align-items: center;
                justify-content: center;
                border: 1px solid $primary-color;
                border-radius: 50%;
                margin-right: 5px;
                font-size: 0.625rem;
                color: $color-indigo;

                @media (max-width: $break-md-max) {
                    width: 24px;
                    min-width: 24px;
                    height: 24px;
                    font-size: 0.9375rem;
                    margin-right: 8px;
                }
            }

            span.text {
                display: block;
                max-width: 176px;
                font-size: 14px;
                font-weight: 600;
                line-height: 16px;

                @media (max-width: $break-md-max) {
                    max-width: none;
                    padding-right: 5px;
                }
            }

            span.progress {
                margin-left: auto;
                color: $text-purple;
                font-size: 14px;
                font-weight: 600;
                line-height: 21px;

                display: block;

                &.empty {
                    color: $highlighted-color-3;
                }

                &.done {
                    color: $highlighted-color-2;
                }

                @media (max-width: $break-md-max) {
                    font-size: 0.9375rem;
                    min-width: 40px;
                    text-align: right;
                }
            }
        }

        ul {
            padding: 0;
            margin-top: 4px;

            li {
                display: flex;
                align-items: center;
                padding: 8px 12px;
                margin-bottom: 4px;
                background-color: #fff;
                border-radius: 8px;
                cursor: pointer;

                &:hover {
                    background-color: $bg-hover;
                }

                p {
                    width: 100%;
                    min-width: 100%;
                    font-size: 12px;
                    color: $color-indigo;
                    font-weight: 500;
                    line-height: 16px;

                    &.input-value {
                        display: block;
                        word-wrap: break-word;
                        color: $text-purple;
                        // text-align: right;
                    }
                }
            }
        }


        // &--active {
        //     h4 {
        //         background-color: $bg-hover;
        //         color: #fff;

        //         @media (max-width: $break-md-max) {
        //             background-color: $primary-color !important;
        //         }

        //         &:hover {
        //             background-color: $primary-color;

        //             @media (max-width: $break-md-max) {
        //                 background-color: $primary-color !important;
        //             }
        //         }

        //         span.num {
        //             border-color: #fff;
        //         }

        //         &::after {
        //             display: none;
        //         }
        //     }
        // }
    }
}