@use '../abstracts/variables_new.scss' as *;

.move-to-folder-v2 {

  &__head {
    border-bottom: 1px solid $border-color-1;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 10px;

    h4 {
      font-family: $ff-primary;
      font-weight: 400;
      font-size: 1rem;
      color: $primary-color;

      strong {
        font-weight: 600;
      }
    }
  }

  &__body {
    padding: 20px 0;

    &_top {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .breadcrumbs {
        font-size: 0.875rem;

        &__item {

          &.active {
            font-weight: 600;
          }
        }
      }

      .icon-btn {

        .svg-icon svg {
          width: 13px;
        }
      }
    }

    &_main {
      padding: 10px 0;

      .folder-inner {
        display: flex;
        align-items: center;
        font-weight: 600;
        font-size: 14px;
        line-height: 16px;
        padding: 8px;
        transition: background-color .3s ease;
        cursor: pointer;
        border-radius: 4px;
        &:hover {
          background-color: $bg-hover;
        }

        svg {
          margin-right: 8px;
        }
      }

      .no-folders {
        text-align: center;
        padding: 50px 0;

        h3 {
          font-size: 1rem;
          font-weight: 600;
          color: $color-indigo;
        }

        p {
          font-size: 14px;
        }
      }

      ul.templates-list {

        li {
          display: flex;
          align-items: center;
          font-size: 0.875rem;
          margin-bottom: 10px;
          padding: 5px;
          transition: background-color .3s ease;

          &:hover {
            background-color: $bg-color-1;
          }

          svg {
            margin-right: 5px;
          }

          .delete {
            margin-left: auto;
            cursor: pointer;
          }
        }
      }
    }
  }

  &__foot {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}