@use '../abstracts/variables_new.scss' as *;

.signature-templates-modal-v2 {
  font-size: 0.875rem;

  h3 {
    margin-bottom: 15px;
    font-size: 1rem;
    font-weight: 600;
    color: $primary-color;

    @media (max-width: $break-md-max) {
      font-size: 1rem;
    }
  }

  ul {

    li {
      display: flex;
      align-items: center;
      margin-bottom: 10px;

      @media (max-width: $break-md-max) {
        font-size: 0.9375rem;
      }

      &:last-child {
        margin-bottom: 0;
      }

      .name {

        .input-v2 {
          margin-bottom: 0;
        }
      }

      .actions {
        display: flex;
        align-items: center;
        margin-left: auto;

        .icon-btn {
          margin-left: 4px;
        }
      }
    }
  }
}