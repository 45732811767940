@use '../abstracts/variables_new.scss' as *;
@use "../abstracts/mixins.scss" as *;

.single-document {
    // display: flex; // this was causing inputs in main body section to have more than 100% width when a lot of text was in inputs
    height: 100%;
    display: flex;
    flex-direction: column;
    margin-left: 290px;
    transition: padding-right 0.3s ease;
    overflow: auto;
    // width: 100%;
    @include hideScrollbar();

    @media (max-width: 991px) {
        margin-left: 266px;
    }

    @media (max-width: $break-md-max) {
        margin-left: 0px !important;
        // position: fixed;
        // top: 180px;
        // left: 0;
        // width: 100%;
        // min-width: 320px;
        // height: calc(100% - 180px - 64px);
        // padding: 0 0 70px 0;
        // overflow-y: auto;
    }

    @media (min-width: 1001px) {
        padding-right: 80px;
    }



    &.opened {
        // padding-right: 677px;
        width: 100%;
        max-width: calc(66% - 260px);

        // @media (max-width: 1400px) {
        //     padding-right: 77px;
        // }

        // @media (max-width: 1300px) {
        //     padding-right: 73px;
        // }

        @media (max-width: 1200px) {
            padding-right: 0;
        }

        .single-document__right {
            right: 24px;
            display: block;

            @media (max-width: $break-md-max) {
                right: 0px;
            }

            .single-document-side-panel__toggle {
                transform: rotate(0deg) translateX(-50%);
            }
        }
    }

    &__left {
        display: flex;
        flex-direction: column;
        width: 290px;
        height: calc(100% - 24px);
        top: 0px;
        left: 75px;
        position: fixed;
        background-color: $primary-color;
        padding-bottom: 38px;
        @include hideScrollbar();

        @media (min-width: $break-lg-max) {
            overflow: auto;
        }

        @media (max-width: $break-lg-max) {
            width: 266px;
        }

        @media (max-width: $break-md-max) {
            width: 100%;
            min-width: 320px;
            height: initial;
            top: 0px;
            left: 0;
            position: relative;
            padding-bottom: 8px;
        }

        .head {
            padding: 40px 16px 24px;

            @media (max-width: $break-md-max) {
                display: none;
            }

            h1 {
                font-weight: 600;
                font-size: 24px;
                line-height: 32px;
                color: $text-color-1;
            }
        }

        .doc-info {
            padding: 0 0px 24px 0;
            border-bottom: 0.5px solid $border-color-1;
            display: block;

            @media (max-width: $break-md-max) {
                display: none;
                padding: 0 0px 0px 0;
                margin: 8px 0;
                border-bottom: 0;

                &.active {
                    display: block;
                }
            }

            .label-title {
                font-size: 12px;
                font-weight: 500;
                line-height: 16px;
                display: flex;
                align-items: center;
                gap: 9.3px;
                color: $text-purple;

                &.color-indigo {
                    color: $color-indigo;
                }

                .status-dot {
                    display: inline-block;
                    width: 9.33px;
                    flex: 0 0 9.33px;
                    height: 9.33px;
                    border-radius: 50px;
                    background: $text-purple;
                    border: 2px solid $primary-color;
                    box-shadow: 0px 0px 0px 2px $text-purple;
                }
            }

            .status-circle {
                width: 16px;
                height: 16px;
                border-radius: 50px;
                display: inline-block;
                background: #E0E0E0;
                margin-right: 8px;
                vertical-align: middle;

                &.green {
                    background: $highlighted-color-2;
                }
            }

            .title-input-wrapper {
                position: relative;
                margin-bottom: 24px;

                span {
                    position: absolute;
                    right: 8px;
                    top: 50%;
                    transform: translateY(-50%);
                    width: 18px;
                    color: #E8025F;
                }
            }

            h2 {
                font-size: 14px;
                font-weight: 600;
                line-height: 20px;
                outline: 0;
                width: 100%;
                max-width: 100%;
                min-height: 40px;
                padding: 10px 24px 10px 16px;
                border: 2px solid $bg-hover;
                background: #fff;
                position: relative;
                border-radius: 4px;
                word-wrap: break-word;
                word-break: break-all;

                &:hover {
                    background-color: $bg-hover;
                }

                &:focus {
                    border: 2px solid #E8025F;
                }

            }

            .status-row {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: center;
                margin-bottom: 16px;
            }

            .select-v2 {
                width: 100%;
            }

            .doc-progress {
                font-size: 14px;
                font-weight: 600;
                line-height: 21px;
                color: $text-color-1;

                &.green {
                    color: $highlighted-color-2;
                }

                &.red {
                    color: $highlighted-color-3;
                }
            }

            .doc-status {
                font-size: 0.75rem;
                line-height: 1.17;
                margin-bottom: 11px;
                display: flex;
                align-items: center;

                span {
                    width: 7px;
                    height: 7px;
                    background-color: $bg-color-4;
                    border-radius: 50%;
                    margin-right: 6px;

                    &.ready {
                        background-color: $highlighted-color-2;
                    }
                }
            }

            .doc-version {
                position: relative;
                margin-bottom: 1rem;
                display: flex;
                align-items: flex-start;
                flex-direction: column;

                svg {
                    width: 16px;
                    color: $text-purple;
                }

                p {
                    font-weight: 600;
                    font-size: 14px;
                    line-height: 21px;
                    color: $text-color-1;
                }
            }

            .doc-attachment-parent {
                background: #fff;
                padding: 16px 8px;
                border-radius: 8px;
            }

            .attachment-list {

                li {
                    display: flex;
                    align-items: center;
                    gap: 8px;
                    font-size: 14px;
                    font-weight: 600;
                    line-height: 1;
                    color: $text-color-1;
                    margin-bottom: 16px;

                    .svg-icon {
                        svg {
                            width: 18px;
                        }
                    }

                    .file-name {
                        white-space: nowrap;
                        white-space: pre-wrap;
                        width: 100%;
                        overflow: hidden;
                        text-overflow: ellipsis;
                    }
                }
            }

            .doc-attachment {
                position: relative;
                display: flex;
                align-items: center;
                color: $text-purple;
                margin-bottom: 16px;
                gap: 4px;
                svg {
                    width: 15.3px;
                }

                .count {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    color: $text-purple;
                    //styleName: Body/Body S - Bold;
                    font-size: 11px;
                    font-weight: 500;
                    line-height: 16px;
                    text-align: left;
                    margin-left: auto;
                }

            }

            .modify-btn {
                svg {
                    width: 18px;
                }
            }
        }

        .summary {
            max-height: 520px;

            @media (max-width: $break-md-max) {
                padding: 0;
                display: none;

                &.active {
                    display: flex;
                }
            }

            h3 {
                font-size: 18px;
                font-weight: 600;
                line-height: 24px;
                color: $text-color-1;
                margin-bottom: 24px;

                @media (max-width: $break-md-max) {
                    display: none;
                }
            }

            &__main {
                overflow-y: auto;
                flex: 1;
                min-height: 0;
            }
        }

        .search {
            padding: 12px;
        }
    }

    &__main {
        padding-top: 16px;
        flex: 1;
        display: flex;
        flex-direction: column;

        @media (max-width: $break-md-max) {
            background-color: #fff;
            position: relative;
            top: 0px;
            width: 100%;
            border-radius: 12px 12px 0 0;
            min-width: 320px;
            padding: 0 0 24px;
            // overflow-y: auto;
            // height: 100vh;
            min-height: max-content;
        }
    }

    &__right {
        position: fixed;
        top: 100px;
        right: -653px;
        width: 100%;
        max-width: 34%;
        height: calc(100% - 100px - 24px);
        border-radius: 8px 8px 0 0;
        background-color: $primary-color;
        transition: right 0.3s ease;
        z-index: 21;
        display: none;

        @media (min-width: $break-md-min) and (max-width: $break-xl-max) {
            position: fixed;
            top: 50%;
            right: 50% !important;
            width: 80%;
            height: 90%;
            transform: translate(50%, -50%);
            background: #fff;
            border-radius: 10px;
            display: none;
            z-index: 100;
            width: 693px;
            max-width: 90%;

            &::before {
                content: "";
                position: fixed;
                left: 0;
                top: 0;
                right: 0;
                background: rgba($bg-color-1, 0.40);
                width: 200%;
                height: 200%;
                transform: translate(-25%, -25%);
                z-index: -1;
            }

        }

        @media (max-width: $break-md-max) {
            position: fixed;
            top: 78px;
            right: -100%;
            width: 100%;
            max-width: 450px;
            height: calc(100% - 64px);
            z-index: 1;
            background: #fff;
            border-radius: 50px 50px 0 0;
            padding-top: 20px;
            box-shadow: 0px 1px 8px 0px #00000026;
            display: none;

            &.active {
                display: block;
                right: 0 !important;
            }
        }
    }

    .single-document-tab {
        @media (max-width: $break-md-max) {
            z-index: 1;

            &.tab-active {
                z-index: 2;
            }
        }
    }

    .accordion-parent {
        padding: 0 16px;

        @media (max-width: $break-md-max) {
            margin-bottom: 8px;
        }

        .accordion-heading {
            width: 100%;
            height: 32px;
            padding: 0px 16.5px 0px 12px;
            border-radius: 4px;
            justify: space-between;
            background: $bg-hover;
            cursor: pointer;
            align-items: center;
            justify-content: space-between;
            display: none;
            font-size: 14px;
            font-weight: 600;
            line-height: 16px;
            color: $color-indigo;

            @media (max-width: $break-md-max) {
                display: flex;
            }

            .svg-icon {
                width: 6px;
                transform: rotate(-90deg);
            }

            &.active {
                background: $bg-selected;

                .svg-icon {
                    transform: rotate(90deg);
                }
            }


        }
    }
}

.single-document-mobile-title {
    display: none;
    align-items: center;
    justify-content: center;
    min-width: 0;
    height: 46px;
    border-top: 1px solid #fff;
    border-bottom: 1px solid #fff;
    background-color: $bg-color-1;
    padding: 0 22px;
    // position: fixed;
    top: 134px;
    left: 0;
    width: 100%;
    z-index: 3;

    h1 {
        font-weight: 600;
        font-size: 1.3125rem;
        line-height: 1.33;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;

        &:focus {
            text-overflow: unset;
        }
    }

    @media (max-width: $break-md-max) {
        display: none;
    }
}

.more-dropdown {
    position: absolute;
    top: calc(100% + 5px);
    right: 0;
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(#000, 0.16);
    max-width: 290px;
    gap: 8px;
    display: flex;
    flex-direction: column;

    ul {
        background-color: $bg-purple1;
        border-radius: 8px;
        padding: 8px;

        li {
            &.show-on-800 {
                display: none;

                @media (max-width: 800px) {
                    display: block;
                }
            }

            button {
                background-color: transparent;
                outline: 0;
                border: 0;
                display: flex;
                align-items: center;
                padding: 0 10px;
                height: 32px;
                font-family: $ff-primary;
                font-size: 0.75rem;
                color: $primary-color;
                min-width: 0;
                max-width: 100%;
                width: 100%;
                cursor: pointer;
                transition: background-color 0.3s ease, color 0.3s ease;

                span.text {
                    white-space: nowrap;
                    text-overflow: ellipsis;
                    overflow: hidden;
                    display: block;
                }

                svg {
                    width: 14px;
                    transition: fill 0.3s ease;
                }

                .icon {
                    width: 24px;
                    height: 24px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    margin-right: 11px;

                    svg {
                        width: 18px;
                        font-size: 1.5rem;
                    }
                }

                &:hover {
                    background-color: $primary-color;
                    color: #fff;
                }

            }

            .btn-hover {
                position: relative;

                &:hover {
                    .icon-btn__popover {
                        display: flex;
                    }
                }
            }
        }
    }
}

.duplicate-border {
    border: 1px solid $primary-color;
    padding: 8px;
    border-radius: 8px;
    position: relative;
    margin-bottom: 10px;
}

.no-border {
    border: none;
    padding: 0;
    margin: 0;

    // border: 1px solid $primary-color;
    // padding: 8px;
    // border-radius: 8px;
    // position: relative;
    // margin-bottom: 10px;

}

.duplicate-button {
    position: absolute;
    top: 10px;
    right: 25px;
    padding: 5px !important;
    border-radius: 4px !important;
    z-index: 10;

    span,
    .svg-icon {
        margin-right: 0;
    }

    svg {
        width: 18px;
    }
}

.delete-border {
    border: 1px solid $bg-color-4;
    padding: 8px;
    border-radius: 8px;
    margin-left: 20px;
    position: relative;
    margin-bottom: 10px;

    &::before {
        content: "";
        position: absolute;
        left: -20px;
        top: 0;
        height: 100%;
        width: 2px;
        background-color: $bg-color-4;
    }
}

.delete-button {
    svg {
        width: 19px;
    }
}