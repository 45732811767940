@use '../abstracts/variables_new.scss' as *;

.inject-modal-v2 {
  min-height: 500px;
  font-size: 14px;

  &__head {
    margin-bottom: 15px;
    background: $primary-color;
    padding: 24px;
    border-radius: 8px 8px 0 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    h2 {
      display: flex;
      align-items: center;
      font-size: 1.125rem;
      font-weight: 600;
      letter-spacing: 0.29px;
      color: $color-indigo;

      @media (max-width: $break-md-max) {
        font-size: 1.25rem;
      }

      svg {
        margin-right: 6px;
        width: 14px;

        @media (max-width: $break-md-max) {
          width: 18px;
          margin-right: 10px;
        }
      }
    }
  }

  &__search {
    padding: 10px 24px;
    border-radius: 4px;
    margin-bottom: 15px;

    .input-v2 {
      margin-bottom: 0;
    }
  }

  &__body {
    display: flex;
    border-radius: 0 0 8px 8px;
    overflow: hidden;

    @media (max-width: $break-md-max) {
      flex-direction: column;
    }

    &_left {
      width: 278px;
      margin-right: 20px;
      background: $primary-color;
      padding: 0 24px;

      @media (max-width: $break-md-max) {
        width: 100%;
        margin-right: 0;
        margin-bottom: 30px;
      }

      h6 {
        margin-bottom: 5px;
        padding: 10px 0;
      }

      ul {

        li {
          margin-bottom: 4px;

          @media (max-width: $break-md-max) {
            font-size: 0.9375rem;
          }

          .main {
            padding: 5px;
            border-radius: 4px;
            cursor: pointer;
            display: flex;
            align-items: center;
            padding-right: 20px;
            position: relative;

            .num {
              display: flex;
              align-items: center;
              justify-content: center;
              position: absolute;
              top: 0;
              right: 4px;
              height: 100%;
              font-weight: 600;
            }
          }

          svg {
            margin-right: 5px;
            width: 14px;

            @media (max-width: $break-md-max) {
              width: 16px;
              margin-right: 8px;
            }
          }

          .main {

            &:hover,
            &.active {
              background-color: $bg-hover;
            }
          }

          .subfolder {
            margin-left: 10px;
            margin-top: 4px;
          }
        }
      }
    }

    &_right {
      flex: 1;
      margin-right: 24px;

      @media (max-width: $break-md-max) {
        border-top: 1px solid $border-color-1;
        margin-right: 0px;

      }

      ul {

        li {
          display: flex;
          align-items: center;
          cursor: pointer;
          padding: 10px;
          border-radius: 4px;

          @media (max-width: $break-md-max) {
            font-size: 0.9375rem;
          }

          .icon {
            display: block;

            &.hover {
              display: none;
            }
          }

          svg {
            margin-right: 8px;
            width: 12px;

            @media (max-width: $break-md-max) {
              width: 16px;
              margin-right: 12px;
            }
          }

          &:hover {
            background-color: $bg-hover;

            .icon {
              display: none;

              &.hover {
                display: block;
              }
            }
          }
        }
      }

      p {

        &.no-data {
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 10px;
          border-radius: 4px;
          background-color: $bg-hover;
          font-size: 0.75rem;
        }
      }
    }
  }
}