@use '../abstracts/variables_new.scss' as *;

.response-loader-v2 {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  z-index: 1000000;
  background-color: rgba(#fff, 0.5);
  font-family: $ff-primary;

  &__content {
    text-align: center;
    background-color: $primary-color;
    padding: 16px;
    border-radius: 8px;

  }

  &__text {
    color: $color-indigo;
    padding: 16px;
    min-width: 300px;
    text-align: center;
    margin-bottom: 20px;
    border-radius: 8px;
    font-size: 14px;
    font-weight: 600;
  }

  &--dark {
    background-color: rgba(#000, 0.5);

    .response-loader-v2__content {
      background-color: #fff;
      padding: 50px;
      border-radius: 4px;
      box-shadow: 0 0 20px rgba(#000, 0.2);
      max-width: 100%;
      margin: 0 15px;

      .loader-v2 {
        width: 40px;
        height: 40px;
      }

      .response-loader-v2__text {
        min-width: auto;
        padding: 0;
        background-color: transparent;
        border-radius: 0;
        color: $color-indigo;
      }

      @media (max-width: $break-xs-max) {
        .response-loader-v2__text {
          min-width: auto;
        }
      }
    }
  }
}