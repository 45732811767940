@use '../abstracts/variables_new.scss' as *;

.message-v2 {
  border: 1px solid transparent;
  padding: 10px;
  text-align: center;
  border-radius: 4px;
  border-color: $primary-color;
  color: $color-indigo;
  font-size: 14px;
  font-weight: 500;

  &--success {
    border-color: $highlighted-color-2;
    color: $highlighted-color-2;
  }
  
  &--danger {
    border-color: $highlighted-color-3;
    color: $highlighted-color-3;
  }

  &--error {
    border-color: $error-color;
    color: $error-color;
  }
}