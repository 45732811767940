@use '../abstracts/variables' as *;

.date_picker {
  
  .react-datepicker-wrapper {
    max-width: 140px;
    
    .react-datepicker__input-container {

      &:focus {
        outline: 0;
      }
      
      input {
        background-color: rgba($primary-color, 0.12);
        padding: 5px 10px 4px 27px;
        border: 1px solid transparent;
        border-radius: 3px;
        font-family: $ff-primary;
        font-size: 0.875rem;
        font-weight: 700;
        color: $nonary-text-color;
        background-image: url('../../assets/images/today-icon.svg');
        background-repeat: no-repeat;
        background-position: 2px center;
        background-size: 22px 22px;
        width: 100%;

        &:focus {
          border-color: $primary-color;
          outline: 0;
        }
      }
    }
  }
}

.synthesis {

  .date_picker {

    .react-datepicker-wrapper {
      max-width: 100%;

      .react-datepicker__input-container {

        input {
          color: $primary-dark-color;
        }
      }
    }
  }
}