@use '../abstracts/variables_new.scss' as *;
@use '../abstracts/mixins.scss' as *;

.registered-mail-modal {
  margin: 0 auto;
  padding: 20px;

  .modal-title {
    font-size: 24px;
    font-weight: 600;
    line-height: 32px;

  }

  .modal-section-title {
    font-size: 18px;
    line-height: 24px;
    display: flex;
    flex-direction: row;
    align-items: center;
    font-weight: 600;
    margin-bottom: 24px;

    &--icon {
      font-size: 24px;
      display: flex;
      align-items: center;
      border-radius: 50%;
      padding: 4px;
      background-color: #f0f5ff;
      margin-right: 5px;
      width: 24px;
      height: 24px;

      .svg-icon {
        display: contents;
      }

      svg {
        width: 100%;
        height: 100%;
      }
    }
  }

  p {
    color: $text-color-1;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
  }

  .separator {
    background: $bg-hover;
    height: 1px;
    margin: 0 0 24px 0;
  }

  .modal-list {
    max-width: 100%;

    .modal-list-item:not(:last-child) {
      margin-bottom: 24px;
    }
  }

  .attention {
    padding: 16px;
    gap: 8px;
    border-radius: 8px;
    background: $bg-purple1;

    p {
      color: $color-indigo;
      line-height: 20px;
    }
  }

  .bottom-desc {
    //styleName: Body/Body M - SemiBold;
    font-size: 12px;
    font-weight: 500;
    line-height: 16px;
    text-align: left;
    color: $text-purple;
  }

  .modal-btn-list {
    display: flex;
    max-width: 100%;
    justify-content: space-around;
  }

  &__match-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding-top: 10px;
    padding-bottom: 10px;
    border-bottom: 0.5px #1d5083 solid;
  }

  &__btn-list {
    display: flex;
    max-width: 100%;
    justify-content: space-around;
  }

  .form-label {
    font-size: 12px;
    font-weight: 600;
    line-height: 16px;
    padding-bottom: 4px;
    padding-left: 8px;
    display: block;
    color: $color-indigo;
  }

  .form-input {
    width: 100%;
    height: 40px;
    padding: 0 10px;
    border: solid 2px $bg-hover;
    background: #fff;
    border-radius: 4px;
    margin-bottom: 0;
  }

  .form-wrapper {
    position: relative;
  }

  .form--group {

    &.invalid {
      .form-input {
        border-color: $highlighted-color-3 !important;
      }
    }
  }

  .terms-label {
    &.invalid {
      color: $highlighted-color-3;
    }
  }

  .text-error {
    color: $highlighted-color-3;
    margin-top: 10px;
    font-size: 14px;
    font-weight: 600;
    border: solid 2px $highlighted-color-3;
    padding: 7px;
    border-radius: 4px;
    text-align: center;
  }

  .text-success {
    color: #00a651;
    margin-top: 10px;
    font-size: 14px;
    border: solid 1px #00a651;
    padding: 7px;
    border-radius: 4px;
    text-align: center;
  }

  .status-value {
    padding: 4px 8px;
    border-radius: 50px;
    font-size: 12px;
    line-height: 16px;
    font-weight: 500;
    display: inline-flex;

    &.verified {
      color: #fff;
      background-color: $highlighted-color-2;
    }

    &.non-verified {
      color: #fff;
      background-color: $highlighted-color-3;
    }

    &.verification-pending {
      color: #fff;
      background-color: $highlighted-color-3;
    }

    &.pending {
      color: $color-indigo;
      background-color: #f0f5ff;
    }

    &.invitation-pending {
      color: $color-indigo;
      background-color: #f0f5ff;
    }

    &.confirmation-pending {
      color: $color-indigo;
      background-color: #f0f5ff;
    }

    &.api-access-pending {
      color: $color-indigo;
      background-color: #f0f5ff;
    }
  }

  .form--group {
    position: relative;
  }

  .input-tooltip {
    position: absolute;

    &.error .info svg {
      fill: $highlighted-color-3;
    }

    &.align-end {
      right: 20px;
      top: 8px;
    }
  }

  table tbody tr td {
    vertical-align: middle;
  }

  .checkbox-v2__label {
    color: black;
  }

  @media (max-width: $break-lg-max) {
    .w-half {
      width: 100%;
    }

    .mobile-flex-wrap {
      flex-wrap: wrap;
    }
  }

}