@use '../abstracts/variables_new.scss' as *;
@use '../abstracts/mixins.scss' as *;
@use '../abstracts/animations.scss' as *;

.signatures-table-parent {
  width: 100%;
  overflow: auto;
}

.signatures-table {
  min-width: 850px;

  // max-width: 1716px;
  @media screen and (max-width: $break-lg-max) {
    min-width: 1000px;
  }

  .signatures-table-row {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 56px;
  }

  .signatures-table-column {
    display: flex;
    align-items: center;
    justify-content: center;
    user-select: none;
    // min-width: 60px;
    padding: 0 8px 0 16px;
    width: 100% !important;

    .refresh-status {
      // position: absolute;
      // top: 50%;
      // right: 0;
      // transform: translateY(-50%);
      // width: 20px;
      // height: 20px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;

      @media (max-width: $break-md-max) {
        display: none;
      }

      svg {
        width: 11px;
      }

      &.refreshing {

        svg {
          animation: spin .7s linear infinite;
        }
      }
    }

    &.flex-column {
      flex-direction: column;
      align-items: center;
      justify-content: center;

      &.actions {
        row-gap: 5px;
        padding-top: 2px;
        padding-bottom: 3px;
      }
    }

    &.align-left {
      justify-content: flex-start;
    }

    &.align-right {
      justify-content: flex-end;
    }

    &:nth-child(1) {
      width: 666px;
      max-width: 38.81%;
      min-width: 100px;
      // flex: 0 0 666px;
    }

    &:nth-child(2) {
      min-width: 108px;
      // flex: 0 0 122px;
      max-width: 7.1%;
    }

    &:nth-child(3) {
      width: 209px;
      // flex: 0 0 209px;
      max-width: 12.17%;

      .number {
        border: 1px solid $border-color-1;
        margin-left: 4px;
        background: $bg-hover;
        width: 34px;
        flex: 0 0 34px;
        height: 24px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50px;
        //styleName: Header/H4;
        font-size: 14px;
        font-weight: 600;
        line-height: 16px;
        color: $color-indigo;
      }
    }

    &:nth-child(4) {
      // flex: 0 0 142px;
      max-width: 8.2%;
    }

    &:nth-child(5) {
      min-width: 120px;
      // flex: 0 0 130px;
      max-width: 7.5%;
    }

    &:nth-child(6) {
      min-width: 120px;
      // flex: 0 0 130px;
      max-width: 7.5%;

    }

    &:nth-child(7) {
      width: 194px;
      min-width: 194px;
      // flex: 0 0 194px;
      max-width: 11.3%;
      padding-right: 14px;
    }

    &__check {
      width: 15px;
      height: 15px;
      border-radius: 4px;
      border: 1px solid $primary-color;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;

      @media (max-width: $break-md-max) {
        width: 19px;
        height: 19px;
      }

      svg {
        width: 9px;

        @media (max-width: $break-md-max) {
          width: 11px;
        }
      }

      &.checked {
        background-color: $primary-color;
      }
    }

    &.zero-width {
      width: 0;
      min-width: 0;
    }
  }

  &__head {
    background: $primary-color;
    border-radius: 8px 8px 0 0;
    height: 56px;

    .signatures-table-column {

      &:nth-child(6) {

        .svg-icon {
          align-self: flex-end;
          padding-bottom: 3px;
        }
      }

      p {
        font-size: 14px;
        font-weight: 600;
        line-height: 16px;
        color: $color-indigo;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;

        &.active {
          // border-bottom-color: $highlighted-color-1;
        }

        &.empty {
          width: 100px;
        }
      }

      .svg-icon {
        margin-left: 8px;

        svg {
          width: 16px;
        }
      }
    }
  }

  &__body {
    padding-bottom: 20px;

    .signatures-table-row {
      height: 96px;
      align-items: center;
      position: relative;

      &:nth-child(even) {
        background-color: #fff;
      }

      &:nth-child(odd) {
        background-color: $bg-purple2;
      }

      &:hover {
        background-color: $bg-hover;
      }

      &.selected {
        background-color: $bg-color-5;
      }
    }

    .signatures-table-column {

      .signature-title {
        cursor: pointer;
      }

      p {
        line-height: 21px;
        overflow: hidden;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        font-size: 14px;
        font-weight: 500;
        color: $text-color-1;
        word-break: break-word;
        width: 100%;
      }

      &.small-text-column {

        p {
          font-size: 12px;
          font-weight: 500;
          line-height: 16px;
        }
      }

      &.recipients-column {

        p {
          display: flex;
          align-items: center;
          position: relative;
          overflow: initial;

          .letter-circle-v2 {
            margin-right: 5px;

            &:last-child {
              margin-right: 0;
            }
          }

          &:hover {
            .recipients-tooltip {
              display: block;
            }
          }

        }

        .name {
          text-overflow: ellipsis;
          width: 100%;
          display: block;
          overflow: hidden;
          max-width: 116px;
          white-space: nowrap;
        }

        .truncate {
          overflow: hidden;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 2;
          white-space: unset;
        }

        .recipients-tooltip {
          display: none;
          position: absolute;
          width: 408px;
          background: #fff;
          top: -100px;
          left: -50%;
          box-shadow: 0px 1px 8px 0px #00000026;
          text-wrap: wrap;
          padding: 16px 16px 24px 16px;
          border-radius: 4px;
          z-index: 1;

          @media (max-width: $break-md-max) {
            width: 208px;
          }

          b {
            margin-bottom: 8px;
            font-size: 14px;
            font-weight: 600;
            line-height: 20px;
            display: flex;
            align-items: center;
            gap: 8px;

            svg {
              width: 18px;
            }
          }

          span {
            font-size: 11px;
            font-weight: 500;
            line-height: 16px;
          }

        }
      }

      &.status-column {

        @media (max-width: $break-md-max) {
          width: auto;
        }

        p {
          display: flex;
          align-items: center;
          cursor: pointer;
          width: unset;

          span.text {

            @media (max-width: $break-md-max) {
              display: none;
            }
          }

          @include signatureStatuses;
        }
      }
    }
  }
}