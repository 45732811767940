.alert {

  &__head {
    text-align: center;
    margin-bottom: 30px;
    padding: 0 10px;
  }

  &__body {
    margin-bottom: 30px;

    p {
      font-size: 0.9375rem;
      line-height: 1.5;
      text-align: center;
    }
  }

  &__foot {
    display: flex;
    align-items: center;
    justify-content: center;

    .button {

      &:first-child {
        margin-right: 15px;
      }
    }
  }
}