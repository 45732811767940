@use '../abstracts/variables_new.scss' as *;

.btn {
  height: 32px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border: 0;
  outline: 0;
  font-size: 14px;
  font-weight: 600;
  line-height: 21px;
  padding: 3.5px 16px;
  border-radius: 20px;
  cursor: pointer;
  background-color: $color-indigo;
  color: #fff;
  transition: background-color .3s ease;
  text-wrap: nowrap;
  @media (max-width: $break-md-max) {
    font-size: 0.9375rem;
    padding: 5px 14px;
    border-radius: 15px;
  }

  &:hover {
    background-color: darken($primary-color, 5);
    color: $color-indigo;
  }

  &:active {
    background-color: darken($primary-color, 10);
  }

  &:disabled {
    background-color: $bg-color-4;
    cursor: not-allowed;
  }

  &--transparent {
    background-color: transparent;
    color: $highlighted-color-3;

    &:hover {
      color: $highlighted-color-3;
      background: #D500001A;
    }

    &:active {
      background-color: $bg-selected;
    }
  }

  &--danger {
    background-color: $highlighted-color-3;

    &:hover {
      background-color: #E74141;
      color: #fff;
    }

    &:active {
      background-color: #E74141;
    }
  }

  &--with-icon {

    .svg-icon {
      margin-right: 6px;

      svg {
        width: 18px;
        fill: #fff;
      }

      @media (max-width: $break-md-max) {
        margin-right: 8px;

        svg {
          width: 16px;
        }
      }
    }
  }

  &--success {
    background-color: $highlighted-color-1;
    color: $primary-color;

    &:hover {
      background-color: $highlighted-color-2;
    }
  }

  &--large {
    height: 40px;
    border-radius: 20px;
    padding-left: 16px;
    padding-right: 16px;
  }

  &--medium {
    height: 40px;
    border-radius: 20px;
    padding-left: 12px;
    padding-right: 12px;
  }

  &--small {
    height: 32px;
    border-radius: 16px;
    padding-left: 8px;
    padding-right: 8px;
    font-size: 13px;
  }

  &--border-primary {
    border: 2px solid $primary-color;
  }

  &--border-white {
    border: 1px solid #fff;
  }

  &.max-content {
    width: max-content;
  }

  &--text-bold {
    font-weight: bold;
  }


  &--light {
    background-color: transparent;
    color: $color-indigo;

    &:hover {
      background-color: darken($primary-color, 5);
    }

    &:active {
      background-color: $bg-selected;
    }
  }

  &--border-radius-sm {
    border-radius: 6px;
  }
}