@use '../abstracts/variables_new.scss' as *;

.modal-v2 {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 100000;
  overflow-y: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: $ff-primary;
  color: $color-indigo;


  &__bg {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba($bg-color-1, 0.4);
    pointer-events: none;
    touch-action: none;
  }

  &__inner {
    max-height: 100%;
    position: relative;
    width: 100%;
  }

  &__content-wrapper {
    padding: 50px 0;
    width: 100%;
  }

  &__content {
    max-width: 1191px;
    width: 90%;
    margin: 0 auto;
    background-color: #fff;
    position: relative;
    border-radius: 8px;
    z-index: 10;
    @media (max-width: $break-md-max) {}

    &_close {
      position: absolute;
      top: 5px;
      right: 5px;
      width: 28px;
      height: 28px;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      transition: background-color .3s ease;
      z-index: 10;

      svg {
        width: 10px;
        transition: fill .3s ease;
      }

      &:hover {
        background-color: $primary-color;

        svg {
          fill: #fff;
        }
      }

      &:active {
        background-color: darken($primary-color, 5);

        svg {
          fill: #fff;
        }
      }
    }
  }

  &--small {

    .modal-v2__content {
      max-width: 458px;
    }
  }

  &--medium {
    .modal-v2__content {
      max-width: 528px;
    }
  }

  &--medium-2 {

    .modal-v2__content {
      max-width: 593px;
    }
  }

  &--medium-3 {
    .modal-v2__content {
      max-width: 641px;
    }
  }

  &--mid {
    .modal-v2__content {
      max-width: 741px;

      .folder-modal-v2__content {
        padding: 24px 30px;
      }
    }
  }

  &--mid-large {
    .modal-v2__content {
      max-width: 797px;
    }
  }

  &--large {
    .modal-v2__content {
      max-width: 1200px;
    }
  }

  &--xx-large {

    .modal-v2__content {
      max-width: 1400px;
    }
  }

  &--bg-2 {

    .modal-v2__content {
      background-color: $bg-default;
    }
  }

  &--no-padding {

    .modal-v2__content {
      padding: 0;
    }
  }

  &--min-content {
    .modal-v2__content {
      width: min-content;
    }
  }

  .sub-modal {

    &__wrapper {
      position: fixed;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    &__background {
      position: fixed;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      background-color: rgba(0, 0, 0, 0.5);
      z-index: 1;
    }

    &__body {
      width: fit-content;
      height: fit-content;
      background-color: #fff;
      border-radius: 3px;
      z-index: 2;

      &.modal--padding-sm {
        padding: 15px;
      }
    }
  }
}