@use '../abstracts/variables_new.scss' as *;

.agency-modal-v2 {

  &__head {
    padding: 20px 40px;

    h2 {
      font-weight: 600;
      font-size: 0.9375rem;
      letter-spacing: 0.29px;
      color: $primary-color;

      @media (max-width: $break-md-max) {
        font-size: 1.125rem;
      }
    }
  }

  &__body {
    padding: 40px;
    background-color: $bg-color-1;

    &_boxes {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;

      .agency-box-v2 {
        width: 48%;

        @media (max-width: $break-md-max) {
          width: 100%;
        }
      }
    }
  }
}