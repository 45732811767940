@use '../abstracts/variables.scss' as *;

.agency-modal {

  &__head {
    padding: 20px 40px;

    h2 {
      font-size: 1.4375rem;
      font-weight: 500;
      letter-spacing: 0.29px;
      color: $secondary-text-color;
    }
  }

  &__body {
    padding: 40px;
    background-color: $primary-bg-color;

    &_boxes {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;

      .agency-box {
        width: 48%;

        @media (max-width: $break-md-max) {
          width: 100%;
        }
      }
    }
  }
}