@use '../abstracts/variables' as *;

.pdf-preview {
  position: relative;

  &-toolbar {
    background-color: #323639;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    // padding-left: 15px;
    // padding-right: 15px;
    padding: 10px 10px 10px 15px;
    color: white;
    
    &-button {
      color: white;
      width: 50px;
      height: 50px;
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 25px;
      transition: all 0.2s;
      &:hover {
        background-color: lighten($color: (#323639), $amount: 10);
      }
    }
  }


  &-content {
    max-height: 83vh;
    overflow: scroll;
  }

  .react-pdf__Page {
    background-color: #323639;
  }
}