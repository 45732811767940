@use '../abstracts/variables_new.scss' as *;

.registered-mail-send-modal {
  display: flex;

  @media (max-width: $break-md-max) {
    flex-direction: column;
  }
}

.registered-mail-send-modal-sidebar-menu {
  width: 278px;
  flex: 0 0 278px;
  padding: 32px 32px 200px;
  background: rgba($bg-hover, 0.33);
  border-top: 3px solid #fff;

  @media (max-width: $break-md-max) {
    width: 100%;
    flex: 0 0 100%;
    padding: 20px 32px;
  }
}

.registered-mail-send-modal-sidebar-content {
  padding: 32px;
  width: 100%;
  overflow: hidden;
}

.sent-successful {
  background: #13AA5B0D;
  padding: 16px;
  display: flex;
  align-items: center;
  gap: 8px;
  color: $highlighted-color-2;
  margin-bottom: 16px;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  border-radius: 8px;

  svg {
    fill: $highlighted-color-2;

  }
}

.sent-error {
  background: #D63F3F0D;
  padding: 16px;
  display: flex;
  align-items: center;
  gap: 8px;
  color: $highlighted-color-3;
  margin-bottom: 16px;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  border-radius: 8px;

  svg {
    fill: $highlighted-color-3;

  }
}

.registered-mail-send-modal-menu {
  li {
    font-size: 14px;
    font-weight: bold;
    color: $bg-default;
    display: flex;
    align-items: center;
    gap: 16px;
    margin-bottom: 32px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  // li:not(:last-child) {
  //   border-right: none;
  // }
  // li:first-child {
  //   border-top-left-radius: 17px;
  //   border-bottom-left-radius: 17px;
  // }
  // li:last-child {
  //   border-top-right-radius: 17px;
  //   border-bottom-right-radius: 17px;
  // }
  span {
    display: flex;
    background: $bg-default;
    width: 32px;
    height: 32px;
    border-radius: 50px;
    align-items: center;
    justify-content: center;
    color: #fff;
  }

  .current {
    // background-color: $bg-color-7;
    color: $color-indigo;

    span {
      background: $color-indigo;
    }
  }
}


.modal-btn-box {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  column-gap: 10px;

}

table {
  th {
    font-size: 14px;
  }

  tr {
    td {
      font-size: 14px;
    }

    input {
      font-size: 12px;
    }
  }
}


// .dropzone {
//   border: 1px solid $primary-color;
//   border-radius: 3px;
//   padding: 10px;
//   display: flex;
//   flex-direction: row;
//   justify-content: space-between;
//   align-items: center;
// }

.rdw-editor-main {
  border: 1px solid $bg-hover;
  padding: 0 10px;
  border-radius: 8px;
}

.btn-delete {
  svg {
    width: 18px;
  }
}

.dropzone {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid $primary-color;
  border-radius: 4px;
  column-gap: 10px;
  padding: 16px;
  background: $primary-color;
  margin-bottom: 8px;

  @media (max-width: $break-md-max) {
    flex-direction: column;
    gap: 16px;

    p {
      margin: auto;
    }
  }

  p {
    color: $text-purple;
    //styleName: Component/Action;
    font-size: 14px;
    font-weight: 600;
    line-height: 21px;

  }

  .dropzone-input {
    flex-grow: 1;
    margin-bottom: 0;
  }
}

.queta {
  font-size: 12px !important;
  font-weight: 500 !important;
  line-height: 16px !important;
  text-align: right;
  color: $text-purple !important;
  margin-bottom: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  b {
    color: $color-indigo !important;
    font-weight: 600 !important;
  }
}

.upload-button {
  position: relative;
  padding: 0 12px;

  &:hover {
    background: $bg-selected;
    border: 2px solid $bg-hover;
    color: $color-indigo;

    svg {
      fill: $color-indigo;
    }
  }

  .upload-icon {
    svg {
      width: 18px;
    }
  }

  .chevron-icon {
    svg {
      width: 18px;
    }
  }

  ul {
    display: none;
    position: absolute;
    top: 102%;
    left: 0;
    background: #fff;
    box-shadow: 0px 1px 8px 0px #00000026;
    width: 267px;
    z-index: 1;

    li {
      padding: 7.5px 16px;
      cursor: pointer;
      text-align: left;
      display: flex;
      gap: 16px;
      align-items: center;
      font-size: 14px;
      font-weight: 500;
      line-height: 20px;

      svg {
        fill: $color-indigo !important;
      }

      &:hover {
        background: $bg-selected;
      }
    }
  }

  &:hover {
    ul {
      display: block;
      color: $color-indigo;
    }
  }
}

.range-container {
  p {
    //styleName: Body/Body M - SemiBold;
    font-size: 12px;
    font-weight: 500;
    line-height: 16px;
    color: $color-indigo;
    margin-top: 4px;

    span {
      font-weight: normal;
      margin-left: 5px;
    }
  }
}

.registered-mail-send-modal-list-table {
  tr {
    td {
      color: $text-color-1;
      //styleName: Body/Body L - Bold;
      font-size: 14px;
      font-weight: 500;
      line-height: 20px;
    }
  }
}

.total-price {
  //styleName: Body/Body L - Bold;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  color: $color-indigo !important;
}

.consent-label {
  text-align: center;

  @media (max-width: $break-md-max) {
    text-align: left;
  }
}