@use '../abstracts/variables' as *;

.status {
  font-size: 12px !important;
  line-height: 16px !important;
  font-weight: 500 !important;
  padding: 4px 8px;
  display: inline-flex;
  background-color: $primary-bg-color;
  border-radius: 50px;
  &--signed {
    background-color: $status-color-2-bg;
    color: $status-color-2;
  }

  &--not-signed {
    background-color: $status-color-3-bg;
    color: $status-color-3;
  }
}