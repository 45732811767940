@use '../abstracts/variables_new.scss' as *;
@use '../abstracts/mixins' as *;

.textarea-v2 {

  textarea:disabled {
    color: rgba($color: $text-purple, $alpha: 0.4);
  }

  textarea::placeholder {
    color: $text-placeholder;
  }

  &__label {
    display: block;
    margin-bottom: 4px;
    color: $color-indigo;
    font-weight: 600;
    font-size: 12px;
    padding-left: 8px;
  }

  &__field {
    border: 2px solid $bg-hover;
    outline: 0;
    border-radius: 4px;
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
    color: $text-color-1;
    padding: 10px 16px;
    max-width: 100%;
    min-width: 100%;
    display: block;
    background: #fff;
    @include placeholder {
      color: $primary-color;
    }

    @media (max-width: $break-md-max) {
      font-size: 0.9375rem;
      border-radius: 15px;
    }
  }

  &__error {
    color: $highlighted-color-3;
    padding: 5px 0;
  }
}