@use '../abstracts/variables' as *;

.dashboard-slider-wrap {
  overflow: hidden;
  max-width: 100%;
}

.dashboard-slider {
  padding: 34px 34px 24px;
  position: relative;
  width: 100%;
  overflow: hidden;
  display: block;

  &__wrapper {
    overflow: hidden;
    padding-bottom: 10px;
  }

  &__inner {
    display: flex;
    transform: translateX(0);
    transition: transform .3s ease;
  }

  &__slide {
    width: 188px;
    margin-right: 15px;
  }

  &__btn {
    width: 33px;
    height: 104px;
    border-radius: 3px;
    background-color: rgba($primary-dark-color, 0.1);
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 50%;
    cursor: pointer;
    color: rgba($primary-text-color, 0.5);
    overflow: hidden;
    
    svg {
      font-size: 2.375rem;
      display: block;
    }
  }

  &__prev {
    left: 34px;
    transform: translate(-50%, -50%);
  }

  &__next {
    right: 34px;
    transform: translate(50%, -50%);
  }

  .dashboard-box {
    
    &__title,
    &__number,
    &__label {
      opacity: 0.3;
    }

    &.active {

      .dashboard-box__title,
      .dashboard-box__number,
      .dashboard-box__label {
        opacity: 1;
      }
    }
  }
}