@use '../abstracts/variables_new.scss' as *;

.actions-dropdown {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  background-color: $primary-light-color;
  padding: 10px;
  border-radius: 8px;
  box-shadow: 0px 0px 24px 0px #00000026;
  white-space: nowrap;
  min-width: 252px !important;

  z-index: 9999;
  transform: translate(30px, -65px);

  &__arrow {
    position: fixed;
    width: 16px;
    height: 16px;
    background: #fff;
    transform: rotate(45deg) translate(18px, -12px);
    z-index: 99999;
  }

  // &::after {
  //   content: "";
  //   position: absolute;
  //   top: 37px;
  //   left: -13px;
  //   width: 0;
  //   height: 0;
  //   border-top: 10px solid transparent;
  //   border-right: 14px solid $primary-light-color;
  //   border-bottom: 10px solid transparent;
  // }

  &--hide {
    display: none;
  }

  &--2 {
    top: calc(100% + 5px);
  }

  &--wider {
    width: calc(100% + 20px);
    left: -10px;
  }

  &--3 {
    min-height: 100%;
  }

  &--no-padding {
    padding: 0;
  }

  &__head {
    position: relative;
    text-align: center;
    padding: 0 18px 10px;
    border-bottom: 1px solid #E3F7E7;
    margin-bottom: 9px;

    p {
      font-size: 0.9375rem;
      font-weight: 500;
      line-height: 1.2;
      color: #5D6C85;
      white-space: normal;
    }

    svg {
      position: absolute;
      top: 0;
      right: 0;
      font-size: 1.125rem;
      color: #767676;
      cursor: pointer;

      &.back {
        right: auto;
        left: 0;
        font-size: 1.25rem;
      }
    }
  }

  &__body {
    max-height: 590px;
    overflow-y: auto;

    ul {

      li {

        &:hover {
          background-color: $bg-hover;
        }

        .link {

          a {
            display: flex;
            align-items: center;
            background-color: #F5F6F8;
            border-radius: 5px;
            padding: 8px 11px;
            min-height: 40px;
            font-size: 0.9375rem;
            font-weight: 400;
            color: #1B1D24;
            transition: background-color .3s ease;
            white-space: normal;



            svg,
            i {
              font-size: 1.5rem;
              display: block;
              margin-right: 8px;
              color: #000;
            }
          }
        }

        .attach-a-link {
          padding-top: 10px;

          p {
            font-size: 0.9375rem;
            font-weight: 500;
            line-height: 1.2;
            color: #45536A;
            margin-bottom: 8px;
          }

          .form-input {
            margin-bottom: 10px;
          }

          .button {
            font-size: 0.9375rem;
            color: #1B1D24;
            background-color: #F5F6F8;

            &:hover {
              background-color: darken(#F5F6F8, 5);
            }
          }
        }
      }
    }

    .document-lists {

      p {
        font-size: 0.875rem;
        cursor: pointer;
        width: calc(100% + 20px);
        margin-left: -10px;
        padding: 8px 10px;
        color: #162A4E;

        &.current {
          color: #5D6C85;
          cursor: default;

          &:hover {
            background-color: transparent;
          }
        }

        &:hover {
          background-color: #F5F6F8;
        }
      }
    }

    .chart-type-list {

      li {
        cursor: pointer;
        color: #53617D;
        font-size: 0.875rem;
        padding: 5px;
        border-radius: 2px;
        margin-bottom: 4px;

        &:hover,
        &.active {
          background-color: #d7dbe3;
        }
      }
    }

  }

  &.delete-list {

    .actions-dropdown__body {

      p {
        font-size: 0.875rem;
        white-space: normal;
        margin-bottom: 10px;
      }
    }

  }

  .dashboard-column__dropdown {
    white-space: normal;
    position: static;

    li>div>div {
      display: flex;
      align-items: center;
    }

    .row {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      width: 100%;
      margin: 0;
      padding: 0;
    }

    &::before,
    &::after {
      display: none;
    }

    &--trash {
      color: $danger-color;

      svg {
        fill: $danger-color;
      }
    }
  }

  &.folders-popup-dropdown {

    .folders-popup {
      position: static;
      transform: translateX(0);
      box-shadow: none;

      li {
        margin-bottom: 0;
      }

      &::after {
        display: none;
      }
    }
  }
}