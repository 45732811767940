@use '../abstracts/variables_new.scss' as *;

.modal-v2-external {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 9999;
  overflow-y: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: $ff-primary;
  color: $primary-color;

  &__bg {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(#000, 0.69);
  }

  &__inner {
    max-height: 100%;
    position: relative;
    width: 100%;
  }

  &__content-wrapper {
    padding: 50px 0;
    width: 100%;
  }

  &__content {
    max-width: 750px;
    width: 90%;
    margin: 0 auto;
    background-color: #fff;
    padding: 30px;
    position: relative;
    border-radius: 3px;
    z-index: 10;

    @media (max-width: $break-md-max) {
      padding: 30px 15px;
    }

    &_close {
      position: absolute;
      top: 5px;
      right: 5px;
      width: 28px;
      height: 28px;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      transition: background-color .3s ease;
      z-index: 10;

      svg {
        width: 10px;
        transition: fill .3s ease;
      }

      &:hover {
        background-color: $primary-color;

        svg {
          fill: #fff;
        }
      }

      &:active {
        background-color: darken($primary-color, 5);

        svg {
          fill: #fff;
        }
      }
    }
  }

  &--small {

    .modal-v2-external__content {
      max-width: 400px;
    }
  }

  &--medium {

    .modal-v2-external__content {
      max-width: 481px;
    }
  }

  &--medium-2 {

    .modal-v2-external__content {
      max-width: 593px;
    }
  }

  &--mid-large {
    .modal-v2-external__content {
      max-width: 797px;
    }
  }

  &--large {

    .modal-v2-external__content {
      max-width: 1200px;
    }
  }

  &--xx-large {

    .modal-v2-external__content {
      max-width: 1400px;
    }
  }

  &--bg-2 {

    .modal-v2-external__content {
      background-color: $bg-color-1;
    }
  }

  &--no-padding {

    .modal-v2-external__content {
      padding: 0;
    }
  }
}