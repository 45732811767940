@use '../abstracts/variables' as *;

.notification {
  padding: 20px 26px 20px 52px;
  position: fixed;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  min-width: 246px;
  z-index: 999999;
  border-width: 1px;
  border-style: solid;
  border-radius: 5px;

  p {
    color: $primary-light-color;
    font-size: 1rem;
    line-height: 1.125;
  }

  &__close {
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translateY(-50%);
    background-color: $primary-light-color;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: $quaternary-text-color;

    svg {
      display: block;
    }
  }

  .icon {
    position: absolute;
    top: 50%;
    left: 19px;
    transform: translateY(-50%);
  }

  &--success {
    background-color: $notification-success-bg;
    border-color: $notification-success-border;

    p {
      color: $notification-success-text;
    }

    .icon {
      color: $notification-success-icon;
    }
  }
  
  &--danger {
    background-color: $notification-danger-bg;
    border-color: $notification-danger-border;

    p {
      color: $notification-danger-text;
    }

    .icon {
      color: $notification-danger-icon;
    }
  }

  &--warning {
    background-color: $notification-warning-bg;
    border-color: $notification-warning-border;

    p {
      color: $notification-warning-text;
    }

    .icon {
      color: $notification-warning-icon;
    }
  }

  &--info {
    background-color: $notification-info-bg;
    border-color: $notification-info-border;

    p {
      color: $notification-info-text;
    }

    .icon {
      color: $notification-info-icon;
    }
  }
}