@use "../abstracts/variables_new.scss" as *;
@use "../abstracts/mixins.scss" as *;

.documents-table-parent {
  // height: calc(100% - 100px);
  width: 100%;
  // overflow: auto;

  &.active {
    height: calc(100% - 140px);
  }

  @include hideScrollbar();

  @media (max-width: $break-xl-max) {
    height: calc(100% - 200px);
  }
}

.documents-table {
  max-width: 1507px;
  width: 100%;
  height: 100%;

  @media (max-width: 1000px) {
    min-width: 1000px;
  }

  @media (min-width: 1920px) {
    max-width: initial;

    .documents-table-column {
      width: 100% !important;
    }
  }

  .documents-table-row {
    padding: 0 12px;
    gap: 16px;
    display: flex;
    justify-content: space-between;
    width: 100%;
    border-bottom: 2px solid #fff;

    @media (min-width: 1000px) and (max-width: 1366px) {
      gap: 2px;
      // padding: 0 2px;
      // p {
      //   font-size: 11px !important;
      // }

      .documents-table-column__name {
        max-width: 25%;
      }
    }
  }

  .documents-table-column {
    display: inline-flex;
    align-items: center;
    // justify-content: flex-start;
    user-select: none;


    .doc_name {
      button {
        margin-right: 8px;
        margin-left: 8px;
      }
    }


    &__checkbox {
      justify-content: flex-start;
      gap: 8px;
    }

    &__name {
      width: 594px;
      max-width: 39.41%;

      @media (max-width: $break-lg-max) {
        flex: initial;
        width: 330px;
      }
    }

    &__status {
      width: 100%;
      min-width: 100px;
      // flex: 0 0 130px;
      max-width: 8.6%;

      .active-text {
        padding: 4px 8px;
        border-radius: 50px;
        background: $highlighted-color-2;
        color: #fff;

        svg {
          width: 18px;
          margin-right: 6px;
        }
      }

      .hidden-text {
        text-wrap: nowrap;

        svg {
          width: 18px;
          margin-right: 6px;
          fill: #fff;
        }

        padding: 4px 8px;
        border-radius: 50px;
        background: $error-color;
        color: #fff;
      }
    }

    &__progress {
      // flex: 0 0 84px;
      width: 84px;
      max-width: 5.57%;

    }

    &__author {
      // flex: 0 0 200px;
      width: 100%;
      max-width: 13.27%;

    }

    &__owner {
      // flex: 0 0 214px;
      width: 100%;
      max-width: 14.2%;

    }

    &__date {
      // flex: 0 0 130px;
      // width: 130px;
      max-width: 8.6%;
      min-width: 75px;
      width: 100%
    }

    &__history {
      min-width: 50px;
      max-width: 3.3%;
    }

    .link-icon {
      width: 24px;
      padding: 0px 3px;
      fill: #9f88bc;
      cursor: pointer;

      &:hover {
        fill: $color-indigo !important;
      }
    }

    &__check {
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
    }

    .more-vert-icon {
      svg {
        fill: #9f88bc;
      }

      &:hover {
        background: none;

        svg {
          fill: $color-indigo !important;
        }
      }
    }

    &__fileName {
      max-width: 70%;
      width: 100%;
    }

    &__size {
      max-width: 10%;
      width: 100%;
    }

    &__actions {
      max-width: 20%;
      width: 100%;
    }
  }

  &__head {
    height: 56px;
    background: $primary-color;
    display: flex;
    border-radius: 8px 8px 0 0;

    &.no-border-radius {
      border-radius: 0;
    }

    @media (max-width: $break-md-max) {
      // display: none;
    }

    .documents-table-column {
      svg {
        fill: $color-indigo;
      }

      &___checkbox {
        padding-bottom: 3px;

        .svg-icon {
          margin-left: 0px;
        }
      }

      &__status {

        // &.template-header {
        //   justify-content: flex-end;
        //   padding-right: 12px;
        // }

        .svg-icon {
          padding-left: 3px;
        }
      }

      p {
        font-size: 14px;
        font-weight: 600;
        line-height: 1.36;
        color: $color-indigo;
        text-overflow: ellipsis;
        overflow: hidden;

        &.active {}
      }
    }
  }

  &__body {
    padding-bottom: 20px;

    .documents-table-row {
      height: 56px;
      align-items: center;
      position: relative;
      // padding: 0 12px;

      &:nth-child(even) {
        background-color: $bg-purple2;
      }

      &:nth-child(odd) {
        background-color: #fff;
      }

      &.no-cursor {
        cursor: default;
      }

      @media (max-width: $break-md-max) {
        height: auto;
        padding: 9px 24px 11px;
        position: relative;
      }

      &.selected {
        background-color: $bg-selected;
      }

      &:hover {
        background-color: $bg-hover;
      }
    }

    .documents-table-column {
      // padding-left: 16px;
      // padding-right: 8px;
      width: 100%;

      p {
        line-height: 20px;
        font-size: 14px;
        font-weight: 500;
        color: $text-color-1;
        align-items: center;
      }

      .status {
        padding: 4px 8px;

        @media (max-width: $break-lg-max) {
          padding: 4px 4px;
        }
      }

      .status--ready {
        background-color: $highlighted-color-2;
        color: #fff;
      }

      .status--draft {
        background-color: $bg-color-2;
      }

      .status--in-progress {
        color: $color-indigo;
        background-color: $highlighted-color-4;
      }

      &__checkbox {
        z-index: 2;
        padding-left: 0px;
        padding-right: 12px;

        .svg-icon {
          margin-left: 0px;
        }

      }

      svg {
        fill: $color-indigo;
      }
    }

    .go-to-document {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 1;
    }
  }

  .no-items {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 278px 0px 100px;
    font-size: 24px;
    font-weight: 600;
    line-height: 32px;
    text-align: center;
    color: $text-purple;
  }


}

.time {
  font-size: 12px !important;
  font-weight: 500 !important;
  color: $text-purple !important;
}

.date {
  text-overflow: ellipsis;
  overflow: hidden;
}