@use '../abstracts/variables' as *;
@use '../abstracts/mixins' as *;

.dashboard-view {
  background-color: $primary-light-color;

  &__head {
    box-shadow: 0 2px 4px 0 rgba($shadow-color, 0.5);
    padding-bottom: 10px;
    position: fixed;
    width: 100%;
    top: 0;
    left: 281px;
    width: calc(100% - 281px);
    z-index: 6;
    background-color: $primary-light-color;
    
    &.not-fixed {
      position: sticky;
      top: 0;
      width: 100%;
    }

    &.static {
      position: static;
      width: 100%;
    }

    @media (max-width: $break-lg-max) {
      left: 52px;
      width: calc(100% - 52px);
    }

    @media (max-width: $break-md-max) {
      padding-bottom: 0;
    }

    &_top-wrap {
      height: 59px;
      opacity: 1;
      transition: height .3s ease, opacity .3s ease;

      &.shrink {
        height: 0;
        opacity: 0;
        z-index: -1;
      }
    }
    
    &_top {
      display: flex;
      align-items: center;
      padding: 13px 15px 13px 20px;
      position: relative;

      h4 {
        display: flex;
        align-items: center;

        svg.info {
          margin-left: 6px;
          font-size: 1.25rem;
          color: $senary-text-color;
        }

        span {
          display: none;
          margin-right: 10px;
          cursor: pointer;

          svg {
            display: block;
          }

          @media (max-width: $break-lg-max) {
            display: block;
          }
        }
      }

      @media (max-width: $break-md-max) {
        margin-bottom: 0;
      }

      @media (max-width: $break-sm-max) {
        padding-left: 12px;
        padding-right: 12px;
      }
    }

    &_bottom {
      display: flex;
      padding: 12px 15px 0 20px;
      min-height: 32px;

      &.fix-1 {
        padding-right: 39px;

        &.fix-2 {
          padding-right: 58px !important;
        }
      }

      @media (max-width: $break-md-max) {
        display: none;
      }

      .dashboard-column {
        justify-content: center;
        height: 34px;

        &:first-child {
          justify-content: flex-start;
        }

        &:last-child {
          text-align: right;
          margin-right: 0;
          justify-content: flex-end;
        }

        &.col-1 {
          justify-content: flex-start;
          text-align: left;
          margin-right: 15px;
        }
      }
    }
  }

  &__body {
    padding-top: 115px;

    &.no-padding-top {
      padding-top: 0;
    }

    @media (max-width: $break-md-max) {
      padding-top: 59px;
    }

    .dashboard-row {

      .dashboard-column {

        &.col-1 {

          .checkbox {
            display: none;
          }
          .icon.checkbox-placeholder {
            display: none;
          }
        }

        &.col-font-small {
          font-size: 0.8125rem;

          span {
            font-weight: 300;
          }
        }
      }

      &:hover,
      &.active {

        .dashboard-column {

          &.col-1 {

            .checkbox {
              display: block;
            }
            .icon {
              display: none;
            }
            .icon.checkbox-placeholder {
              display: block;
            }
          }

          &__main {

            p.title {
              color: $primary-color;
            }
          }
        }
      }
    }
  }
}