@use '../abstracts/variables_new.scss' as *;

.checkbox-v2 {

  input {
    display: none;
  }

  label {
    display: flex;
    align-items: center;
  }

  &__btn {
    cursor: pointer;

    &.checked {
      svg {
        fill: #E8025F;
      }
    }

    svg {
      width: 24px;
      height: auto;
      fill: $color-indigo;
    }
    &:hover{
      box-shadow: 0 0 0 3px $bg-hover inset;
      border-radius: 3px;
    }
  }

  &__label {
    //styleName: Body/Body L - Bold;
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
    margin-left: 8px;
    word-break: break-word;
  }

  &.disabled {
    .checkbox-v2__btn {
      cursor: not-allowed;

      // &.checked {
      //   svg {
      //     fill: $color-indigo;
      //   }
      // }
    }

  }
}