@use '../abstracts/variables' as *;

.dashboard-box {
  width: 188px;
  height: 180px;
  background-color: $primary-light-color;
  box-shadow: 0 4px 8px 0 rgba($primary-dark-color, 0.1);
  border-radius: 3px;
  padding: 15px 20px;
  cursor: pointer;
  transition: box-shadow .3s ease;

  &:hover {
    box-shadow: 0 0 0 rgba($primary-dark-color, 0);
  }

  &__title {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: 8px 12px;
    border-radius: 14px;
    font-size: 0.625rem;
    line-height: 1.1;
    text-transform: uppercase;

    p {
      font-weight: 700;
    }
  }

  .loader {
    display: block;
    margin-top: 20px;
  }

  &__number {
    font-size: 3.375rem;
    font-weight: 900;
    line-height: 1.2;
  }

  &__label {
    font-size: 0.75rem;
    font-weight: 700;
    line-height: 1.167;
  }

  &--ready {
    color: $primary-color;

    .dashboard-box__title {
      background-color: rgba($primary-color, 0.08);
    }

    &.active {
      background-color: $primary-color;
      color: $primary-light-color;

      .dashboard-box__title {
        background-color: $primary-light-color;
        color: $primary-color;
      }
    }
  }

  &--draft {
    color: $tertiary-color;

    .dashboard-box__title {
      background-color: rgba($tertiary-color-light, 0.54);
    }

    &.active {
      background-color: $tertiary-color;
      color: $primary-light-color;

      .dashboard-box__title {
        background-color: $primary-light-color;
        color: $tertiary-color;
      }
    }
  }

  &--in-progress {
    color: $quaternary-color;

    .dashboard-box__title {
      background-color: rgba($quaternary-color-light, 0.38);
    }

    &.active {
      background-color: $quaternary-color;
      color: $primary-light-color;

      .dashboard-box__title {
        background-color: $primary-light-color;
        color: $quaternary-color;
      }
    }
  }

  &--closed {
    color: $quinary-color;

    .dashboard-box__title {
      background-color: $quinary-color-light;
    }

    &.active {
      background-color: $quinary-color;
      color: $primary-light-color;

      .dashboard-box__title {
        background-color: $primary-light-color;
        color: $quinary-color;
      }
    }
  }

  &--expired {
    color: $senary-color;

    .dashboard-box__title {
      background-color: $tertiary-bg-color;
    }

    &.active {
      background-color: $senary-color;
      color: $primary-light-color;

      .dashboard-box__title {
        background-color: $primary-light-color;
        color: $senary-color;
      }
    }
  }

  &--canceled {
    color: $secondary-color;

    .dashboard-box__title {
      background-color: rgba($secondary-color-light, 0.12);
    }

    &.active {
      background-color: $secondary-color;
      color: $primary-light-color;

      .dashboard-box__title {
        background-color: $primary-light-color;
        color: $secondary-color;
      }
    }
  }
}