@use '../abstracts/variables_new.scss' as *;

.header {
  display: flex;
  align-items: center;
  height: 84px;
  padding: 0 24px;
  // position: fixed;
  width: calc(100% - 290px);
  // min-width: 320px;
  top: 0;
  right: 0;
  z-index: 100;
  background: #fff;
  margin-left: auto;
  border-bottom: 1px solid $border-color-1;

  &--full {
    width: calc(100%) !important;
  }

  &--full-width {
    width: calc(100%) !important;
  }

  @media (max-width: $break-lg-max) {
    width: calc(100% - 266px);
    padding: 0 16px;
  }

  @media (max-width: $break-md-max) {
    width: 100%;
    height: 74px;
    background: $primary-color;
    border-bottom: 0px;
  }


  &__left {
    display: flex;
    //styleName: Header/H2;
    font-size: 24px;
    font-weight: 600;
    line-height: 32px;
    color: $color-indigo;
    gap: 16px;
    align-items: center;

    @media (max-width: $break-md-max) {
      font-size: 18px;
    }

    &__icon {
      width: 40px;
      height: 40px;
      border-radius: 6px;
      background: $bg-default;
      display: flex;
      align-items: center;
      justify-content: center;

      @media (max-width: $break-lg-max) {

        margin-right: 5px !important;
      }
    }

  }

  &__right {
    margin-left: auto;
    display: flex;
    align-items: center;
    gap: 8px;
    order: 2;

    @media (max-width: $break-md-max) {
      display: none;
    }

    .support-menu {
      @media (max-width: $break-md-max) {
        display: none;
      }
    }

    .link {
      width: 40px;
      height: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: $color-indigo;
      border-radius: 50%;
      border: 0;
      outline: 0;
      cursor: pointer;
      margin-left: 10px;
      transition: background-color 0.3s ease;

      img,
      svg {
        display: block;
      }

      svg {
        font-size: 1.625rem;
        color: #fff;
        width: 11px;
      }

      &:hover {
        background-color: $bg-color-1;
      }

      @media (max-width: $break-md-max) {
        width: 42px;
        height: 42px;

        svg {
          font-size: 2.625rem;
          width: 21px;
        }

        img {
          width: 20px;
        }
      }
    }

    .update {
      @media (max-width: $break-md-max) {
        display: none;
      }

      @media (max-width: $break-xl-max) {
        .text {
          display: none;
        }
      }
    }
  }
}

.logo {
  margin: 16px 0 24px;
  padding-left: 24px;

  svg {
    display: block;
    width: 140px;
    height: 50px;

    @media (max-width: $break-md-max) {
      width: 133px;
      height: 33px;
    }
  }
}

.support-menu-btn {
  width: 26px;
  height: 26px;
  padding: 4px;
  border-radius: 13px;
  margin-left: 10px;
  color: $color-indigo;
  background-color: #fff; // rgba(#EBECF0, 0.35);

  &:hover {
    background-color: darken(rgb(255, 255, 255), 5);
  }

  svg {
    width: 20px;
    height: 20px;
  }
}

@media (max-width: $break-md-max) {
  .support-menu-btn {
    width: 42px;
    height: 42px;
    border-radius: 50%;
  }
}