@use '../abstracts/variables_new.scss' as *;

.synthesis-v2 {
  &__filters {
    display: flex;
    align-items: center;
    color: #E8025F;
    font-size: 14px;
    font-weight: 600;
    line-height: 21px;
    text-align: center;
    border: 2px solid $bg-selected;
    height: 40px;
    border-radius: 4px;
    margin-bottom: 34px;

    div {
      border: 1px solid $bg-selected;
      border-width: 0 1px;
      cursor: pointer;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .active {
      font-weight: 500;
      line-height: 20px;
      background: $bg-selected;
    }

  }

  &__section {
    margin-bottom: 20px;

    &:last-child {
      margin-bottom: 0;
    }

    &_fields {

      .inputs-wrapper {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        width: calc(100% + 15px);
      }

      .input-wrapper {
        flex: 1;
        margin-right: 15px;
        min-width: calc(100% / 3 - 15px);
        margin-bottom: 24px;

        @media (max-width: $break-sm-max) {
          min-width: calc(100% / 2 - 15px);
        }

        @media (max-width: $break-xs-max) {
          min-width: calc(100% - 15px);
        }

        .input-v2 {
          width: 100%;

          &__label {
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
          }

          &.not-empty {

            .input-v2__field {
              background-color: rgba($primary-color, 0.1);
            }
          }
        }

        .date-input {

          &__label {
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
          }

          .react-datepicker-wrapper input {
            border: 2px solid $bg-hover;
            border-radius: 4px;
            background-color: transparent;
            height: 40px;
            color: $text-color-1;
            width: 100%;
            //styleName: Body/Body L - SemiBold;
            font-size: 14px;
            font-weight: 600;
            line-height: 20px;
          }

          &.not-empty {

            .date-input__body {

              input {
                background-color: rgba($primary-color, 0.1);
              }
            }
          }
        }

        .select_input_wrapper {
          margin-bottom: 15px;

          .label {
            margin-bottom: 5px;
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
            display: block;
          }

          select {
            width: 100%;
            padding: 3px 12px 2px;
            border: 1px solid $primary-color;
            border-radius: 3px;
            outline: 0;
            font-family: $ff-primary;
            font-weight: 400;
            line-height: 1.33;
          }

          &.not-empty {

            select {
              background-color: rgba($primary-color, 0.1);
            }
          }
        }
      }

      .textarea-wrapper {
        width: calc(100% - 15px);
        margin-bottom: 15px;

        .textarea-v2 {

          &__label {
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
          }

          &.not-empty {

            .textarea-v2__field {
              background-color: rgba($primary-color, 0.1);
            }
          }
        }
      }

      .question-container {
        width: calc(100% - 15px);
      }

      .question-wrapper {
        padding-bottom: 5px;
        margin-bottom: 24px;
        width: 100%;

        &.hide {
          display: none;
        }

        p {
          font-size: 14px;
          font-weight: 600;
          padding: 8px 0;
          line-height: 20px;
          color: $text-color-1;
          display: inline-flex;
          align-items: center;
          flex-wrap: wrap;
          gap: 8px;

          span {
            padding: 6px 12px;
            background: #13AA5B0D;
            color: $highlighted-color-2;
            border-radius: 4px;
            display: inline-block;

          }
        }
      }
    }
  }

  h2 {
    margin-bottom: 10px;
  }

  .synthesis-form {
    &__actions {
      display: flex;
      align-items: center;
      justify-content: center;

      .btn {

        &:first-child {
          margin-right: 8px;
        }
      }
    }
  }
}

.react-datepicker-popper {
  z-index: 99999;
}