@use "../abstracts/variables_new.scss" as *;

.filters {
  margin-bottom: 47px;

  h6 {
    margin-bottom: 17px;
  }

  &__list {
    position: relative;

    &_item {
      font-size: 0.8125rem;
      margin-bottom: 12px;
      color: $secondary-text-color;
      padding: 5px 9px;
      width: calc(100% + 18px);
      margin-left: -9px;
      border-radius: 3px;
      transition: background-color .3s ease;

      &:hover {
        background-color: $primary-light-color;

        > span {
          color: $secondary-text-color;
        }
      }
      
      > div:not(.dropdown):not(.folders-popup):not(.dropdown-el):not(.item-inner):not(.dropdown-wrapper) {
        display: flex;
        align-items: center;
        cursor: pointer;
        user-select: none;
      }
      
      &:last-child {
        margin-bottom: 0;
      }
      
      span.icon {
        font-size: 1.5rem;
        color: $quinary-text-color;
        margin-right: 12px;
        transition: color .3s ease;

        svg {
          display: block;
        }
      }

      &-menu {
        position: absolute;
        top: 100%;
        left: 36px;
        position: absolute;
        white-space: nowrap;
        background-color: $primary-light-color;
        box-shadow: 0 0 8px rgba($shadow-color, 0.5);
        border-radius: 3px;

        li {
          display: flex;
          align-items: center;
          padding: 8px;
          color: $nonary-text-color;
          font-size: 0.875rem;
          transition: color;
          cursor: pointer;
          margin-bottom: 0;
    
          &:hover {
            color: $primary-color;
          }
        }
      }
    }

    &.folders {

      > li > .dropdown-wrapper {

        .folders-popup {
          left: calc(100% + 18px) !important;
        }
      }

      li.filters__list_item {
        // display: flex;
        // align-items: center;
        // position: relative;
        // width: calc(100% + 36px);
        // margin-left: -18px;
        // padding: 4px 18px;
        margin-bottom: 4px;
        padding-top: 0;
        padding-bottom: 0;
        // transition: background-color .3s ease;

        &:hover {
          background-color: transparent !important;
  
          > span {
            color: inherit;
          }
        }

        &.active {

          > .item-inner {
            background-color: $primary-light-color;
            min-height: 35px;

            p {
              font-weight: 700;
            }
          }
        }

        .dropdown-wrapper {
          position: relative;
          width: 100%;
          height: 1px;
        }

        .filters__list_item {
          margin-bottom: 0;
          padding: 0;
          margin-left: 0;
          width: 100%;

          .item-inner {
            width: calc(100% + 10px);
            margin-left: -5px;
            padding-left: 5px;
            padding-right: 5px;
            
            &--no-subfolders {
              padding-left: 29px;
            }
          }

          .dropdown {
            right: -10px;
          }

          .folders-dropdown {
            left: calc(100% - 42px);
          }
        }

        .item-inner {
          display: flex;
          align-items: center;
          position: relative;
          padding: 4px 18px;
          width: calc(100% + 36px);
          margin-left: -18px;
          transition: background-color .3s ease;

          &--no-subfolders {
            padding-left: 42px;
          }

          &:hover {
            background-color: $primary-light-color;
          }
          
          &.hover {
            background-color: darken($tertiary-bg-color, 10);
          }
        }

        span.icon-arrow {
          color: $quinary-text-color;
          border-radius: 3px;
          cursor: pointer;
          position: relative;
          top: -2px;

          .right-icon {
            display: block;
          }
          
          .drop-down-icon {
            display: none;
          }

          &:hover {
            background-color: $tertiary-bg-color;
          }

          &.active {

            .drop-down-icon {
              display: block;
            }

            .right-icon {
              display: none;
            }
          }
        }

        .folders-dropdown {
          position: absolute;
          top: calc(100% + 10px);
          left: calc(100% - 54px);
        }

        .folders-popup {
          top: 0;
          left: 5px;
          bottom: auto;
          transform: translateX(0);
  
          &::after {
            top: auto;
            bottom: 100%;
            left: 24px;
            border-color: rgba($primary-light-color, 0);
            border-bottom-color: $primary-light-color;
            border-width: 6px;
        
            @media (max-width: $break-md-max) {
              left: 24px;
              right: auto;
            }
          }
        }
        
        .icon-left {
          color: $quinary-text-color;
          margin-right: 11px;
        }

        p {
          cursor: pointer;
          display: flex;
          align-items: center;
        }
        
        .icon-right {
          margin-left: auto;
          color: $color-indigo;
          cursor: pointer;
        }

        .dropdown {
          background-color: $primary-light-color;
          min-width: 122px;
          border-radius: 3px;
          padding: 20px 11px;
          position: absolute;
          top: calc(100% + 10px);
          right: 4px;
          z-index: 10;
          box-shadow: 0 2px 4px rgba($shadow-color-6, 0.5);

          &::after {
            bottom: 100%;
            left: 80%;
            border: solid transparent;
            content: " ";
            height: 0;
            width: 0;
            position: absolute;
            pointer-events: none;
            border-color: rgba($primary-light-color, 0);
            border-bottom-color: $primary-light-color;
            border-width: 6px;
            margin-left: -6px;
          }

          p {
            display: flex;
            align-items: center;
            margin-bottom: 8px;
            color: $nonary-text-color;
            cursor: pointer;
            font-weight: 700;

            &:last-child {
              margin-bottom: 0;
            }

            &:nth-child(2) {

              svg {
                font-size: 1.5rem;
              }
            }

            span {
              width: 24px;
              margin-right: 4px;
              display: flex;
              align-items: center;
              justify-content: center;
            }

            svg {
              font-size: 1.125rem;
              color: $nonary-text-color;
            }

            &.delete {

              svg {
                font-size: 1.5rem;
              }
            }

            &.create,
            &.move {

              svg {
                font-size: 1.25rem;
              }
            }
          }
        }

        .subfolder {
          width: 100%;
          display: block;
          padding: 2px 0 2px 10px;
        }

        p.no-folders {
          padding: 5px 0;
          text-align: center;
          display: block;
        }
      }
    }
  }
}