@use '../abstracts/variables_new.scss' as *;

.select-v2 {
  display: flex;
  align-items: center;
  gap: 8px;
  height: 100%;
  max-height: 40px;
  max-width: 220px;

  &.full-width {
    width: 100%;
    max-width: unset;
  }

  &.height-40 {
    height: 40px;
  }

  &.options-full-width {
    .dropdown-item {
      width: 100%;
    }

    .select-v2__body_dropdown {
      left: 0;
      min-width: 100%;
      z-index: 5;
      background-color: #fff;
      box-shadow: 0px 1px 8px 0px #00000026;
      border-radius: 4px;
      min-width: unset;
      width: 100%;
    }
  }

  &__label {
    line-height: 20px;
    font-weight: 600;
    font-size: 14px;
    white-space: nowrap;

    @media (max-width: $break-md-max) {
      font-size: 0.9375rem;
    }
  }

  &.invalid {
    .select-v2__body_selected {
      border: 2px solid #D5000033;
    }
  }

  &__body {
    position: relative;
    width: 100%;
    height: 100%;
    max-height: 40px;

    &.opened {
      .select-v2__body_selected {
        background-color: $bg-selected;
        border: solid 2px $bg-selected;

        .svg-icon {
          transform: translateY(-50%) rotate(180deg);
        }
      }
    }

    &_selected {
      border: solid 2px $bg-hover;
      background: #fff;
      border-radius: 5px;
      display: flex;
      align-items: center;
      height: 40px;
      padding: 0 37px 0 16px;
      line-height: 21px;
      font-weight: bold;
      font-size: 14px;
      position: relative;
      cursor: pointer;
      user-select: none;

      @media (max-width: $break-md-max) {
        padding: 0 32px 0 12px;
      }

      p {
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        line-height: 21px;
        font-weight: 600;
        font-size: 14px;
        color: $color-indigo;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
      }

      .svg-icon {
        position: absolute;
        top: 50%;
        right: 12px;
        transform: translateY(-50%);
        fill: $color-indigo;
        stroke: $color-indigo;

        svg {
          width: 20.5px;
        }
      }

      &--colorpicker {
        border: 0;
        padding: 0;

        .color-box {
          width: 23px;
          height: 23px;
          border-radius: 12px;
          border: 1px solid $primary-color;
          margin-right: 7px;

          @media (max-width: $break-md-max) {
            width: 31px;
            height: 31px;
            margin-right: 9px;
            border-radius: 16px;
          }
        }

        .color-text {
          border: 1px solid $primary-color;
          border-radius: 12px;
          padding: 0 14px;
          display: flex;
          align-items: center;
          height: 23px;

          @media (max-width: $break-md-max) {
            height: 31px;
            padding: 0 18px;
            border-radius: 16px;
          }
        }
      }

      &--status {

        p.selected-status {
          display: flex;
          align-items: center;
          font-family: $ff-primary;

          span {
            display: block;
            width: 7px;
            height: 7px;
            border-radius: 50%;
            margin-right: 6px;

            &.draft {
              background-color: $bg-color-4;
            }

            &.ready {
              background-color: $highlighted-color-2;
            }
          }
        }
      }

      &--filled {
        background-color: $bg-hover;
        border-radius: 4px;
        border: none;

        p {
          color: $color-indigo;
        }

        .svg-icon {
          svg {
            // width: 10px;
          }
        }
      }
    }

    &_dropdown {
      position: absolute;
      top: calc(100% + 2px);
      left: 0;
      min-width: 100%;
      z-index: 5;
      background-color: #fff;
      box-shadow: 0px 1px 8px 0px #00000026;
      border-radius: 4px;
      // overflow-y: auto;
      // max-height: 230px;
      min-width: 220px;

      &.align-right {
        left: auto;
        right: 0;
      }

      .dropdown-item {
        padding: 0 16px;
        height: 40px;
        display: flex;
        align-items: center;
        // width: 100%;
        // max-width: max-content;
        cursor: pointer;
        transition: background-color .3s ease;
        min-width: 220px;

        &--empty {
          display: none;
        }

        p {
          padding: 3px 0 4px;
          //styleName: Body/Body L - Bold;
          font-size: 14px;
          font-weight: 500;
          line-height: 20px;
          color: $color-indigo;
          // display: flex;
          align-items: center;
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
          width: 100%;
        }

        &:hover {
          background-color: $bg-hover;
          color: #fff;
        }

        &.selected {
          background-color: $bg-selected;
        }
      }
    }
  }

  &--colorpicker {

    .select-v2__body {

      &.opened {
        background-color: #fff;
        box-shadow: none;
      }

      &_dropdown {
        background-color: #fff;
        border: 0;
        border-radius: 12px;

        .dropdown-item {

          &:first-child {
            border-radius: 12px 12px 0 0;
          }

          p {
            display: flex;
            align-items: center;
          }

          span {
            width: 23px;
            height: 23px;
            border-radius: 12px;
            border: 1px solid $primary-color;
            margin-right: 7px;
            display: block;
          }
        }
      }
    }
  }

  &--status {

    .select-v2__body {

      &.opened {
        background-color: transparent;
        box-shadow: none;
      }

      &_dropdown {
        background-color: #fff;
        border: 0;
        border-radius: 12px;
        padding-top: 0;

        .dropdown-item {

          &:first-child {
            border-radius: 12px 12px 0 0;
          }

          p {
            display: flex;
            align-items: center;
          }

          span.status-box {
            width: 7px;
            height: 7px;
            border-radius: 50%;
            margin-right: 6px;

            &.draft {
              background-color: $bg-color-4;
            }

            &.ready {
              background-color: $highlighted-color-2;
            }
          }
        }
      }
    }
  }

  &--filled {

    .select-v2__body {

      &.opened {
        background-color: transparent;
        box-shadow: none;
      }

      &_dropdown {
        background-color: #fff;
        border: 0;
        margin-top: 5px;
        border-radius: 4px;
        padding-top: 0;

        .dropdown-item {

          border-radius: 0;
          border: none;
          padding-top: 2px;
          padding-bottom: 2px;
          position: relative;

          p {
            color: $color-indigo;
            font-size: small;
            width: 100%;
            justify-content: space-between;

            svg {
              fill: $color-indigo !important;
              float: right;
            }

            &:hover {
              svg {
                fill: $color-indigo;
              }
            }
          }
         
        }
      }
    }
  }

  &__group-header {
    padding: 0 10px;
    height: 40px;
    display: flex;
    align-items: center;
    background-color: $bg-purple2;
    font-size: 14px;
    font-weight: 600;
    color: $color-indigo;
    cursor: default;
    user-select: none;

    @media (max-width: $break-md-max) {
      padding: 0 8px;
    }

    .svg-icon {
      margin-left: auto;
      fill: $color-indigo;
      stroke: $color-indigo;
    }
  }

  &--caret-start {
    .select-v2__body_selected {
      padding: 0 16px 0 37px;

      @media (max-width: $break-md-max) {
        padding: 0 12px 0 32px;
      }
    }

    .svg-icon {
      left: 12px;
      right: auto;
    }
  }
}