@use '../abstracts/variables' as *;

.dashboard-head-meta {
  display: flex;
  align-items: center;
  margin-left: auto;

  &__overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba($primary-dark-color, 0.2);
    z-index: 1;
  }

  .search-small {
    display: none;
    align-items: center;
    padding: 0 5px;
    color: $nonary-text-color;
    margin-right: 5px;
    cursor: pointer;

    @media (max-width: $break-sm-max) {
      display: flex;
    }
  }

  .search-wrap {
    display: none;
    background-color: $primary-bg-color;
    align-items: center;
    margin-right: 10px;
    position: absolute;
    top: 50%;
    left: 46px;
    transform: translateY(-50%);
    width: calc(100% - 92px);
    z-index: 1;
    min-height: 34px;

    &__close {
      color: $nonary-text-color;
      margin-right: 5px;

      svg {
        display: block;
      }
    }

    @media (max-width: $break-sm-max) {
      display: flex;

      form {
        display: block;
        flex: 1;
        margin-right: 0;
      }
    }
  }

  form {
    width: 143px;
    margin-right: 15px;
    position: relative;
    transition: width .3s ease;

    &.search-form-el {
      z-index: 100;
      position: relative;
    }

    &.wider {
      width: 300px;
    }

    .form-input {
      margin-bottom: 0;
      z-index: 11;
      position: relative;

      &__field {
        padding-right: 20px;
      }

      &__icon {
        cursor: pointer;
      }
    }

    .close {
      position: absolute;
      top: 0;
      right: 0;
      width: 20px;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      z-index: 12;

      svg {
        font-size: 1.125rem;
        color: $nonary-text-color;
      }
    }

    @media (max-width: $break-sm-max) {
      display: none;
    }

    .suggestions {
      position: absolute;
      top: -10px;
      left: -10px;
      width: calc(100% + 20px);
      background-color: $primary-light-color;
      z-index: 10;
      padding: 63px 20px 10px;
      box-shadow: 0 0 5px rgba($primary-dark-color, 0.1); 

      p {
        font-size: 0.875rem;

        a {
          color: $primary-color;
          text-decoration: underline;
        }
      }

      ul {
        margin-top: 15px;

        li {
          display: flex;
          align-items: center;
          font-size: 0.875rem;

          a {
            color: $primary-text-color;

            &:hover {
              text-decoration: underline;
            }
          }

          &:not(:last-child) {
            margin-bottom: 10px;
          }

          svg {
            font-size: 1.25rem;
            margin-right: 10px;
          }
        }
      }
    }
  }

  .support-menu-btn {
    width: 36px;
    margin-right: 8px;
    color: $nonary-text-color;
    background-color: rgba(#EBECF0, 0.35);

    &:hover {
      background-color: darken(rgba(#EBECF0, 0.35), 5);
    }
  }

  .create {
    // margin-right: 15px;
    position: relative;

    .dropdown {
      position: absolute;
      right: 0;
      top: calc(100% + 5px);
      width: 120px;
      background-color: $primary-light-color;
      box-shadow: 0 0 8px rgba($shadow-color, 0.5);
      border-radius: 3px;
      z-index: 1;

      li {

        a {
          display: flex;
          align-items: center;
          padding: 5px;
          color: $nonary-text-color;
          font-size: 0.875rem;
          transition: color;

          span {
            margin-right: 5px;

            svg {
              display: block;
            }
          }

          &:hover {
            color: $primary-color;
          }
        }
      }
    }

    @media (max-width: $break-xs-max) {
      
      .button {
        padding: 0 7px;

        span {
          &.icon {
            margin: 0;
          }
          &.text {
            display: none;
          }
        }
      }
    }
  }

  .user {
    width: 24px;
    height: 24px;
    border-radius: 50%;
    background-color: $senary-bg-color;
    display: flex;
    align-items: center;
    justify-content: center;
    
    svg {
      font-size: 1.125rem;
      color: $primary-light-color;
    }
  }

  &--form {

    form {
      margin-right: 0;
    }
  }
}