.dashboard {
  padding-left: 52px;
  min-height: 100%;
  display: flex;

  &__content {
    display: flex;
    flex-direction: column;
    width: 100%;
    z-index: 10;

    &_overlay {
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 5;
      background-color: rgba(0,0,0,0.2);
      cursor: pointer;
    }
  }

  &-inner {
    padding-bottom: 0;
  }

  .line-loader {
    top: 0;
  }
}