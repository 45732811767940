@use "../abstracts/variables_new.scss" as *;

@mixin placeholder {
  &::-webkit-input-placeholder {
    @content;
  }

  &:-moz-placeholder {
    @content;
  }

  &::-moz-placeholder {
    @content;
  }

  &:-ms-input-placeholder {
    @content;
  }
}

@mixin hideScrollbar {
  &::-webkit-scrollbar {
    display: none;
  }

  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}


@mixin collectionContent {
  padding-left: 290px;
  height: calc(100% - 24px);

  @media (max-width: $break-xl-max) {
    padding-left: 266px;
  }

  @media (max-width: $break-md-max) {
    padding-left: 0;
    height: initial;
  }

  &__sidebar {
    background-color: $primary-color;
    position: fixed;
    top: 0px;
    left: 75px;
    width: 290px;
    height: 100%;
    overflow: auto;
    padding: 40px 16px;
    z-index: 5;
    color: $text-color-1;

    @include hideScrollbar();

    @media (max-width: $break-xl-max) {
      width: 266px;
    }

    @media (max-width: $break-md-max) {
      display: block;
      position: unset;
      height: auto;
      width: 100%;
      padding: 0 16px 16px;
      overflow: visible;
    }

    &_head {
      display: flex;
      align-items: center;
      color: $text-color-1;

      @media (max-width: $break-md-max) {
        display: none;
      }

      h1 {
        font-size: 24px;
        font-weight: 600;
        line-height: 32px;

        span {
          display: inline-block;
          padding-bottom: 24px;
        }
      }

      button {
        margin-left: auto;
      }
    }

    section {
      padding: 0 0 24px;
      border-bottom: 1px solid $border-color-1;
      margin-bottom: 24px;

      &:last-child {
        margin-bottom: 0;
        border-bottom: 0;
      }

      h3 {
        font-weight: 600;
        font-size: 18px;
        line-height: 1.36;
        color: $color-indigo;
        margin-bottom: 16px;
      }

      &.sidebar-filters-section {
        .sidebar-filters-section__item-separate {
          margin: 10px 0;
          border-bottom: 1px solid;
        }

        .sidebar-filters-section__item {
          margin-bottom: 12px;

          .item-btn {
            margin: 0 auto;
            display: block;
            width: 100%;
          }

          &:last-child {
            margin-bottom: 0;
            padding-bottom: 20px;
            border-bottom: 1px solid $primary-color;
          }

          &.separator-bottom {
            margin-bottom: 0;
            padding-bottom: 20px;
            border-bottom: 1px solid $primary-color;
          }
        }
      }

      &.sidebar-folders {
        ul {
          li {
            width: 100%;
            margin-bottom: 8px;

            &:last-child {
              margin-bottom: 0;
            }
          }
        }

        &.current-documents {
          li {
            .folder-list-item__main {
              cursor: pointer;
            }
          }
        }
      }

      @media (max-width: $break-md-max) {
        border-bottom: none;
        margin-bottom: 0;
        padding: 0 0 0px;
      }
    }
  }


  .filters-section {
    display: flex;
    flex-wrap: wrap;
    gap: 8px;

    .select-all {
      display: none;
      align-items: center;
    }

    @media (max-width: $break-lg-max) {
      .select-all {
        display: flex;
        margin-right: 8px;
      }
    }

    .mobile-filters-list {
      display: none;
    }

    .back-btn {
      display: none;
    }

    @media (max-width: $break-md-max) {
      display: none;

      .select-all {
        display: none;
      }

      &.show {
        display: flex;

      }

      flex-direction: column;
      width: 200px;
      border-top: 2px solid $bg-hover;
      padding: 10px;
      box-shadow: 0px 1px 8px 0px #00000026;
      position: absolute;
      right: 0px;
      z-index: 10;
      background: #fff;
      top: 31px;

      &__item {
        width: 100%;
        display: none;

        &.show {
          display: flex;
        }

        .select-v2 {
          width: 100%;

          &__body {
            width: 100%;
          }
        }
      }

      .back-btn {
        display: flex;
        text-align: left;
      }

      .mobile-filters-list {
        display: flex;
        flex-direction: column;
        color: $color-indigo;

        li {
          display: flex;
          align-items: center;
          justify-content: space-between;

          span {
            font-size: 12px;
            font-weight: 500;
          }
        }
      }
    }

    .opened {
      .select-v2__body_selected {
        background: $bg-selected;
        border: none;
      }
    }


    .select-v2__body_selected {
      border-radius: 50px;
      background: $bg-default;
      border: 0;

      &:hover:not(:active) {
        background: $color-indigo;

        p {
          color: #fff;
        }

        svg {
          fill: #fff;
          stroke: #fff;
        }
      }

      &:active {
        background: $bg-selected;
      }
    }
  }


  .select-v2__body_dropdown {

    min-width: max-content;

    .dropdown-item:hover {
      background: $bg-hover;
    }

    .dropdown-item.selected {
      background: $bg-selected;
    }
  }

  .filters-section-toggle {
    width: 32px;
    height: 32px;
    border-radius: 4px;
    border: 2px solid $bg-hover;
    float: right;
    display: none;
    justify-content: center;
    align-items: center;
    background: #fff;

    &:hover {
      background: $color-indigo;

    }

    @media (max-width: $break-md-max) {
      display: flex;
      order: 1;
    }
  }


}

.filters-section-sort {
  cursor: pointer;
  background: #fff;
  border-radius: 4px;
  border: 2px solid $bg-hover;
  height: 32px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  font-size: 14px;
  font-weight: 600;
  color: $color-indigo;
  gap: 8px;

  .sort-label {
    white-space: nowrap;

    &.empty {
      font-style: italic;
      color: #9D8FBD;
      line-height: 24px;
      font-size: 14px;
      font-weight: 500;
    }
  }

  &:active {
    background: $bg-selected !important;
    border: 2px solid $bg-selected !important;
    color: $color-indigo !important;

    svg {
      fill: $color-indigo !important;
    }
  }

  @media (max-width: $break-md-max) {
    padding: 0;
    margin-right: initial;
    width: 32px;
    height: 32px !important;

    .sort-label {
      display: none;
    }
  }

  .icon {
    width: 8px;
    display: inline-block;
  }

  .chevron-icon {
    transform: rotate(90deg);

    @media (max-width: $break-md-max) {
      display: none;
    }
  }



  &:hover {
    background: $color-indigo;
    border: 2px solid $color-indigo;
    color: #fff;

    svg {
      fill: #fff;
    }
  }

  &.hover-light:hover {
    background: $bg-hover;
    border: 2px solid $bg-hover;
    color: $color-indigo;

    svg {
      fill: $color-indigo;
    }
  }
}

@mixin signatureStatuses {
  padding: 0px 8px 0px 8px;
  border-radius: 50px;
  height: 24px;

  &.COMPLETED,
  &.SIGNING_COMPLETED {
    color: #fff;
    background-color: $highlighted-color-2;
  }

  &.SIGNING_PENDING,
  &.IN_PROGRESS {
    color: $color-indigo;
    background-color: $highlighted-color-4;
  }

  &.DECLINED {
    background-color: $highlighted-color-3;

  }

  &.EXPIRED,
  &.ACTION_NEEDED {
    color: #fff;
    background-color: $color-indigo;

  }

  &.CANCELLED {
    color: $color-indigo;
    background-color: $bg-color-2;
  }

  &.DELETED {
    background-color: lighten($highlighted-color-3, 10);
  }
}

@mixin collectionContentMainActions {
  display: flex;
  height: 50px;

  @media (max-width: $break-md-max) {
    height: auto;
    position: fixed;
    bottom: 64px;
    left: 0;
    width: 100%;
    z-index: 10;
    box-shadow: 0 0 28px 0 rgba(#000, 0.1);
  }
}

@mixin collectionContentMainMobile {
  position: fixed;
  top: 134px;
  left: 0;
  width: 100%;
  height: 45px;
  border-top: 1px solid #fff;
  display: none;
  align-items: center;
  z-index: 10;

  @media (max-width: $break-md-max) {
    display: flex;
  }

  .filters-item {
    width: 50%;
    min-width: 50%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: $bg-color-1;

    &:nth-child(1) {
      border-right: 1px solid #fff;
    }

    p {
      font-size: 0.875rem;
      line-height: 1.36;
      font-weight: 600;
      display: flex;
      align-items: center;
      justify-content: center;

      .svg-icon {
        margin-right: 5px;

        svg {
          width: 17px;
        }
      }
    }
  }
}