@use '../abstracts/variables_new.scss' as *;

.share-attachments-wrapper-v2 {

  &__label {
    font-size: 0.875rem;
    font-weight: 500;
    margin-bottom: 5px;

    a {
      color: $primary-color;
      text-decoration: underline;
    }
  }

  &__box {
    border: 1px solid $border-color-1;
    padding: 10px 15px 5px;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    border-radius: 4px;

    &_item {
      display: flex;
      align-items: center;
      margin-right: 15px;
      border-radius: 3px;
      margin-bottom: 5px;
    }

    p {
      font-size: 0.75rem;

      @media (max-width: $break-md-max) {
        font-size: 0.9375rem;
      }
    }
  }

  .attachments-disabled-msg {
    padding: 8px 0;
    color: $bg-color-4;

    @media (max-width: $break-md-max) {
      font-size: 0.9375rem;
    }
  }
}