@use '../abstracts/variables_new.scss' as *;

.notaries-modal-v2 {

  &__head {
    margin-bottom: 10px;

    &--2 {
      margin-bottom: 30px;
    }

    h2 {
      font-weight: 600;
      font-size: 1rem;
      letter-spacing: 0.29px;
      color: $primary-color;

      @media (max-width: $break-md-max) {
        font-size: 1.125rem;
      }
    }
  }

  &__search {
    padding: 10px 46px 10px 10px;
    background-color: rgba($primary-color, 0.1);
    margin-bottom: 30px;
    border-radius: 24px;
    position: relative;

    .input-v2 {
      margin-bottom: 0;

      input {
        background-color: #fff;
      }
    }

    .icon-btn {
      position: absolute;
      top: 50%;
      right: 10px;
      transform: translateY(-50%);
    }
  }

  &__body {

    &_boxes {
      min-height: 300px;
    }

    .notary-form {

      &__group {
        display: flex;
        justify-content: space-between;

        .input-v2 {
          width: 30%;
          max-width: 30%;
        }

        .select-v2 {
          margin-bottom: 15px;
          width: 100%;
        }

        @media (max-width: $break-sm-max) {
          flex-direction: column;

          .input-v2 {
            width: 100%;
            max-width: 100%;
          }
        }
      }

      &__actions {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        padding-top: 10px;

        .btn {
          margin: 0 10px 0 0;

          svg {
            fill: $primary-color;
            width: 12px;
            height: auto;
            margin-right: 5px;
          }
        }

        @media (max-width: $break-xxs-max) {
          flex-direction: column;
          align-items: flex-start;

          .btn {
            margin: 0 0 10px 0;
          }
        }
      }
    }
  }
}