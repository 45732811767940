@use '../abstracts/variables_new.scss' as *;

.choose-signature-position-v2 {
  overflow-x: auto;

  &__head {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 15px;

    .icon-btn {
      margin: 0 5px;

      &.caret {

        .svg-icon svg {
          width: 7px;
        }
      }
    }

    select {
      margin: 0 5px;
      height: 26px;
      width: 60px;
      border: 1px solid $border-color-1;
      outline: 0;
      padding: 0 10px;

      @media (max-width: $break-md-max) {
        width: 50px;
        padding: 0;
      }
    }
  }

  &__body {
    width: 778px;
    height: 1098px;
    overflow: hidden;
    position: relative;
    border: 2px solid $border-color-1;
    margin: 0 auto;

    .doc-preview {
      width: 774px;
      height: 1094px;
      overflow: hidden;
      user-select: none;

      .react-pdf__Page {
        background-color: transparent;
        padding: 0;
      }
    }

    .doc-overlay {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 10;

      .boxes-wrapper {
        position: absolute;
        display: inline-flex;
        flex-wrap: wrap;
        user-select: none;
        cursor: grab;

        &::before {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          content: '';
          z-index: 1;
        }
        
        // &.moving {
        //   cursor: grabbing;
        // }

        .s-box {
          width: 200px;
          height: 100px;
          background-color: rgba($primary-color, 0.3);
          border: 1px solid $primary-color;
          margin-right: 15px;
          margin-bottom: 15px;

          &.disabled {
            background-color: rgba($bg-color-4, 0.3);
            border-color: $bg-color-4;
          }

          span {
            background-color: #fff;
            padding: 5px;
            display: inline-block;
            font-size: 0.75rem;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
            max-width: 100%;
          }
        }
      }

      .box {
        width: 200px;
        height: 100px;
        background-color: rgba($primary-color, 0.3);
        border: 1px solid $primary-color;
        position: absolute;
        top: 300px;
        left: 280px;

        &.disabled {
          background-color: rgba($bg-color-4, 0.3);
          border-color: $bg-color-4;
        }

        &__move {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          z-index: 5;
          cursor: grab;

          &.moving {
            cursor: grabbing;
          }
        }

        &__expand-width {
          position: absolute;
          top: 0;
          right: 0;
          width: 5px;
          height: 100%;
          z-index: 7;
          cursor: e-resize;
        }

        &__expand-height {
          position: absolute;
          bottom: 0;
          left: 0;
          width: 100%;
          height: 5px;
          z-index: 6;
          cursor: s-resize;
        }
      }
    }
  }
}

.placement-options-wrapper-v2 {
  max-width: 300px;
  width: 100%;
  margin: 20px auto 0;
  position: fixed;
  bottom: 15px;
  left: 15px;
  background-color: #fff;
  z-index: 9999;

  .placement-options {
    border: 2px solid $primary-color;
    padding: 5px 20px;
    max-width: 100%;

    &__row {
      display: flex;
      align-items: center;

      p {
        font-size: 0.75rem;
        min-width: 50%;
        width: 50%;
        padding-right: 5px;

        @media (max-width: $break-md-max) {
          font-size: 0.9375rem;
        }
      }
      
      .slider-wrapper {
        min-width: 50%;
        width: 50%;

        .MuiSlider-root {

          .MuiSlider-rail {
            background-color: $primary-color;
          }

          .MuiSlider-track {
            background-color: $primary-color;
          }

          .MuiSlider-thumb {
            background-color: $primary-color;
          }
        }
      }
    }
  }
}