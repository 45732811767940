@use '../abstracts/variables' as *;

.document-detail-sp-appendices {
  padding: 16px 24px;

  @media (max-width: $break-xs-max) {
    padding-left: 15px;
    padding-right: 15px;
  }

  h4 {
    font-weight: 500;
    font-size: 1.0625rem;
    margin-bottom: 12px;
    display: flex;
    align-items: center;

    .icon {
      margin-left: 3px;

      svg {
        font-size: 1.125rem;
        color: $nonary-text-color;
        display: block;
      }
    }
  }

  .file-upload button {
    width: 100%;
  }

  .button-wrapper {
    margin-top: 20px;
  }

  .paste-attachments {
    margin-top: 20px;

    .button {
      padding: 0 10px;

      i {
        font-size: 1.25rem;
      }
    }
  }

  .add-attachment-from-url {
    margin-bottom: 15px;
    margin-top: 20px;

    &__head {
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: $form-el-bg-color-1;
      border: 1px solid $form-el-border-color-1;
      padding: 7px;
      border-radius: 2px;
      margin-bottom: 15px;

      svg {
        font-size: 1.5rem;
        margin-right: 11px;
        color: $nonary-text-color;
        opacity: 0.87;
      }

      p {
        font-size: 0.875rem;
        color: $nonary-text-color;
      }

    }

    form {
      display: flex;
      align-items: center;
      justify-content: center;
      border: 1px solid $form-el-border-color-1;
      background-color: $form-el-bg-color-1;
      border-radius: 3px;
      padding: 2px 5px;
      transition: border-color .3s ease;

      &.active {
        border: 2px solid $primary-color;
        background-color: $primary-light-color;
        padding: 5px;

        .icon {
          display: none;
        }

        .button {
          display: flex;
        }

        input {
          flex: 1;
          padding-right: 10px;
          min-width: auto;
          padding-left: 13px;
        }
      }

      .icon {
        
        svg {
          font-size: 1.5rem;
          margin-right: 11px;
          color: $nonary-text-color;
          opacity: 0.87;
          display: block;
        }
      }

      input {
        // background-image: url('../../assets/images/http-icon.svg');
        // background-repeat: no-repeat;
        // background-position: left center;
        background-color: transparent;
        border: 0;
        outline: 0;
        height: 32px;
        display: block;
        font-size: 0.875rem;
        color: $secondary-text-color;
        min-width: 230px;
      }

      .button {
        display: none;
      }
    }
  }
}