@use '../abstracts/variables_new.scss' as *;

.breadcrumbs-v2 {
  display: flex;
  align-items: center;

  &__item {
    position: relative;
    display: flex;
    align-items: center;
    font-size: 1rem;

    &--last {

      .icon-btn {
        margin-left: 5px;
      }

      .more-actions {
        position: absolute;
        left: calc(100% - 24px);
        top: calc(100% + 10px);
        white-space: nowrap;
        background-color: #fff;
        min-width: 122px;
        border-radius: 3px;
        padding: 20px 11px;
        z-index: 10;
        box-shadow: 0 2px 4px rgba(#000, 0.16);

        p {
          display: flex;
          align-items: center;
          margin-bottom: 8px;
          cursor: pointer;
          font-size: 0.8125rem;
          font-weight: 700;

          &:last-child {
            margin-bottom: 0;
          }

          &:nth-child(2) {

            svg {
              font-size: 1.5rem;
            }
          }

          span {
            width: 24px;
            margin-right: 4px;
            display: flex;
            align-items: center;
            justify-content: center;
          }

          svg {
            width: 18px;
          }

          &.delete {

            svg {
              width: 24px;
            }
          }

          &.create,
          &.move {

            svg {
              width: 20px;
            }
          }
        }
      }
    }

    .svg-icon {
      width: 16px;
      display: flex;
      align-items: center;
      justify-content: center;

      svg {
        width: 8px;
      }
    }

    p {
      cursor: pointer;
    }

    .more-folders {
      position: absolute;
      top: calc(100% + 10px);
      left: 24px;
    }

    &_more {
      background-color: #fff;
      box-shadow: 0 0 10px 0 rgba(#000, 0.16);
      border-radius: 3px;
      width: 120px;
      padding: 10px;
      z-index: 10;

      li {
        display: flex;
        align-items: center;
        font-size: 0.8125rem;
        transition: color .3s ease;
        cursor: pointer;
        font-weight: 700;

        &:not(:last-child) {
          margin-bottom: 10px;
        }

        &:hover {
          text-decoration: underline;
        }
      }
    }

    &.active {
      font-weight: 600;
    }
  }
}