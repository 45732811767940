@use '../abstracts/variables_new.scss' as *;

.dashboard-document-box {
  padding: 0px 0px;
  border-radius: 6px;
  background-color: $bg-hover;
  position: relative;
  margin-bottom: 8px;
  display: flex;
  cursor: pointer;
  overflow: hidden;
  &.signatures {
    padding-left: 8px;
  }

  &.registered_mail {
    padding-left: 8px;
  }

  @media (max-width: $break-md-max) {
    padding: 0;
    margin-bottom: 10px;
    width: 100%;
  }

  &.active {
    background-color: $bg-selected;
  }

  &:hover {
    background-color: $bg-default;
  }

  &:last-child {
    margin-bottom: 0;
  }

  &__check {
    width: 40px;
    height: 100%;
    padding: 8px 8px;
    z-index: 2;
    gap: 4px;
    display: flex;
    flex-direction: column;
    &.relative {
      position: relative;
      width: initial;
      padding: 0;
      cursor: default;
    }
  }

  &__go-to {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
  }

  h3 {
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
    margin-bottom: 4px;
    color: $text-color-1;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &__content {
    min-height: 70px;
    padding: 8px 8px 8px 4px;
    gap: 4px;
    overflow: hidden;
  }

  &__meta {
    align-items: center;
    font-size: 11px;
    font-weight: 500;
    color: $text-purple;

    @media (max-width: $break-md-max) {
      font-size: 1rem;
    }

    .date {
      color: $text-purple;
      //styleName: Body/Body S - Bold;
      font-size: 11px;
      font-weight: 500;
      line-height: 16px;
    }

    .folder {
      line-height: 1.3;
      display: flex;
      align-items: center;

      svg {
        display: block;
        width: 13px;
        margin-right: 3px;
      }
    }
  }

  .template-exists {
    position: absolute;
    top: 5px;
    right: 5px;

    @media (max-width: $break-md-max) {
      top: 7px;
      right: 7px;
    }

    span {
      display: block;
      width: 9px;
      height: 9px;
      border-radius: 50%;

      @media (max-width: $break-md-max) {
        width: 13px;
        height: 13px;
      }

      &.on {
        background-color: $highlighted-color-2;
      }

      &.off {
        background-color: $bg-color-4;
      }
    }
  }

  .mail-box-label, .folder {
    font-size: 11px;
    font-weight: 500;
    line-height: 11px;
    color: $text-color-1;
    display: block;
    margin-bottom: 4px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    svg{
      fill: $text-color-1;
    }
  }

}