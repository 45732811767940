@use '../abstracts/variables_new.scss' as *;
@use '../abstracts/mixins.scss' as *;

.signatures-content {
  @include collectionContent;
  overflow: auto;
  @include hideScrollbar();
  padding-left: 0px !important;

  &__sidebar {
    display: flex;
    justify-content: space-between;
    padding: 16px 24px 0;
    width: 100%;

    .filters-section-sort {
      margin-right: initial;
    }
  }

  .refresh-btn {
    svg {
      width: 15px;
    }
  }

  &.panel-opened {

    @media (min-width: $break-md-min) {
      padding-right: calc(327px + 24px);
    }

    // .signatures-table-column:nth-child(1) {
    //   width: 505px;
    //   flex: 0 0 505px;
    // }

    // @media (max-width: 1000px) {
    // padding-right: 0;
    // }

    .signatures-status-panel {
      right: 24px;

      @media (max-width: $break-md-max) {
        right: 0px;
      }

      &__toggle {
        transform: translateX(-50%);
      }
    }
  }

  &__main {
    padding: 16px 24px;

    @media (max-width: $break-md-max) {
      padding: 16px;
      overflow: auto;
      background: #fff;
      border-radius: 14px 14px 0 0;
      position: relative;
    }

    &_actions {
      @include collectionContentMainActions;
    }

    &_mobile {
      @include collectionContentMainMobile;

      .filters-item {
        width: 100%;
        min-width: 100%;
      }
    }
  }

  .filters-section {
    display: flex;
    gap: 8px;
    flex-wrap: wrap;

    .select-v2__body_selected {
      border-radius: 50px;
      background: $bg-default;
    }

    .mobile-filters-list {
      display: none;
    }

    .back-btn {
      display: none;
    }

    @media (max-width: $break-md-max) {
      display: none;

      .select-all {
        display: none;
      }

      &.show {
        display: flex;

      }

      flex-direction: column;
      width: 200px;
      border-top: 2px solid $bg-hover;
      padding: 10px;
      box-shadow: 0px 1px 8px 0px #00000026;
      position: absolute;
      right: 0px;
      z-index: 10;
      background: #fff;
      top: 31px;

      &__item {
        width: 100%;
        display: none;

        &.show {
          display: flex;
        }

        .select-v2 {
          width: 100%;

          &__body {
            width: 100%;
          }
        }
      }

      .back-btn {
        display: flex;
        text-align: left;
      }

      .mobile-filters-list {
        display: flex;
        flex-direction: column;
        color: $color-indigo;

        li {
          display: flex;
          align-items: center;
          justify-content: space-between;

          span {
            font-size: 12px;
            font-weight: 500;
          }
        }
      }
    }

    .select-v2__body_selected {
      border-radius: 50px;
      background: $bg-default;
      border: 0;
    }
  }

  .filters-section-toggle {
    width: 32px;
    height: 32px;
    border-radius: 4px;
    border: 2px solid $bg-hover;
    float: right;
    display: none;
    justify-content: center;
    align-items: center;

    @media (max-width: $break-md-max) {
      display: flex;
      order: 1;
    }
  }
}