@use '../abstracts/variables_new.scss' as *;

.add-new-document {
  position: relative;

  @media (max-width: $break-md-max) {
    // display: none;
  }

  // &__body-wrapper {
  //   position: relative;
  //   margin-left: 8px;
  // }

  &__body {
    position: relative;
    height: 40px;
    cursor: pointer;
    transition: width .3s ease;
    border-radius: 25px;
    background-color: $color-indigo;
    display: flex;
    align-items: center;
    padding: 0 16px 0 12px;
    gap: 8px;
    color: #fff;

    svg {
      width: 18px;
    }

    @media (max-width: $break-md-max) {
      width: 100%;
      height: 48px;
      padding: 0px 16px;
      grid-gap: 8px;
      gap: 8px;
      border-radius: 24px;
      color: #fff;
      background: #482982;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 14px;
      font-weight: 600;
      margin-bottom: 8px;

      svg {
        width: initial;
      }
    }

    &:hover {
      background-color: $bg-hover;
      color: $color-indigo;

      svg {
        fill: $color-indigo;
      }
    }

    &.active {
      background: $bg-selected;
      color: $color-indigo;

      svg {
        fill: $color-indigo;
      }
    }

    &_main {
      display: flex;
      align-items: center;
      opacity: 1;
      transition: opacity .3s ease;
      height: 40px;

      p {
        font-size: 14px;
        font-weight: 600;
        line-height: 40px;
        text-wrap: nowrap;
        white-space: nowrap;
      }
    }

    >.add-new-document__dropdown {
      border-radius: 13px 0 13px 13px;
    }

    >.add-new-document__dropdown>ul>li ul {
      position: absolute;
      top: 0;
      right: 100%;
      border-radius: 13px;
    }
  }

  &__dropdown-menu-wrapper {
    display: flex;
    flex-direction: row-reverse;
    position: absolute;
    right: 0;
    top: 56px;
    width: max-content;
  }

  &__dropdown {
    // position: absolute;
    z-index: 1000;
    top: 100%;
    right: 0;
    background-color: $bg-purple2;
    border: 8px solid #fff;
    border-radius: 8px;
    box-shadow: 0px 0px 24px 0px #00000026;
    max-height: 80vh;
    overflow: auto;
    width: max-content;
    height: min-content;
    width: 347px;
    color: #fff;

    &-header {
      padding: 8px 8px 0;
      color: $color-indigo;
      font-weight: 600;
      font-size: 1.1rem;
    }

    .return {
      cursor: pointer;
    }

    @media (max-width: $break-md-max) {
      position: fixed;
      width: 100%;
      top: initial;
      bottom: 0;
    }

    >ul {
      padding: 8px;
      border-radius: 8px;
      display: flex;
      flex-direction: column;
      gap: 8px;

      >li {
        position: relative;
        background: #fff;
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-radius: 4px;
        padding: 8px 8px 8px 16px;
        cursor: pointer;

        &:hover {
          background-color: $bg-hover;
        }

        a {
          //styleName: Body/Body L - Bold;
          font-size: 14px;
          font-weight: 500;
          line-height: 20px;
          color: $color-indigo;
          display: flex;
          align-items: center;
          transition: background-color .3s ease;

          span {

            &.text {
              display: block;
            }
          }

          svg {
            margin-right: 10px;
            fill: $color-indigo;
          }

        }

        svg {
          fill: $color-indigo;
        }

        ul {
          max-width: 100%;
          min-width: 80%;
          height: auto;
          box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
          border: 5px solid #fff;
          display: none;
          overflow-y: auto;
          z-index: 5;
          background-color: $primary-color;
          padding: 8px;
          flex-direction: column;
          gap: 8px;

          li {
            max-width: 100%;
            height: auto;
            background-color: #fff;

            a {
              max-width: 100%;
              height: auto;
              // height: 24px;
              padding: 6px 15px;

              &:hover {
                font-weight: 300;
              }
            }

            &:first-child {

              a {
                border-radius: 13px 13px 0 0;
              }
            }

            &:last-child {
              border-bottom: 0;

              a {
                border-radius: 0 0 13px 13px;
              }
            }
          }
        }

        &:hover {

          ul {
            display: flex;
          }
        }
      }
    }
  }
}

.empty-folder {
  color: $color-indigo;
  font-size: 14px;
  font-weight: 600;
}