@use '../abstracts/variables_new.scss' as *;

.mt-0 {
  margin-top: 0 !important;
}

.mt-5 {
  margin-top: 5px !important;
}

.mb-20 {
  margin-bottom: 20px !important;
}

.mt-20 {
  margin-top: 20px !important;
}

.mb-40 {
  margin-bottom: 40px !important;
}

.mt-40 {
  margin-top: 40px !important;
}

.mb-20 {
  margin-bottom: 20px !important;
}

.mb-30 {
  margin-bottom: 30px !important;
}

.flex {
  display: flex !important;
}

.flex-column {
  flex-direction: column !important;
}

.flex-row {
  flex-direction: row !important;
}

.flex-wrap {
  flex-wrap: wrap;
}

.justify-center {
  justify-content: center !important;
}

.justify-between {
  justify-content: space-between !important;
}

.justify-end {
  justify-content: flex-end;
}

.justify-start {
  justify-content: flex-start;
}

.align-center {
  align-items: center;
}

.align-end {
  align-items: flex-end;
}

.align-start {
  align-items: flex-start;
}

.text-center {
  text-align: center;
}

.text-left {
  text-align: left;
}

.gap-5 {
  gap: 5px;
}

.gap-20 {
  gap: 20px;
}

.w-full {
  width: 100%;
}

.w-half {
  width: 50%;
}

.w-quarter {
  width: 25%;
}

.w-min-content {
  width: min-content;
}

.flex-0 {
  flex: 0;
}

.flex-1 {
  flex: 1;
}

.flex-grow {
  flex-grow: 1;
}

.mr-10 {
  margin-right: 10px !important;
}

.mr-20 {
  margin-right: 20px !important;
}

.mr-30 {
  margin-right: 30px !important;
}

.ml-10 {
  margin-left: 10px !important;
}

.ml-20 {
  margin-left: 20px !important;
}

.ml-30 {
  margin-left: 30px !important;
}

.pr-10 {
  padding-right: 10px !important;
}

.pr-20 {
  padding-right: 20px !important;
}

.pr-30 {
  padding-right: 30px !important;
}

.pl-0 {
  padding-left: 0 !important;
}

.pl-10 {
  padding-left: 10px !important;
}

.pl-20 {
  padding-left: 20px !important;
}

.pl-30 {
  padding-left: 30px !important;
}

.pt-10 {
  padding-top: 10px !important;
}

.pt-20 {
  padding-top: 20px !important;
}

.pt-30 {
  padding-top: 30px !important;
}

.pb-10 {
  padding-bottom: 10px !important;
}

.pb-20 {
  padding-bottom: 20px !important;
}

.pb-30 {
  padding-bottom: 30px !important;
}

.gap-1 {
  gap: 0.25rem;
}

.gap-2 {
  gap: 0.5rem;
}

.gap-3 {
  gap: 0.75rem;
}

.gap-4 {
  gap: 1rem;
}

.border-none {
  border: none !important;
}

.line-height-15 {
  line-height: 1.5;
}

.line-height-18 {
  line-height: 1.8;
}

.line-height-2 {
  line-height: 2;
}

.iframe {
  width: 100%;
  height: calc(100vh - 170px);
  border: 0;
}

.buttons-wrapper {
  padding: 20px 0;
  display: flex;
  align-items: center;
  justify-content: center;

  &--gap-20 {
    column-gap: 20px;
  }

  .button {
    margin: 0 15px;
  }
}

.loader-wrapper,
.preview-error {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 50px 0;

  &--2 {
    padding: 0;
  }

  &--3 {
    padding: 5px 0;
  }
}

.draggable-ghost-el {
  font-size: 1rem;
  padding: 10px 20px;
  display: block;
  position: absolute;
  top: -1000px;
  left: 0;
  background-color: rgba($primary-color, 1);
  color: $primary-light-color;
  box-shadow: 0 0 100px rgba($shadow-color, 0.9);
  display: flex;
  align-items: center;
  min-width: 120px;
  max-width: 300px;
  width: auto;
  cursor: all-scroll;
}

.no-data-to-show {
  text-align: center;
  padding: 50px 0;
}

.back-to-top {
  font-size: 1.5rem;
  color: rgba($primary-dark-color, 0.2);
  cursor: pointer;
  transition: color .3s ease, opacity .3s ease;
  z-index: -1;
  position: relative;
  left: -9999px;
  opacity: 0;

  &.active {
    z-index: 1;
    opacity: 1;
    left: 0;
  }

  &--2 {
    margin-bottom: 20px;
  }

  &:hover {
    color: $primary-color;
  }
}

.u-error {
  font-size: 0.75rem;
  color: $secondary-color;
  padding: 5px 0;

  &--2 {
    color: $secondary-bg-color;
  }
}

.signature-positions {
  display: flex;
  align-items: flex-start;
  margin-bottom: 20px;

  &__page {
    display: flex;
    align-items: center;
    height: 36px;
    font-size: 1rem;
    padding: 0 12px;
    border-radius: 4px;
    margin-right: 11px;
    background-color: #F3F3F3;
    color: #767676;
    text-transform: capitalize;
  }

  .form-input {
    margin-right: 20px;
    margin-bottom: 0 !important;
    width: 100px;
    flex: 0 1 auto !important;
  }

  .button,
  .icon-button {
    align-self: flex-end;
  }
}

.fields-wrapper {
  margin: 20px 0 10px;

  &--2 {
    margin: 0;
  }

  >.button {
    margin-bottom: 20px;
  }
}

.field-positions {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  position: relative;
  flex-wrap: wrap;

  &__page {
    display: flex;
    align-items: center;
    height: 36px;
    font-size: 1rem;
    padding: 0 12px;
    border-radius: 4px;
    margin-right: 11px;
    background-color: #F3F3F3;
    color: #767676;
    text-transform: capitalize;
  }

  .form-input {
    // margin-right: 20px;
    margin-bottom: 0 !important;
    // width: 100px;
    flex: 0 1 auto !important;

    &.label-input {
      // width: 120px;
      margin-right: 13px;
    }

    &.checkbox-page {
      margin-right: 11px;

      input {
        width: 56px;
        height: 36px;
      }
    }
  }

  &__divider {
    height: 22px;
    width: 1px;
    background-color: #ccc;
    margin-right: 17px;
  }

  .checkbox-wrapper {
    margin-bottom: 0;
    margin-right: 17px;

    &__field {
      background-color: #F3F3F3;
      border-radius: 4px;
      padding: 0 9px 0 4px;
      display: flex;
      align-items: center;
      height: 36px;
      margin-right: 13px;

      &_fix {
        position: relative;
        z-index: 1;

        &::before {
          content: '';
          background-color: #fff;
          position: absolute;
          top: 4px;
          left: 4px;
          width: 16px;
          height: 16px;
          z-index: -1;
        }
      }

      p {
        color: #2E2F35;
        font-size: 0.875rem;
      }

      .checkbox {

        svg path {
          // background: #fff;
        }
      }
    }
  }

  .button {

    &.label-btn {
      margin-right: 10px;
      height: 36px;
    }

    &:last-child {
      margin-left: auto;
    }
  }

  &__error {
    padding-top: 5px;
    font-size: 0.75rem;
    color: $secondary-color;
    position: absolute;
    top: 100%;
    left: 0;
  }

  .checkbox-label {
    display: flex;

    .form-input {

      input {
        height: 36px;
        width: 169px;
        font-weight: 400;
      }
    }

    .edit-btn {
      margin-left: 0 !important;
      margin-right: 13px;
    }
  }

  .delete-btn,
  .edit-btn {
    width: 36px;
    height: 36px;
    color: $nonary-text-color;
    transition: color 0.3s ease, background-color 0.3s ease;

    &:hover {
      color: $secondary-color-light;
      background-color: rgba($secondary-color-light, 0.1);
    }
  }

  .edit-btn {

    &:hover {
      background-color: rgba($primary-color, 0.1);
      color: $primary-color;
    }
  }

  &__remove {
    margin-left: auto;
  }

  @media (max-width: $break-md-max) {
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 20px;
    padding-bottom: 20px;
    border-bottom: 1px solid #f2f2f2;

    &:last-child {
      margin-bottom: 0;
      padding-bottom: 0;
      border-bottom: 0;
    }

    &__page {
      margin-bottom: 13px;
    }

    &__divider {
      height: 1px;
      background-color: transparent;
      margin: 13px 0 0;
    }

    &__remove {
      position: absolute;
      top: 0;
      right: 0;
    }
  }
}

.sortable-wrapper {
  padding: 20px 0 0;
}

.sortable-list-item {
  background-color: $primary-light-color;
  border: 1px solid $primary-border-color;
  padding: 8px 13px;
  border-radius: 3px;
  cursor: grab;
  margin-bottom: 10px;
  font-size: 0.875rem;
  user-select: none;
}

.checkbox-wrapper {
  display: flex;
  align-items: center;
  margin-bottom: 30px;

  p {
    font-size: 0.875rem;
    margin-left: 5px;
  }

  .button {
    margin-left: 20px;
  }
}

.u-cursor--pointer {
  cursor: pointer;
}

.u-cursor--default {
  cursor: default !important;
}

.u-white-space--nowrap {
  white-space: nowrap;
}

.u-white-space--pre-wrap {
  white-space: pre-wrap;
}

.status-el {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-size: 0.5625rem;
  line-height: 1.22;
  font-weight: 500;
  letter-spacing: 0.4px;
  color: $nonary-text-color;
  padding: 5px 8px;
  border-radius: 9px;
  background-color: $tertiary-bg-color;
  text-transform: uppercase;

  &--ready {
    background-color: rgba($primary-color, 0.08);
    color: $primary-color;
  }

  &--draft {
    background-color: rgba($tertiary-color-light, 0.54);
    color: $tertiary-color;
  }
}

.progress-el {
  width: 100px;
  height: 4px;
  border-radius: 3px;
  background-color: $tertiary-bg-color;
  position: relative;

  &__inner {
    background-color: $octonary-color;
    border-radius: 3px;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
  }
}

.link-wrapper {
  margin-bottom: 20px;

  a {
    display: inline-flex;
    align-items: center;
    color: $primary-color;
    border-bottom: 1px solid transparent;
    flex-wrap: wrap;
    white-space: normal;

    svg {
      margin-right: 2px;
      font-size: 1rem;
    }

    &:hover {
      border-bottom: 1px solid $primary-color;
    }
  }
}

.load-more-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 5px;
  margin-bottom: 20px;

}

// .u-custom-scrollbar {
//   -webkit-overflow-scrolling: touch;
//   scrollbar-width: thin;
//   scrollbar-color: #cbcbcb #f2f2f2;
//   // -webkit-transform: translate3d(0, 0, 0); // react-beautiful-dnd didn't work correctly when item was dragged and this was on

//   &::-webkit-scrollbar {
//     height: 6px;
//     width: 6px;
//   }

//   &::-webkit-scrollbar-button:start:decrement,
//   &::-webkit-scrollbar-button:end:increment {
//     background: transparent;
//     display: none;
//   }

//   &::-webkit-scrollbar-track-piece {
//     background: #f2f2f2;
//   }

//   &::-webkit-scrollbar-track-piece:vertical:start {
//     border-radius: 3px 3px 0 0;
//   }

//   &::-webkit-scrollbar-track-piece:vertical:end {
//     border-radius: 0 0 3px 3px;
//   }

//   &::-webkit-scrollbar-track-piece:horizontal:start {
//     border-radius: 3px 0 0 3px;
//   }

//   &::-webkit-scrollbar-track-piece:horizontal:end {
//     border-radius: 0 3px 3px 0;
//   }

//   &::-webkit-scrollbar-thumb:vertical,
//   &::-webkit-scrollbar-thumb:horizontal {
//     background: #cbcbcb;
//     border-radius: 3px;
//     display: block;
//     height: 48px;
//   }
// }

// .u-custom-scrollbar--2 {

//   &::-webkit-scrollbar {
//     height: 10px;
//     width: 10px;
//   }

//   &::-webkit-scrollbar-track-piece:vertical:start {
//     border-radius: 5px 5px 0 0;
//   }

//   &::-webkit-scrollbar-track-piece:vertical:end {
//     border-radius: 0 0 5px 5px;
//   }

//   &::-webkit-scrollbar-track-piece:horizontal:start {
//     border-radius: 5px 0 0 5px;
//   }

//   &::-webkit-scrollbar-track-piece:horizontal:end {
//     border-radius: 0 5px 5px 0;
//   }

//   &::-webkit-scrollbar-thumb:vertical,
//   &::-webkit-scrollbar-thumb:horizontal {
//     border-radius: 5px;
//   }
// }

// .u-custom-scrollbar--3 {
//   -webkit-overflow-scrolling: touch;
//   scrollbar-width: thin;
//   scrollbar-color: #1E548A #cbcbcb;
//   // -webkit-transform: translate3d(0, 0, 0); // react-beautiful-dnd didn't work correctly when item was dragged and this was on

//   &::-webkit-scrollbar {
//     height: 6px;
//     width: 6px;
//   }

//   &::-webkit-scrollbar-button:start:decrement,
//   &::-webkit-scrollbar-button:end:increment {
//     background: transparent;
//     display: none;
//   }

//   &::-webkit-scrollbar-track-piece {
//     background: #cbcbcb;
//   }

//   &::-webkit-scrollbar-track-piece:vertical:start {
//     border-radius: 3px 3px 0 0;
//   }

//   &::-webkit-scrollbar-track-piece:vertical:end {
//     border-radius: 0 0 3px 3px;
//   }

//   &::-webkit-scrollbar-track-piece:horizontal:start {
//     border-radius: 3px 0 0 3px;
//   }

//   &::-webkit-scrollbar-track-piece:horizontal:end {
//     border-radius: 0 3px 3px 0;
//   }

//   &::-webkit-scrollbar-thumb:vertical,
//   &::-webkit-scrollbar-thumb:horizontal {
//     background: #1E548A;
//     border-radius: 3px;
//     display: block;
//     height: 48px;
//   }
// }

// .hide-scrollbar {
//   scrollbar-width: none;
//   scrollbar-color: transparent transparent;

//   &::-webkit-scrollbar {
//     width: 0px;
//   }

//   &::-webkit-scrollbar-track {
//     background: transparent;
//   }

//   &::-webkit-scrollbar-thumb {
//     background-color: transparent;
//   }
// }

.text-error {
  color: $highlighted-color-3;
  margin-top: 10px;
  font-weight: 600;
  border: solid 1px $highlighted-color-3;
  background-color: #fff5f5;
  padding: 7px;
  border-radius: 4px;
  text-align: center;
}

.text-success {
  color: #00a651;
  margin-top: 10px;
  border: solid 1px #00a651;
  padding: 7px;
  border-radius: 4px;
  text-align: center;
}

.rotate-45 {
  transform: rotate(45deg);
}

.rotate-90 {
  transform: rotate(90deg);
}

.rotate-135 {
  transform: rotate(135deg);
}

.rotate-180 {
  transform: rotate(180deg);
}

.rotate-225 {
  transform: rotate(225deg);
}

.rotate-270 {
  transform: rotate(270deg);
}

.rotate-315 {
  transform: rotate(315deg);
}

.h-flip {
  transform: scaleX(-1);
}

.v-flip {
  transform: scaleY(-1);
}

.position-relative {
  position: relative;
}


.sm-d-none {
  @media (max-width: $break-md-max) {
    display: none !important;
  }
}

.sm-d-flex {
  display: none !important;

  @media (max-width: $break-md-max) {
    display: flex !important;
  }
}

.sm-d-block {
  display: none !important;

  @media (max-width: $break-md-max) {
    display: block !important;
  }
}

#root {
  &:has(.landscape-primary) {
    transform: rotate(-90deg);
    transform-origin: left top;
    width: 100dvh;
    height: 100dvw;
    overflow-x: scroll;
    position: absolute;
    top: 100%;
    left: 0;
    margin: 0;
    padding: 0;
  }
  &:has(.landscape-secondary) {
    transform: rotate(90deg);
    transform-origin: left top;
    width: 100dvh;
    height: 100dvw;
    overflow-x: scroll;
    position: absolute;
    top: 0;
    left: 100%;
    margin: 0;
    padding: 0;
  }
}