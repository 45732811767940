@use '../abstracts/variables_new.scss' as *;

.single-document-variables {

  div,
  h1,
  h2,
  h3,
  span {
    scroll-margin: 150px;
  }

  &__body {

    >div.variables-v2:first-child {

      .single-document-variable-head {
        margin-top: 0;
      }
    }

    section {
      margin-bottom: 50px;
    }

    h4 {
      color: $primary-color;
      margin-bottom: 16px;

      span {
        border-bottom: 2px solid $primary-color;
      }
    }
  }

  .select_input_wrapper {
    margin-left: 10px;
    margin-right: 10px;

    &.multiselect {

      div {
        display: flex;
        align-items: center;
      }
    }
  }

  .textarea-el {

    .textarea-lines {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      cursor: pointer;
    }

    &.lines-added {

      textarea:disabled {
        background-color: #fff !important;
      }
    }
  }

  .date_picker-v2 {
    margin-left: 10px;
    margin-right: 10px;
    position: relative;

    .react-datepicker-wrapper {
      width: 153px;
      min-width: initial;

      .date-input__body {
        position: relative;
        height: 32px;

        &.clearable {
          padding-right: 0;
        }
      }

      input {
        height: 32px;
        background: #fff;
        border-radius: 4px;
        border: 2px solid $bg-hover;
      }
    }

    .date-lines {
      width: 100%;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      left: 0;
      padding-left: 27px;
      color: $primary-color;
      font-size: 0.75rem;
      cursor: pointer;

      @media (max-width: $break-md-max) {
        font-size: 0.9375rem;
      }
    }
  }

  .raw-text-space {
    display: none;
    width: 10px;
    height: 1px;
  }

  &__foot {
    margin-top: 20px;

    .variables-v2__text {
      margin-right: -5px;
      margin-left: -5px;
    }

    h4 {
      // width: calc(100% + 68px);
      // margin-left: -34px;
      border-bottom: 1px solid $primary-color;
      // padding-left: 60px;
      margin-bottom: 20px;

      @media (max-width: $break-md-max) {
        width: calc(100% + 28px);
        margin-left: -14px;
      }

      span {
        padding: 5px 24px;
        display: flex;
        align-items: center;
        height: 40px;
        background-color: $color-indigo;
        border: 1px solid $primary-color;
        border-radius: 8px 8px 0 0;
        font-size: 14px;
        font-weight: 600;
        color: #fff;
        border-bottom: 0;
      }
    }
  }

  table {
    width: 100%;
    max-width: 100%;
    border-collapse: collapse;
    table-layout: fixed;
  }

  table,
  th,
  td {
    border: 1px solid $primary-color;
    max-width: 100%;
  }

  thead {
    background-color: $primary-color;
    max-width: 100%;
  }

  thead td {
    color: $color-indigo;
  }

  td {
    padding: 8px;
  }

  td .form-input {
    margin: 0;
  }

  td .tooltip-wrapper {
    max-width: 80%;

    @media (max-width: $break-md-max) {
      max-width: 100%;
    }
  }

  .row-count-input-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 16px 0;
    justify-content: flex-end;
    padding: 0 24px;
  }

  .row-count-input-label {
    margin-right: 10px;
    color: $color-indigo;
  }

  .row-count-button.button {
    background-color: $primary-color;
    color: $color-indigo;

    &:disabled {
      background-color: lightgray;
    }
  }

  .row-count-input {
    width: 20px;
    margin-bottom: 0;
    margin-left: 10px;
    margin-right: 10px;
    text-align: center;
    color: $primary-color;
    font-weight: bold;
    color: $color-indigo;

    &.disabled {
      color: darkgray;
    }
  }
}

.variable-attachment {
  margin: 0 0 16px 0;

  .heading-section {
    display: flex;
    align-items: center;

    span {
      display: flex;
      align-items: center;
    }
  }
}

.attach-file-box {
  background: $primary-color;
  padding: 20px;
}