@use '../abstracts/variables_new.scss' as *;

.folder-list-item {
  display: block;
  width: 100%;

  &__main {
    border-radius: 8px;
    transition: background-color .3s ease;
    padding: 8px 8px 8px 12px;
    gap: 8px;
    border-radius: 8px;
    position: relative;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    &:hover,
    &.active {
      background-color: $bg-hover;
    }

    .icon-arrow {
      position: absolute;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
      width: 20px;
      height: 20px;
      display: flex;
      align-items: center;
      justify-content: center;

      svg {
        width: 6px;
      }

      &.active {
        transform: translateY(-50%) rotate(90deg);
      }
    }

    .icon-right {
      position: absolute;
      top: 50%;
      right: 8px;
      transform: translateY(-50%);
      width: 20px;
      height: 20px;
      display: flex;
      align-items: center;
      justify-content: center;

      svg {
        width: 16px;
      }
    }

    &.root {

      span.text {
        display: flex;
      }
    }

    p {
      position: relative;
      display: flex;
      align-items: center;
      min-width: 0;
      user-select: none;
      cursor: pointer;
      color: $text-color-1;
      width: 100%;
      flex: 0 0 86%;

      svg {
        color: $color-indigo;
        margin-right: 8px;
      }

      span.text {
        font-weight: 600;
        font-size: 14px;
        text-overflow: ellipsis;
        display: block;
        white-space: nowrap;
        overflow: hidden;
        line-height: 16px;
        align-items: center;
        width: 100%;
      }

      span.number {
        //styleName: Component/Action;
        font-size: 14px;
        font-weight: 600;
        line-height: 21px;
        color: $text-purple;
        display: block;
        text-align: center;
        width: 20px;
      }
    }

    .dropdown {
      border: 8px solid #fff;
      min-width: 184px;
      border-radius: 8px;

      position: fixed;
      display: flex;
      flex-direction: column;
      gap: 8px;
      // top: -11px;
      // left: calc(100% + 10px);
      box-shadow: 0px 0px 24px 0px #00000026;
      z-index: 1;
      transform: translate(35px, -18px);
      background: #fff;

      ul {
        padding: 8px;
        background-color: $bg-purple2;
        border-radius: 4px;
      }

      &::before {
        content: "";
        position: absolute;
        top: 12px;
        left: -17px;
        width: 16px;
        height: 16px;
        background: #fff;
        transform: rotate(45deg);
      }

      &::after {
        content: "";
        position: absolute;
        top: 12px;
        left: -1px;
        width: 2px;
        height: 16px;
        background: $bg-purple1;
      }

      @media (max-width: $break-lg-max) {
        top: 37px !important;
        right: -8px !important;
        left: initial !important;
        position: absolute !important;
        transform: translate(0, 0);

        &::before {
          content: "";
          position: absolute;
          top: -16px;
          right: 10px;
          left: initial;
          width: 16px;
          height: 16px;
          background: #fff;
          transform: rotate(45deg);
        }

        &::after {
          content: "";
          position: absolute;
          top: 0px;
          right: 10px;
          left: initial;
          width: 16px;
          height: 3px;
          background: $bg-purple1;
        }
      }

      a {
        background: #fff;
        display: flex;
        align-items: center;
        color: $color-indigo;
        cursor: pointer;
        border-radius: 5px;
        font-weight: 500;
        line-height: 20px;
        font-size: 14px;
        padding: 3px 16px;
        transition: color .3s ease, background-color .3s ease;
        height: 40px;
        gap: 16px;
        white-space: nowrap;

        svg {
          fill: $color-indigo;
          transition: fill .3s ease;
        }

        span {
          width: 24px;
          display: flex;
          align-items: center;
          justify-content: center;
        }

        &.delete {
          color: $danger-color;

          svg {
            width: 24px;
            fill: $danger-color;
          }
        }

        &:hover {
          background-color: $bg-hover;
          color: $color-indigo;

          &.delete {
            background-color: $danger-color;
            color: #fff;

            svg {
              fill: #fff;
            }
          }


        }
      }
    }
  }

  &__body {

    .sub-folders {
      padding: 2px 0 2px 8px;
    }
  }
}

.dragged-over-item {
  position: relative;

  &:after {
    content: "";
    position: absolute;
    background: #c5c5c5;
    animation: flicker 1s infinite;
  }

  &.top:after {
    top: 0;
    width: 100%;
    height: 3px;
  }

  &.bottom:after {
    bottom: 0;
    width: 100%;
    height: 3px;
  }

  &.drop-disabled {
    &:after {
      display: none !important;
    }
    cursor: not-allowed !important
  }
}

@keyframes flicker {
  0% {
    opacity: 0.2;
  }

  50% {
    opacity: 1;
  }

  100% {
    opacity: 0.2;
  }
}

.grabbable {
  cursor: move;
  /* fallback if grab cursor is unsupported */
  cursor: grab;
  cursor: -moz-grab;
  cursor: -webkit-grab;
}

/* (Optional) Apply a "closed-hand" cursor during drag operation. */
.grabbable:active {
  cursor: grabbing;
  cursor: -moz-grabbing;
  cursor: -webkit-grabbing;
}