@use '../abstracts/variables' as *;

.breadcrumbs {
  display: flex;
  align-items: center;

  &__item {
    position: relative;
    display: flex;
    align-items: center;

    &--last {

      .icon-button {
        margin-left: 5px;
      }

      .more-actions {
        position: absolute;
        left: calc(100% - 24px);
        top: calc(100% + 10px);
        white-space: nowrap;
        background-color: $primary-light-color;
        min-width: 122px;
        border-radius: 3px;
        padding: 20px 11px;
        z-index: 10;
        box-shadow: 0 2px 4px rgba($shadow-color-6, 0.5);

        p {
          display: flex;
          align-items: center;
          margin-bottom: 8px;
          color: $nonary-text-color;
          cursor: pointer;
          font-size: 0.8125rem;
          font-weight: 700;

          &:last-child {
            margin-bottom: 0;
          }

          &:nth-child(2) {

            svg {
              font-size: 1.5rem;
            }
          }

          span {
            width: 24px;
            margin-right: 4px;
            display: flex;
            align-items: center;
            justify-content: center;
          }

          svg {
            font-size: 1.125rem;
            color: $nonary-text-color;
          }

          &.delete {

            svg {
              font-size: 1.5rem;
            }
          }

          &.create,
          &.move {

            svg {
              font-size: 1.25rem;
            }
          }
        }
      }
    }

    p {
      cursor: pointer;
    }

    .more-folders {
      position: absolute;
      top: calc(100% + 10px);
      left: 24px;
    }

    &_more {
      background-color: $primary-light-color;
      box-shadow: 0 0 10px 0 rgba($shadow-color, 0.5);
      border-radius: 3px;
      width: 120px;
      padding: 10px;
      z-index: 10;

      li {
        display: flex;
        align-items: center;
        font-size: 0.8125rem;
        color: $nonary-text-color;
        transition: color .3s ease;
        cursor: pointer;
        font-weight: 700;

        &:not(:last-child) {
          margin-bottom: 10px;
        }

        &:hover {
          color: $tertiary-text-color;
        }
      }
    }
  }
}