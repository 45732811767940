.transition {

  &__fade {

    &-enter {
      opacity: 0;
      transform: scale(0);
    }
    &-enter-active {
      opacity: 1;
      transform: scale(1);
      transition: opacity .3s, transform .3s;
    }
    &-exit {
      opacity: 1;
      transform: scale(1);
    }
    &-exit-active {
      opacity: 0;
      transform: scale(0);
      transition: opacity .3s, transform .3s;
    }
  }
}