@use '../abstracts/variables_new.scss' as *;

.template-preview-modal {

  &__preview {

    .react-pdf__Page__textContent {
      display: none !important;
    }

    .react-pdf__Page {
      box-shadow: 0 3px 21px 0 rgba(#000, 0.16);
      margin-bottom: 15px;

      canvas {
        width: 100%;
        max-width: 100%;
        height: auto !important;
      }
    }
  }

  &__create-doc {
    display: flex;
    align-items: center;
    justify-content: center;

    .icon-btn {
      position: relative;
      top: -28px;
    }
  }
}