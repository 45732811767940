@use '../abstracts/variables_new.scss' as *;

.report-a-bug {

  &__head {
    padding: 36px 30px;
    background-color: rgba($bg-hover, 0.33);
    border-radius: 8px 8px 0 0;
    display: flex;
    justify-content: space-between;

    h3 {
      display: flex;
      align-items: center;
      font-size: 24px;
      font-weight: 600;
      color: $color-indigo;

      svg {
        display: block;
        margin-right: 5px;
      }
    }
  }

  &__body {
    padding: 24px 16px 32px;

    >p {
      font-size: 18px;
      font-weight: 600;
      color: $color-indigo;
      text-align: center;
    }

    p.already-voted {
      padding: 20px;
      text-align: center;
      border: 1px solid #EBECF0;
      margin-top: 20px;
    }

    .emojis {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 30px 0;
      gap: 24px;

      @media (max-width: $break-md-max) {
        flex-direction: column;
      }

      a {
        text-align: center;
        width: 56px;
        color: $color-indigo;

        svg {
          font-size: 3rem;
          border: 1px solid #F0EBF9;
          border-radius: 50%;
          padding: 3.2px;
          width: 48px;
          height: 48px;
        }

        span {
          display: flex;
          justify-content: center;
          font-size: 11px;
          font-weight: 500;
          line-height: 16px;
          margin-top: 4px;
          color: $text-purple;
        }

        &.active {
          span {
            color: #E8025F;
          }

          svg {
            fill: #E8025F;
          }
        }
      }
    }

    form {

      .textarea {
        background: $bg-purple2;
        border-radius: 8px;
        padding: 24px 16px;

        &__field {
          height: 133px;
          resize: none;
        }
      }

      .image-wrapper {
        display: flex;
        margin-bottom: 30px;
        align-items: center;

        @media (max-width: $break-xs-max) {
          display: block;
        }

        &__left {
          margin-right: 15px;

          @media (max-width: $break-xs-max) {
            margin-right: 0;
            margin-bottom: 15px;
          }

          p,
          ul li {
            font-size: 0.875rem;
            margin-bottom: 10px;
          }

          ul {

            li {

              &:last-child {
                margin-bottom: 0;
              }

              a {
                color: #000;

                &.active {
                  color: #006EFF;
                }
              }
            }
          }
        }

        &__right {
          background-color: #EBECF0;
          flex: 1;

          &_dnd,
          &_url {
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            padding: 15px;
            min-height: 100px;

            p {
              font-size: 0.875rem;
              margin-top: 10px;
              text-align: center;

              a {
                color: #aaa;

                &:hover {
                  text-decoration: underline;
                }
              }
            }

            .form-input {
              margin-bottom: 0;
              width: 100%;
            }
          }
        }
      }

      .features {
        margin: 20px 0;
        overflow-y: auto;
        max-height: 214px;

        &__item {
          margin-bottom: 10px;

          &:last-child {
            margin-bottom: 0;
          }

          .checkbox {
            margin-right: 5px;

            label {
              display: flex;
              align-items: center;
              padding: 10px;
              border: 1px solid #DFE1E6;
              border-radius: 4px;
              cursor: pointer;
              user-select: none;
              height: auto;

              .checkbox__label {
                font-weight: 400;
                margin-top: 1px;
              }
            }
          }
        }
      }

      .btn-wrapper {
        display: flex;
        justify-content: flex-end;
      }
    }
  }
}