@use '../abstracts/variables_new.scss' as *;

.field-positions-v2 {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  position: relative;
  flex-wrap: wrap;
  padding-top: 10px;

  &__page {
    display: flex;
    align-items: center;
    height: 23px;
    font-size: 0.75rem;
    padding: 0 10px;
    border-radius: 4px;
    margin-right: 8px;
    background-color: $bg-color-1;
    text-transform: capitalize;
  }

  .input-v2 {
    margin-bottom: 0;
    flex: 0 1 auto;

    &.label-input {
      // width: 120px;
      margin-right: 13px;
    }

    &.checkbox-page {
      margin-right: 11px;

      input {
        width: 56px;
        height: 36px;
      }
    }
  }

  &__divider {
    height: 23px;
    width: 1px;
    background-color: $bg-color-4;
    margin-right: 8px;
  }

  .checkbox-wrapper {
    margin-bottom: 0;
    margin-right: 8px;
    
    &__field {
      margin-right: 8px;
    }
  }

  .button {

    &.label-btn {
      margin-right: 10px;
      height: 36px;
    }

    &:last-child {
      margin-left: auto;
    }
  }

  &__error {
    padding-top: 5px;
    font-size: 0.75rem;
    color: $highlighted-color-3;
    position: absolute;
    top: 100%;
    left: 0;
  }

  .checkbox-label {
    display: flex;
    align-items: center;

    .edit-btn-wrapper {
      margin-left: 4px;
      margin-right: 4px;
    }
  }

  .delete-btn,
  .edit-btn {
    width: 36px;
    height: 36px;
    transition: color 0.3s ease, background-color 0.3s ease;
    
    &:hover {
      color: $highlighted-color-3;
      background-color: rgba($highlighted-color-3, 0.1);
    }
  }

  .edit-btn {

    &:hover {
      background-color: rgba($primary-color, 0.1);
      color: $primary-color;
    }
  }

  &__remove {
    margin-left: auto;
  }

  @media (max-width: $break-md-max) {
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 20px;
    padding-bottom: 20px;
    border-bottom: 1px solid $border-color-1;

    &:last-child {
      margin-bottom: 0;
      padding-bottom: 0;
      border-bottom: 0;
    }

    &__page {
      margin-bottom: 13px;
    }

    &__divider {
      height: 1px;
      background-color: transparent;
      margin: 13px 0 0;
    }

    &__remove {
      position: absolute;
      top: 0;
      right: 0;
    }
  }
}