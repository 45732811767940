@use '../abstracts/variables_new.scss' as *;

.history-box-v2 {
  background-color: #fff;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.21);
  padding: 20px 30px;
  border-radius: 3px;
  margin-bottom: 20px;
  position: relative;
  z-index: 1;

  header {
    display: flex;

    @media (max-width: $break-md-max) {
      display: block;

      .history-box__time {
        margin-bottom: 20px;
      }

      .history-box__progress {
        margin-bottom: 20px;
      }
    }
  }

  &__time {
    width: 86px;
    padding-right: 10px;
    padding-top: 9px;

    @media (max-width: $break-md-max) {
      font-size: 0.9375rem;
    }
  }

  &__progress {

    p {

      &.main {
        font-size: 0.9375rem;
        margin-bottom: 4px;

        @media (max-width: $break-md-max) {
          font-size: 1rem;
        }
      }

      &.number-of-entries {
        font-weight: 500;

        @media (max-width: $break-md-max) {
          font-size: 0.9375rem;
        }
      }

      &.editable-status {
        font-weight: 500;

        @media (max-width: $break-md-max) {
          font-size: 0.9375rem;
        }

        &.success {
          color: #13aa5b !important;
        }

        &.error {
          color: $error-color !important;
        }
      }
    }

    .progress-bar {
      width: 190px;
      max-width: 100%;
      height: 4px;
      border-radius: 3px;
      position: relative;
      background-color: rgba($primary-color, 0.2);
      margin-bottom: 6px;

      div {
        background-color: $primary-color;
        position: absolute;
        left: 0;
        top: 0;
        height: 100%;
        border-radius: 3px;
      }
    }
  }

  &__actions {
    margin-left: auto;
    display: flex;
    align-items: center;

    .btn {
      margin-bottom: 4px;

      &:first-child {
        margin-right: 4px;
      }
    }
  }

  &__body {
    padding-top: 44px;
    padding-bottom: 20px;

    .group {
      display: flex;
      align-items: center;
      margin-bottom: 10px;
      min-width: 0;

      @media (max-width: $break-md-max) {
        display: block;
      }

      &:last-child {
        margin-bottom: 0;
      }

      p {
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;

        &.label {
          width: 220px;
          margin-right: 8px;

          @media (max-width: $break-md-max) {
            font-size: 0.9375rem;
          }
        }

        &.input {
          font-size: 0.9375rem;
          font-weight: 600;
          padding: 2px 0 1px;
          border-bottom: 1px solid $border-color-1;
          flex: 1;
          padding: 8px 0;

          @media (max-width: $break-md-max) {
            font-size: 1rem;
          }
        }
      }
    }
  }

  &--template {

    header {
      align-items: center;
    }

    .history-box-v2__time {
      padding-top: 0;
    }

    .history-box-v2__progress {

      p {
        margin-bottom: 0;
        white-space: pre-wrap;
      }
    }
  }
}