@use '../abstracts/variables_new.scss' as *;

.settings-profile-parent {
  display: flex;
  gap: 24px;
  max-width: 1426px;
  flex-wrap: wrap;

  &.show-preview {
    max-width: calc(100% - 620px);

    .settings-profile__box.small {
      @media (max-width: 2580px) {
        max-width: 806px;
      }

    }
  }

  @media (max-width: $break-xxl-max) {
    flex-wrap: wrap;
  }

  @media (max-width: $break-xl-max) {
    flex-direction: column;
  }
}

.info-box {
  background: $bg-purple2;
  padding: 16px;
  border-radius: 8px;
  color: $text-purple;
  display: flex;
  gap: 6px;
  font-size: 14px;
  font-weight: 500;
  width: fit-content;
  font-weight: 600;
}

.agency-select {
  margin: 24px 0 0 24px;
  display: flex;

  .select-v2 {
    height: 60px;

    .select-v2__body_selected {
      border-radius: 30px;
      // max-height: 60px;
      // height: 60px;
    }
  }
}

.settings-profile {
  padding: 16px 24px 50px 24px;

  &.cover {
    padding: 16px 104px 50px 24px;

  }

  &.medium {
    .settings-profile__box {
      max-width: 806px;
    }
  }



  @media (max-width: $break-md-max) {
    padding: 0 0 80px !important;
  }

  &__box {
    border-radius: 8px;
    border: 1px solid $bg-hover;
    max-width: 747px;

    &.medium {
      max-width: 766px;

      &.show-preview {
        max-width: 806px;
      }
    }

    &.small {
      max-width: 636px;
      width: 100%;
      height: fit-content;

      @media (max-width: 1919px) {
        max-width: 766px;
      }
    }

    .head {
      background-color: $bg-purple2;
      padding: 8px 32px 8px 24px;
      height: 76px;
      display: flex;
      flex-direction: column;
      justify-content: center;

      span {
        color: $text-color-1;
        font-size: 14px;
        font-weight: 600;
        line-height: 20px;
      }
    }

    .body {
      padding: 24px 24px;

    }

    @media (max-width: $break-md-max) {
      border-radius: 0;
      box-shadow: none;
      max-width: 100%;
      background: #fff;
    }

    h3 {
      font-size: 18px;
      font-weight: 600;
      line-height: 24px;
      color: $text-color-1;
    }

    .inner-heading {
      font-size: 16px;
      color: $color-indigo;
      margin-bottom: 4px;
      font-weight: 700;
    }

    .setting-cover-page-text {
      color: $text-color-1;
      font-size: 12px;
      font-weight: 500;
      line-height: 15px;
      display: block;
    }

    .fields {
      display: flex;
      flex-wrap: wrap;

      .input-v2 {

        &.disabled {

          input {
            background-color: #fff !important;
          }
        }

      }
    }
  }

  .hr {
    height: 1px;
    border-bottom: 1px solid $bg-hover;
    margin: 24px 0;
  }

  .agency-section {
    padding: 16px 24px;
    margin-bottom: 20px;
    border-radius: 8px;
    border: 1px solid $bg-hover;
    background: $bg-purple1;

    &:last-child {
      margin-bottom: 0;
    }

    @media (max-width: $break-md-max) {
      margin-bottom: 30px;
    }

    h3 {
      //styleName: Header/H3;
      font-size: 18px;
      font-weight: 600;
      line-height: 24px;
      color: $text-color-1;
      margin-bottom: 8px !important;
      min-height: 40px;
      padding: 8px 0px;
    }

    .separator-line {
      background: $bg-hover;
      height: 1px;
      width: 100%;
    }

    .agency-fields-wrapper-v2 {
      display: flex;
      flex-wrap: wrap;
      margin: 0 -8px;

      .agency-field {
        padding: 0 8px;
        display: flex;
        align-items: flex-end;



        .date-input {
          &.disabled {
            svg {
              fill: #E0E0E0;
            }
          }

          &__body {
            position: relative;

            .react-datepicker-wrapper {
              width: 100%;
              min-width: initial;
            }

            &.clearable {
              padding-right: 0;
            }

            input {
              background: #fff;
              border-radius: 4px;
              border: 2px solid $bg-hover;

              &:disabled {
                background: #fff !important;
                color: rgba($color: $text-purple, $alpha: 0.4) !important;
              }
            }
          }
        }

        @media (max-width: $break-md-max) {
          width: 100%;
        }

        .input-v2 {
          width: 100%;

          &.disabled {

            input {
              background-color: #fff !important;
            }
          }
        }

        // .date-input {
        //   margin-bottom: 15px;
        // }
      }
    }
  }

  &__tabs {
    display: flex;
    align-items: center;
    color: #E8025F;
    font-size: 14px;
    font-weight: 600;
    line-height: 21px;
    text-align: center;
    border: 2px solid $bg-selected;
    height: 40px;
    border-radius: 4px;

    div {
      border: 1px solid $bg-selected;
      border-width: 0 1px;
      cursor: pointer;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .active {
      font-weight: 500;
      line-height: 20px;
      background: $bg-selected;
    }
  }

  .dnd-wrapper {

    padding-bottom: 24px;
    border-bottom: 1px solid $bg-hover;
    margin-bottom: 24px;

    .file-upload-dnd {
      display: flex;
      align-items: center;
      background: $bg-purple2;
      border: 1px solid $bg-hover;
      padding: 16px;
      border-radius: 8px;

      &.disabled {
        opacity: 0.5;
        // filter: grayscale(1);
        cursor: not-allowed;
        pointer-events: none;
      }

      @media (max-width: $break-md-max) {
        width: 100%;
        flex-direction: column;
      }

      &__box {

        button {
          max-width: 197px;

          @media (max-width: $break-md-max) {
            max-width: 239px;
          }
        }
      }
    }
  }
}