@use "../abstracts/variables_new.scss" as *;
@use "../abstracts/mixins.scss" as *;

.registered-mail-sent {
  height: 100%;
}

.registered-mail__box {
  overflow: auto;
  @include hideScrollbar();
}

.registered-mail-table {

  @media screen and (max-width: $break-lg-max) {
    min-width: 1050px;
  }

  .registered-mail-table-row {
    display: flex;
    justify-content: space-between;
    padding: 0 16px;
    min-height: 56px;
    gap: 5px;
  }

  .download-btn {
    svg {
      width: 18px;
    }
  }

  &.sender {
    .registered-mail-table-column {
      &:nth-child(1) {
        width: 290px;
        // flex: 0 0 290px;
        max-width: 19.24%;
      }

      &:nth-child(2) {
        width: 151px;
        // flex: 0 0 151px;
        max-width: 10%;
      }

      &:nth-child(3) {
        width: 184px;
        // flex: 0 0 184px;
        max-width: 12.2%;
      }

      &:nth-child(4) {
        width: 187px;
        // flex: 0 0 187px;
        max-width: 12.4%;
      }

      &:nth-child(5) {
        width: 230px;
        // flex: 0 0230px;
        max-width: 15.26%;

      }

      &:nth-child(6) {
        // width: 250px;
        // flex: 0 0 250px;
        max-width: 16.58%;

      }

      &:nth-child(7) {
        width: 150px;
        // flex: 0 0 150px;
        max-width: 9.9%;

      }
    }
  }

  .registered-mail-table-column {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    user-select: none;
    width: 100%;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;

    &:nth-child(1) {
      min-width: 116px;
      // flex: 0 0 139px;
      max-width: 9.2%;

    }

    &:nth-child(2) {
      // width: 190px;
      // flex: 0 0 190px;
      max-width: 12.6%;
    }

    &:nth-child(3) {
      // width: 242px;
      // flex: 0 0 242px;
      max-width: 16%;
    }

    &:nth-child(4) {
      // width: 162px;
      // flex: 0 0 162px;
      max-width: 10.7%;
    }

    &:nth-child(5) {
      // width: 262px;
      // flex: 0 0 262px;
      max-width: 17.38%;
    }

    &:nth-child(6) {
      // width: 190px;
      // flex: 0 0 190px;
      max-width: 12.6%;
    }

    &:nth-child(7) {
      // width: 113px;
      // flex: 0 0 113px;
      max-width: 7.4%;
    }

    &:nth-child(8) {
      min-width: 145px;
      // flex: 0 0 173px;
      max-width: 11.47%;
    }


    &.made-by-column {
      p {
        gap: 8px;
        line-height: 20px;
        color: $text-color-1;
        height: 20px;
      }

      svg {
        margin-right: 8px;
        width: 16px;
      }

      .agency-name {
        font-size: 12px;
        line-height: 16px;
        height: 16px;
        color: $text-purple;
        text-align: left;
        display: block;
        width: 100%;
      }
    }



    &:nth-child(1) {
      text-align: left;
      justify-content: flex-start;
    }

    // &:nth-child(2) {
    //   width: 12%;
    //   justify-content: flex-start;
    // }

    // &:nth-child(3) {
    //   width: 12%;
    // }

    // &:nth-child(4) {
    //   width: 12%;
    // }

    // &:nth-child(5) {
    //   width: 12%;
    // }

    &__check {
      width: 15px;
      height: 15px;
      border-radius: 4px;
      border: 1px solid $primary-color;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;

      @media (max-width: $break-md-max) {
        width: 19px;
        height: 19px;
      }

      svg {
        width: 9px;

        @media (max-width: $break-md-max) {
          width: 11px;
        }
      }

      &.checked {
        background-color: $primary-color;
      }
    }
  }

  &__head {
    background: $primary-color;
    border-radius: 8px 8px 0 0;

    .registered-mail-table-column {

      cursor: pointer;

      &:nth-child(1) {
        text-align: left;
        justify-content: flex-start;
      }

      // &:nth-child(1) {
      //   padding-bottom: 3px;

      //   .svg-icon {
      //     margin-left: 0px;
      //   }
      // }

      // &:nth-child(5) {
      //   .svg-icon {
      //     align-self: flex-end;
      //     padding-bottom: 3px;
      //   }
      // }

      p {
        font-weight: 600;
        font-size: 14px;
        color: $color-indigo;
        line-height: 16px;
        text-overflow: ellipsis;
        overflow: hidden;
      }
    }
  }

  &__body {
    padding-bottom: 20px;

    .registered-mail-table-row {
      &:nth-child(odd) {
        background-color: #fff;
      }

      &:nth-child(even) {
        background-color: $bg-purple2;
      }

      align-items: center;
      position: relative;
      cursor: pointer;

      @media (max-width: $break-md-max) {
        height: auto;
      }

      &.selected {
        background-color: $bg-selected;
      }

      &:hover {
        background-color: $bg-hover;
      }
    }

    .registered-mail-table-column {
      min-width: 0;

      p {
        font-weight: 600;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        width: 100%;
        color: $text-color-1;

        span.status {
          padding: 4px 8px !important;
          display: inline-block;
          border-radius: 50px;
          padding: 0;
        }

        span.status--ready {
          background-color: $highlighted-color-2;
          color: #fff;
        }

        span.status--draft {
          background-color: $bg-color-4;
        }

        span.status--pending {
          background-color: $highlighted-color-4;
          color: $text-color-1;
        }

        span.status--success {
          background-color: $highlighted-color-2;
          color: #fff;
        }

        span.status--error {
          background-color: #FF9A1C;
          color: $color-indigo;
        }
      }

    }

    .registered-mail-table-column-mobile {
      display: none;
      align-items: center;
      font-size: 1rem;
      line-height: 1.3125;

      @media (max-width: $break-md-max) {
        display: flex;
      }

      .date {
        margin-right: 25px;
      }

      .folder {
        display: flex;
        align-items: center;

        svg {
          display: block;
          width: 19px;
          margin-right: 8px;
          fill: $primary-color;
        }
      }
    }

    .go-to-document {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 1;
    }
  }

  .folder-row-icon {
    svg {
      width: 16px;
    }
  }
}