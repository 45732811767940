@use '../abstracts/variables_new.scss' as *;
@use "../abstracts/mixins.scss" as *;

.dashboard-content {
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 16px 24px 20px;
  max-height: 100%;
  overflow-x: auto;
  overflow-y: hidden;

  @media (max-width: $break-md-max) {
    padding: 0px;
    overflow: scroll;
  }

  &__stats {
    // margin-bottom: 2rem;

    @media (max-width: $break-md-max) {
      display: block;
      background: #fff;
      border-radius: 16px 16px 0 0;
      margin-bottom: 0rem;

      &:not(:first-child) {
        margin-top: 16px;
      }

    }

    div {
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-size: 24px;
      font-weight: 600;
      line-height: 32px;
      text-align: left;
      color: $color-indigo;
      border-bottom: 1px solid $border-color-1;
      padding: 0 16px;
      height: 65px;
      border-radius: 12px 12px 0px 0px;

      span {
        height: 24px;
        padding: 0px 8px;
        display: flex;
        align-items: center;
        border-radius: 50px;
        background: #E8025F;
        font-size: 12px;
        font-weight: 500;
        line-height: 16px;
        text-align: left;
        color: #fff;
        white-space: nowrap;
      }

      @media (max-width: $break-md-max) {
        height: 72px;
        font-size: 18px;
        line-height: 16px;
        flex: 0 0 100% !important;
        margin-bottom: 24px;
      }
    }

    @media (max-width: $break-xl-max) {
      display: flex;

      div {
        flex: 0 0 100%;
      }
    }
  }

  &__actions {

    @media (max-width: $break-md-max) {
      width: 100%;
      z-index: 10;
    }
  }

  &__boxes-wrapper {
    height: 100%;
    position: relative;
    overflow: auto;

    @media (max-width: $break-md-max) {
      margin-left: 0;
      padding: 0;
      overflow: visible;
      max-height: none;
    }


  }

  &__boxes {
    // position: absolute;
    // top: 110px;
    // left: 0px;
    // width: 100%;
    display: grid;
    grid-template-columns: repeat(3, minmax(0, 1fr));
    gap: 24px;

    .column-parent {
      .container-element {
        gap: 16px;
        display: grid;
        grid-template-columns: repeat(2, minmax(0, 1fr));
        background-color: #fff;

        @media (max-width: $break-md-max) {
          gap: 24px;
          display: flex;
          flex-direction: column;
          padding-bottom: 16px;
          border-radius: 0 0 12px 12px;
        }

      }
    }

    @media (max-width: 1284px) {
      display: flex;

      .column-parent {
        flex: 0 0 100%;
        max-width: 704px;
      }
    }

    @media (max-width: $break-lg-max) {
      .column-parent {
        max-width: 100%;
      }
    }

    .column {
      height: calc(100vh - 212px);
      overflow: hidden;
      padding-top: 2rem;

      >div {
        overflow: hidden;
        height: 100%;

        &.half-height {
          max-height: 50%;
        }
      }

      &.less-height {
        height: calc(100vh - 225px);
      }

      @include hideScrollbar();

      &:nth-child(3n) {
        margin-right: 8px;
      }

      &:last-child {
        margin-right: 0px;
      }

      // width: 16.66%;
      // flex: 0 0 16.66%;
      @media (max-width: $break-md-max) {
        height: initial !important;
        margin-right: 0 !important;
        padding-top: 0;
        gap: 24px;
        display: flex;
        flex-direction: column;
      }
    }

    @media (max-width: $break-md-max) {
      flex-direction: column;

      .column-parent {
        flex: initial;

      }
    }
  }
}