@use '../abstracts/variables_new.scss' as *;

.agency-box-v2 {
  box-shadow: 0 0 10px rgba(0,0,0,0.1);
  border-radius: 10px;
  margin-bottom: 40px;
  display: flex;
  flex-direction: column;

  &__head {
    background: rgb(244,102,111);
    background: linear-gradient(90deg, rgba(244,102,111,1) 0%, rgba(230,177,105,1) 100%);
    padding: 20px;
    border-radius: 10px 10px 0 0;
    position: relative;
    display: flex;
    justify-content: flex-end;

    h3 {
      color: #fff;
      font-weight: 300;

      span {
        display: block;
      }
    }

    @media (max-width: $break-xs-max) {

    }
  }

  &__body {
    padding: 20px;
    border-radius: 0 0 10px 10px;
    background-color: #fff;
    flex: 1;

    p {
      margin-bottom: 5px;
      font-size: 0.75rem;

      strong {
        font-weight: 600;
      }

      &:last-child {
        margin-bottom: 0;
      }

      @media (max-width: $break-md-max) {
        font-size: 0.9375rem;
        margin-bottom: 8px;
      }
    }
  }
}