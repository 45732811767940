@use '../abstracts/variables_new.scss' as *;

.template-folder-box {
  border-bottom: 0.5px solid $border-color-1;

  &__head {
    padding: 17px 24px;

    p {
      display: flex;
      align-items: center;
      font-size: 1.1875rem;
      line-height: 1.32;

      svg {
        width: 29px;
        margin-right: 13px;
        min-width: 29px;
      }
    }
  }

  &__body {
    padding: 0 16px;
  }
}