@use '../abstracts/variables' as *;

.my-profile {
  display: flex;
  height: 100%;

  &__left {
    width: 229px;
    position: fixed;
    top: 0;
    left: 52px;
    height: 100%;
    overflow-y: auto;
    transition: left .3s ease;

    &_overlay {
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba($secondary-bg-color, 0.5);
      display: none;
    }

    @media (max-width: $break-md-max) {
      position: fixed;
      left: -229px;
      top: 0;
      height: 100%;
      z-index: 1004;

      &.opened {
        left: 0;

        .my-profile__left_overlay {
          display: block;
        }
      }
    }
  }

  &__right {
    flex: 1;
    padding-left: 229px;
    transition: padding-left .3s ease;

    @media (max-width: $break-md-max) {
      padding-left: 0;
    }
    
    &_head {
      display: flex;
      align-items: center;
      background-color: $primary-light-color;
      box-shadow: 0 2px 4px 0 rgba($shadow-color, 0.5);
      padding: 15px 18px 15px 34px;
      position: fixed;
      top: 0;
      left: 281px;
      width: calc(100% - 281px);
      z-index: 1;
      transition: left .3s ease, width .3s ease;

      @media (max-width: $break-md-max) {
        padding-left: 15px;
        width: calc(100% - 52px);
        left: 52px;
      }

      h1 {
        font-size: 1.25rem;
        font-weight: 700;
        color: $secondary-text-color;
        display: flex;
        align-items: center;
        margin-right: auto;

        span {
          display: none;
          margin-right: 10px;

          svg {
            display: block;
          }

          @media (max-width: $break-md-max) {
            display: block;
          }
        }
      }

      .support-menu-btn {
        width: 36px;
        margin-right: 8px;
        color: $nonary-text-color;
        background-color: rgba(#EBECF0, 0.35);
    
        &:hover {
          background-color: darken(rgba(#EBECF0, 0.35), 5);
        }
      }

      .username {
        width: 24px;
        height: 24px;
        border-radius: 50%;
        background-color: $senary-bg-color;
        display: flex;
        align-items: center;
        justify-content: center;
        
        svg {
          font-size: 1.125rem;
          color: $primary-light-color;
        }
      }
    }

    &_body {
      padding: 98px 34px 44px;

      @media (max-width: $break-md-max) {
        padding: 98px 15px 44px;
      }

      h3 {
        font-weight: 400;
        color: $secondary-text-color;
        margin-bottom: 13px;
      }

      .form {
        // max-width: 448px;

        .form-input {
          min-width: 320px;

          @media (max-width: $break-xs-max) {
            min-width: 1px;
            width: 100%;
            margin-right: 0;
          }
        }

        .input-wrapper {

          @media (max-width: $break-xs-max) {
            width: 100%;
          }
        }

        h3 {

          &.space-above {
            margin-top: 60px;
          }
        }

        .button {

          &.w-140 {
            min-width: 140px;
          }
        }

        &__actions {
          justify-content: flex-start;

          .button {

            &:first-child {
              margin-left: 0;
            }

            &:last-child {
              margin-right: 0;
            }
          }
        }
      }
    }
  } 

  .radio-container {
    padding-top: 10px;
    padding-left: 2px;
  }

  .radio-title {
    font-size: 1.05rem;
    font-weight: 500;
  }

  .form-radio__choices_label {
    margin-top: 7px;
  }
  
  .form-radio__choices_label p {
    font-size: 1rem;
    font-weight: normal;
  }

  .form-radio__choices_btn::after {
    background-color: $primary-color;
  }
}

.cover-page {

  .preview-container {
    display: block;
  }

  .preview-loading-container {
    width: 300px;
    height: 388px;
    background-color: white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    box-shadow: 2px 2px 8px 0px rgba(0,0,0,0.1);
  }

  .agency-image-container {
    width: 50%;
  }
  
  .agency-image {
    max-height: 400px;
    max-width: 100%;
    margin-top: 15px;
    margin-bottom: 15px;
  }

  .color-select .MuiSelect-selectMenu {
    flex-direction: row;
    display: flex;
    margin-top: 10px;
  }

  .MuiMenuItem-root {
    flex-direction: row;
    display: flex;
  }

  .row {
    display: flex;
    flex-direction: row;
  }

  .row.c-gap-50 {
    column-gap: 50px;
  }
  .row.c-gap-10 {
    column-gap: 10px;
  }

  .row.v-centered {
    align-items: center;
  }

  .row.spacearound {
    justify-content: space-around;
  }
}

.color-option-box {
  width: 20px;
  height: 20px;
}

.color-option-label {
  margin-left: 10px;
}