@use '../abstracts/variables_new.scss' as *;
@use '../abstracts/mixins.scss' as *;

.cover-page-preview {
  position: fixed;
  top: 100px;
  right: -653px;
  width: 100%;
  max-width: 34%;
  height: calc(100% - 100px - 24px);
  border-radius: 8px 8px 0 0;
  background-color: $primary-color;
  transition: right 0.3s ease;
  z-index: 21;
  display: none;

  @media (min-width: $break-md-min) and (max-width: $break-xl-max) {
    position: fixed;
    top: 50%;
    right: 50% !important;
    width: 80%;
    height: 90%;
    transform: translate(50%, -50%);
    background: #fff;
    border-radius: 10px;
    display: none;
    z-index: 100;
    width: 693px;
    max-width: 90%;

    &::before {
      content: "";
      position: fixed;
      left: 0;
      top: 0;
      right: 0;
      background: rgba($bg-color-1, 0.40);
      width: 200%;
      height: 200%;
      transform: translate(-25%, -25%);
      z-index: -1;
    }

  }

  @media (max-width: $break-md-max) {
    position: fixed;
    top: 78px;
    right: -100%;
    width: 100%;
    max-width: 450px;
    height: calc(100% - 64px);
    z-index: 2;
    background: #fff;
    border-radius: 50px 50px 0 0;
    padding-top: 20px;
    box-shadow: 0px 1px 8px 0px #00000026;
    display: none;

    &.active {
      display: block;
      right: 0 !important;
    }
  }

  &__head {
    height: 76px;
    padding: 8px 16px 8px 24px;
    display: flex;
    align-items: center;
    position: sticky;
    top: 0;
    background: $primary-color;
    z-index: 100;

    @media (max-width: $break-xl-max) {
      background: $primary-color;

      .fold-btn {
        display: none;
      }

      .fold-btn-mobile {
        display: flex;
      }
    }

    @media (max-width: $break-md-max) {
      background: none;
      height: initial;
      padding: 22px 24px;
      display: block;

      .refresh-btn-mobile {
        margin-top: 16px;
        display: flex;
        width: 100%;
      }

      .refresh-btn {
        display: none;
      }

      span.svg-icon {
        svg {
          width: 18px !important;
        }
      }
    }

    h2 {
      display: flex;
      align-items: center;
      font-size: 24px;
      font-weight: 600;
      line-height: 32px;
      color: $color-indigo;
      justify-content: space-between;
      width: 100%;

      span.text {
        display: inline-block;
        padding-bottom: 1px;
        color: $color-indigo;
      }

      @media (max-width: $break-lg-max) {
        font-size: 18px;
        color: $text-color-1;
      }

      @media (max-width: $break-md-max) {
        width: initial;
      }

      span.svg-icon {
        cursor: pointer;

        svg {
          width: 11px;
        }
      }
    }
  }

  &__inner {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow-y: auto;
    border-radius: 12px;
    @include hideScrollbar();

    @media (max-width: $break-md-max) {
      padding: 20px 0;
    }
  }

  &__body {
    padding: 0 24px 24px;
    text-align: center;

    @media (max-width: $break-xl-max) {
      padding: 0 24px 24px;
      background: $primary-color;
      overflow: auto;
      height: calc(100% - 76px);
      margin-top: -1px;
    }

    @media (max-width: $break-md-max) {
      padding: 0;
      background: #fff;

      &_loader-wrapper {
        padding: 15px;
      }
    }

    .react-pdf__Page {
      border-radius: 8px;
      overflow: hidden;
    }

    .react-pdf__Document canvas {
      width: 100% !important;
      height: auto !important;
    }

    .react-pdf__Page__textContent {
      display: none;
    }

    &_close {
      display: none;
      align-items: center;
      font-size: 1.125rem;
      margin-bottom: 20px;

      .svg-icon {
        margin-right: 10px;

        svg {
          width: 14px;
        }
      }

      @media (max-width: $break-md-max) {
        display: flex;
      }
    }
  }

  &.opened {
    right: 24px;
    display: flex;

    @media (max-width: $break-md-max) {
      right: 0px;
    }

    .cover-page-preview__toggle {
      transform: translateX(-50%) rotate(0deg);
      left: 0;
    }
  }
}