@use '../abstracts/variables_new.scss' as *;
@use '../abstracts/mixins' as *;


.dashboard-content-box {
  // width: 269px;
  // min-width: 269px;
  // max-height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  height: 100%;

  &__head {
    margin-bottom: 12px;
    position: relative;
    gap: 4px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    >div {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;

      @media (min-width: 1284px) and (max-width: 1711px) {
        height: 48px;
        align-items: flex-start;

      }
    }

    @media (max-width: $break-md-max) {
      padding: 0px 16px;
      margin-bottom: 0;
      background: #fff;
      gap: 8px;
    }

    .title {
      cursor: pointer;
      padding: 0 0 0 8px;
    }

    h2 {
      font-size: 18px;
      font-weight: 600;
      line-height: 24px;
      color: $text-color-1;
      display: flex;
      align-items: center;
      cursor: pointer;
      overflow: hidden;

      span {
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        overflow: hidden;
        word-break: break-word;
      }



      @media (max-width: $break-md-max) {
        font-size: 14px;
        font-weight: 600;
      }
    }

    .circle {
      width: 16px;
      height: 16px;
      border-radius: 50px;
      display: inline-block;
      background: #E0E0E0;
      margin-right: 8px;

      &.green {
        background: $highlighted-color-2;
      }
    }

    .number {
      font-size: 16px;
      font-weight: 700;
      line-height: 24px;
      color: $text-purple;
      padding: 0 8px;

      @media (max-width: $break-md-max) {
        font-size: 14px;
        font-weight: 600;
        line-height: 16px;
      }
    }

    .toggle-icon {
      cursor: pointer;
      width: 24px;
      height: 24px;
      display: none;
      align-items: center;
      justify-content: center;

      &.opened {
        svg {
          transform: rotate(180deg);
        }
      }

      @media (max-width: $break-md-max) {
        display: flex;
      }
    }
  }

  &__filter-row {
    display: flex;
    // flex-wrap: wrap;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    background: $bg-purple2;
    padding: 16px 8px;
    margin-bottom: 3px;
    border-radius: 8px 8px 0 0;
    gap: 8px;

    .checkbox-v2__label {
      @media (min-width: 1200px) and (max-width: 1700px) {
        display: none;
      }
    }

    div {
      color: $color-indigo !important;
    }

    &--mobile {
      display: none;

      @media (max-width: $break-md-max) {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        padding: 15px 15px 15px 2px;
        background: $bg-purple2;
        margin: 0 16px 3px;
      }
    }

    @media (max-width: $break-md-max) {
      padding: 0 25px;
      display: none;
    }
  }

  &__sort-button {
    cursor: pointer;
    background: #fff;
    border-radius: 4px;
    border: 2px solid $bg-hover;
    width: 56px;
    flex: 0 0 56px;
    height: 32px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin-left: auto;

    .up {
      width: 8px;
      display: inline-block;
      transform: rotate(90deg);
    }

    .down {
      width: 8px;
      display: inline-block;
      transform: rotate(-90deg);

    }
  }

  &__check-all {
    padding: 4px 8px;
    user-select: none;
    max-width: 168px;
  }

  &__body {
    background: $bg-purple2;
    border-radius: 5px;
    padding: 16px 8px;
    overflow: scroll;
    @include hideScrollbar();

    @media (max-width: $break-md-max) {
      overflow: visible;
      margin: 0 16px;
      display: none;
    }
  }

  .no-document {
    //styleName: Header/H4;
    font-size: 14px;
    font-weight: 600;
    line-height: 16px;
    color: $text-purple;
    padding: 44px 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background: $bg-purple2;
    border-radius: 8px;

    @media (max-width: $break-md-max) {
      display: none;
    }
  }


  &.opened {
    @media (max-width: $break-md-max) {
      padding: 8px 0;
      background: #fff;
    }

    .dashboard-content-box__head {

      @media (max-width: $break-md-max) {
        padding-top: 0;
        padding-bottom: 0;
        margin-bottom: 24px;
      }

      .toggle-icon {
        transform: translateY(-50%) rotate(180deg);
      }
    }

    .dashboard-content-box__body {

      @media (max-width: $break-md-max) {
        display: block;
      }
    }
  }
}