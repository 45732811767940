@use '../abstracts/variables_new.scss' as *;

.date-input {
  @media (min-width: $break-md-max) {
    &.expandable {
      border-radius: 20px;
      width: 40px;
      background: $bg-default;

      &:hover {
        background: $color-indigo;

      }

      .date-input__body {
        // display: flex;

        .calendar-icon {
          display: flex;
          flex: 0 0 40px;
          height: 40px;
          width: 40px;
          justify-content: center;
          align-items: center;
          float: left;
          .svg-icon {
            position: absolute;
            top: 50%;
            left: 11px;
            transform: translateY(-50%);
          }
        }
      }

      .react-datepicker-wrapper {
        display: inline-block;
        max-width: initial;
      }

      &.shrinked {
        .react-datepicker-wrapper {
          display: none;
        }
      }

      input {
        position: relative;
        opacity: 0;
        padding: 3px 12px 2px 0px !important;
        width: 40px !important;
        cursor: pointer;
      }

      &.expanded {
        width: max-content;

        input {
          opacity: 1;
          width: 100% !important;
        }
      }

      &:hover {
        .clear-date-btn {
          svg {
            fill: #E8025F !important;
          }
        }
      }
    }
  }


  &__label {
    color: $color-indigo;
    font-weight: 600;
    font-size: 12px;
    margin-bottom: 5px;

    @media (max-width: $break-md-max) {
      font-size: 0.9375rem;
    }
  }

  &__body {
    position: relative;
    height: 40px;

    &:hover {
      input {
        background: $color-indigo !important;
        color: #fff !important;
      }

      svg {
        fill: #fff !important;
        cursor: pointer;
      }

      .clear-date-btn svg {
        fill: #fff !important;
      }
    }

    .svg-icon {
      position: absolute;
      top: 50%;
      left: 11px;
      transform: translateY(-50%);
      z-index: 1;
      width: 18px;
      display: flex;
      justify-content: center;

      svg {
        width: 13.5px;
      }
    }

    .react-datepicker-wrapper {
      display: block;
      min-width: 204px;

      @media (max-width: $break-md-max) {
        min-width: initial;
      }

      input {
        display: block;
        width: 100%;
        border-radius: 20px;
        padding: 3px 20px 2px 38px;
        line-height: 21px;
        font-weight: 600;
        font-size: 14px;
        background-color: transparent;
        color: $color-indigo;
        outline: 0;
        border: 0;
        background: $bg-default;
        height: 40px;

        &::placeholder {
          color: $text-placeholder;
        }

        @media (max-width: $break-md-max) {
          font-size: 11px;
          padding: 4px 12px 4px 29px;
          border-radius: 15px;
        }


      }
    }

    &.clearable {
      padding-right: 0px;

      button.clear-date-btn {
        position: absolute;
        right: 8px;
        top: 50%;
        transform: translateY(-50%);
        background-color: transparent;
        border: 0;
        outline: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 16px;
        height: 16px;
        color: $primary-color;
        padding: 0;
        cursor: pointer;

        .svg-icon {
          position: static;
          transform: translateY(0);
        }

        svg {
          fill: $color-indigo;
          width: 16px;
        }
      }
    }
  }
}