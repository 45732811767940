@use '../abstracts/variables' as *;

.dashboard-page {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  height: 100%;

  &__head {
    display: flex;
    align-items: center;
    padding: 13px 34px 10px 34px;
    box-shadow: 0 2px 4px 0 rgba($shadow-color, 0.5);
    position: relative;
    
    h1 {
      font-size: 1.25rem;
      font-weight: 700;
      line-height: 1.2;
    }

    .line-loader {
      top: 100%;
    }

    .search-wrap {
      width: calc(100% - 24px);
      left: 12px;
    }

    @media (max-width: $break-sm-max) {
      padding-left: 12px;
      padding-right: 12px;
    }
  }
  
  &__body {
    background-color: $primary-bg-color;
    display: flex;
    flex: 1;
    max-width: 100%;
    height: 100%;

    @media (max-width: $break-sm-max) {
      flex-direction: column;
    }

    &.filter {
      flex-direction: column;
      padding: 0;

      .dashboard-view {
        flex: 1;
      }
    }
    
    h5 {
      font-size: 0.875rem;
      font-weight: 400;
      color: $nonary-text-color;
      margin-bottom: 20px;
    }

    &_left {
      flex: 1;
      padding: 30px 19px 30px 0;
    }

    &_right {
      width: 298px;
      padding: 30px 0 30px 19px;
      border-left: 1px solid rgba($quaternary-border-color, 0.42);

      @media (max-width: $break-sm-max) {
        border-left: 0;
        border-top: 1px solid rgba($quaternary-border-color, 0.42);
        width: 100%;
        padding-left: 0;
      }
    }

    .dashboard-view {

      &__head {

        &_top,
        &_bottom {
          padding-left: 34px;
          padding-right: 34px;
        }

        &_top {

          h4 span {
            display: none;
          }

          @media (max-width: $break-sm-max) {
            padding-left: 12px;
            padding-right: 12px;
            
            .search-wrap {
              width: calc(100% - 24px);
              left: 12px;
            }
          }
        }
      }

      &__body {

        .dashboard-row {
          padding-left: 34px;
          padding-right: 34px;

          @media (max-width: $break-md-max) {
            .more {
              right: 30px;
            }
          }

          @media (max-width: $break-sm-max) {
            padding-left: 12px;
            padding-right: 12px;

            .more {
              right: 15px;
            }
          }
        }
      }
    }

    &--help {
      padding: 30px;

      iframe {
        width: 100%;
        height: 533px;
        border: 1px solid #ccc;
      }
    }
  }
}