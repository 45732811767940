@use '../abstracts/variables_new.scss' as *;
@use "../abstracts/mixins.scss" as *;


html,
body,
#root,
.app-wrapper {
  height: 100%;
  @include hideScrollbar();

  @media (max-width: $break-md-max) {
    height: initial;
  }
}

html {
  text-rendering: optimizeLegibility !important;
  -webkit-font-smoothing: antialiased !important;
  font-size: 16px;

  scrollbar-width: none;
  scrollbar-color: transparent transparent;

  &::-webkit-scrollbar {
    width: 0px;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background-color: transparent;
  }
}

.react-datepicker__triangle {
  left: 50px !important;
}

.app-wrapper {
  min-width: 320px;
  font-size: 0.875rem;

  &.height-auto {
    height: auto;
    display: none;
  }
}

.svg-icon {
  display: block;

  svg {
    display: block;
    fill: $color-indigo;
  }
}

.rdw-editor-toolbar {
  background: $bg-purple1;
  border: 0;
  border-radius: 4px;
  padding: 7px 5px;
}

.rdw-option-wrapper {
  background: $bg-purple1;
  border: 0;

  padding: 0;

  &:hover {
    background: $bg-hover;
    border: 0 !important;
    box-shadow: none !important;
  }

  &.rdw-option-active {
    background: $bg-selected;
    border: 0 !important;
    box-shadow: none !important;
  }
}

.rdw-history-wrapper,
.rdw-inline-wrapper,
.rdw-list-wrapper,
.rdw-text-align-wrapper {
  &::after {
    content: "";
    display: inline-block;
    width: 2px;
    height: 16px;
    background: #ECE8F7;
    border: 0;
    margin: 0 5px;
  }
}

.public-DraftEditor-content {
  border-radius: 8px;
  border-color: $bg-hover;

  color: #000;
  font-size: 12px;
  line-height: 16px;
  font-weight: 500;
  padding: 15px;
  box-sizing: content-box;
}

.rdw-block-dropdown,
.rdw-fontsize-dropdown,
.rdw-fontfamily-dropdown {
  background-color: transparent;
  border: none;
  box-shadow: none;
  &:hover {
    background-color: transparent;
    border: none;
    box-shadow: none;
  }
}

.rdw-dropdown-selectedtext {
  color: $color-indigo;
  font-weight: 600;
  font-size: 14px;
}