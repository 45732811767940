@use '../abstracts/variables_new.scss' as *;

.support-page {
  padding: 12px 0;
  flex: 1;
  background-color: #fff;
  height: 100%;

  &__container {
    margin: 0 auto;
    padding: 0 15px;
    height: 100%;
  }

  &__head {
    text-align: center;
    padding: 50px 0;
    border-bottom: 1px solid #EBECF0;
    margin-bottom: 50px;

    h1 {
      color: #5D6C85;
    }
  }

  &__body {
    height: 100%;

    &--flex {
      display: flex;
      flex-wrap: wrap;

      >aside {
        width: 200px;
        margin-right: 40px;

        @media (max-width: $break-md-max) {
          width: 100%;
          margin-bottom: 40px;
          margin-right: 0;
        }

        h4 {
          text-transform: uppercase;
          font-size: 0.875rem;
          font-weight: 700;
          letter-spacing: 1px;
          margin-bottom: 20px;
          cursor: pointer;
        }

        ul {

          li {
            font-size: 0.875rem;
            margin-bottom: 5px;

            a {
              color: #5D6C85;
              display: flex;
              align-items: center;
              line-height: 24px;

              svg {
                display: none;
              }

              &:hover {
                color: darken(#5D6C85, 10);
              }
            }

            &.active {

              a {
                font-weight: 700;

                &:hover {
                  color: #5D6C85;
                }

                svg {
                  display: block;
                }
              }
            }
          }
        }

        .back {
          color: #5D6C85;
          display: flex;
          align-items: center;
          margin-top: 30px;
          font-size: 0.875rem;
          border-top: 1px solid #EBECF0;
          padding-top: 10px;

          &:hover {
            color: #000;
          }
        }
      }

      >main {
        flex: 1;
      }
    }
  }

  &__no-data {
    padding: 50px 20px;
    border-radius: 12px;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    p {
      //styleName: Header/H2;
      font-size: 24px;
      font-weight: 600;
      line-height: 32px;
      text-align: center;
      color: $text-purple;
      text-align: center;
    }
  }
}