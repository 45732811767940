@use '../abstracts/variables_new.scss' as *;

.mobile-menu {
  position: fixed;
  top: 0px;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: $bg-color-1;
  overflow-y: auto;
  z-index: 20;
  display: flex;
  flex-direction: column;

  .mobile-header {
    svg {
      fill: #fff
    }
  }

  > ul {
    padding: 0 16px;

    li {
      .hr {
        height: 1px;
        background: #5c417a;
        margin: 1rem 0;
      }

      a {
        display: flex;
        align-items: center;
        padding-left: 8px;
        position: relative;
        color: $primary-color;
        font-size: 14px;
        font-weight: 600;
        line-height: 1.35;
        height: 48px;
        border-radius: 8px;
        gap: 8px;

        >span {
          width: 24px;
          height: 48px;
          display: flex;
          align-items: center;
          justify-content: center;

          svg {
            width: 24px;
            height: 24px;
            fill: $primary-color;
          }

          span.icon-wrapper {
            width: 33px;
            height: 33px;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 50%;
            background-color: $primary-color;
            color: #fff;
            font-size: 1.6875rem;
            line-height: 1;
            font-weight: 500;
          }
        }

        &.active {
          background-color: $bg-hover !important;
          color: $color-indigo !important;

          svg {
            fill: $color-indigo;
          }
        }
      }
    }
  }

  .link {
    width: 100%;
    height: 48px;
    padding: 0px 16px;
    gap: 8px;
    border-radius: 24px;
    color: #fff;
    background: $color-indigo;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    font-weight: 600;
    margin-bottom: 8px;

    svg {
      fill: #fff
    }
  }

  &__bottom {
    margin-top: auto;
    padding: 24px;
    border-radius: 50px 50px 0 0;
    background: $primary-color;
  }

}

.update {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4px;
  font-size: 12px;
  font-weight: 500;
  color: $text-purple;
  padding-right: 16px;
  margin-right: 8px;
  position: relative;
  height: 24px;

  svg {
    margin-right: 5px;
  }

  .text {
    white-space: nowrap;
  }

  &::after {
    content: "";
    width: 2px;
    height: 16px;
    background: $bg-default;
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
  }

  @media (max-width: $break-lg-max) {
    margin-right: 56px;

    &::after {
      display: none;
    }

    &.homepage {
      margin-right: 0px;
    }
  }

  @media (max-width: $break-md-max) {
    margin-right: 0;
  }

  .date {
    color: $color-indigo;
  }

  svg {
    width: 16px;
  }
}