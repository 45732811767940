@use '../abstracts/variables_new.scss' as *;

.letter-circle-v2 {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 22px;
  height: 22px;
  border: 1px solid $primary-color;
  font-size: 0.75rem;
  text-transform: uppercase;
  border-radius: 100%;

  &.active {
    background-color: $primary-color;
    color: #fff;
  }
}