@use '../abstracts/variables_new.scss' as *;

.single-document-mobile-nav {
    display: none;
    align-items: center;
    // position: fixed;
    bottom: 64px;
    height: 50px;
    width: 100%;
    min-width: 320px;
    background-color: $bg-color-1;
    box-shadow: 0 3px 17px 0 rgba(#000, 0.16);
    z-index: 3;

    @media (max-width: $break-md-max) {
        display: flex;
    }

    a {
        display: flex;
        align-items: center;
        justify-content: center;
        width: calc(100% / 4);
        min-width: calc(100% / 4);
        height: 75%;

        &:nth-child(1) {
            svg {
                width: 31px;
            }
        }

        &:nth-child(2) {
            border-left: 3px solid #fff;
            border-right: 3px solid #fff;

            svg {
                width: 27px;
            }
        }

        &:nth-child(3) {
            svg {
                width: 29px;
            }
        }

        &:nth-child(4) {
            border-left: 3px solid #fff;

            svg {
                width: 27px;
            }
        }

        &.active {
            background-color: $bg-color-5;
        }
    }
}
