@use '../abstracts/variables_new.scss' as *;

.tox-tinymce {
  border: 0;
}

.tox-sidebar-wrap {
  border: 1px solid $bg-hover !important;
  border-radius: 8px !important;
  margin-top: 4px !important;
  overflow: hidden;
}

.tox .tox-tbtn {
  color: $color-indigo;
}

.tox .tox-tbtn--disabled svg,
.tox .tox-tbtn--disabled:hover svg,
.tox .tox-tbtn:disabled svg,
.tox .tox-tbtn:disabled:hover svg {
  fill: rgba($color-indigo, .5);
}

.tox .tox-tbtn svg {
  fill: $color-indigo !important;
}

.tox-statusbar {
  display: none !important;
}

.tox-tinymce {
  border: 0 !important;
}

.tox-toolbar__primary {
  background: $bg-purple1 !important;
  border-radius: 4px;
}

.document-share {

  &__body {
    padding: 24px;

    .description-box {
      max-width: 618px;
      h3 {
        //styleName: Header/H3;
        font-size: 18px;
        font-weight: 600;
        line-height: 24px;
        text-align: left;
        margin-bottom: 4px;
      }

      p {
        //styleName: Body/Body L - Bold;
        font-size: 14px;
        font-weight: 500;
        line-height: 20px;
      }
    }

    @media (max-width: $break-md-max) {
      padding: 16px;

      .description-box {
        background: $bg-purple2;
        padding: 8px;
        margin-bottom: 24px;

        h3 {
          font-size: 14px;
          line-height: 16px;
          margin-bottom: 8px;
        }
      }

    }

    .share-form {
      display: flex;
      flex-wrap: wrap;
      margin: 0 -8px;
      &__field-wrapper {
        position: relative;
        padding: 0 8px;
        .rdw-editor-main {
          height: 235px;
        }
        &.with-icon {
          padding-right: 48px;

          a.icon {
            position: absolute;
            top: 22px;
            right: 0;
            width: 40px;
            height: 40px;
            display: flex;
            align-items: center;
            justify-content: center;
            background: $bg-default;
            border-radius: 50px;

          }
        }
      }

      .checkbox-add-watermark {
        margin-top: 20px;
      }
    }
  }

  .buttons-row-mobile {
    display: none;
  }

  @media (max-width: $break-md-max) {
    .fold-btn-mobile {
      display: flex;
    }

    background: #fff;
    position: fixed;
    left: 0;
    top: 75px;
    width: 100%;
    height: calc(100% - 75px);
    border-radius: 50px 50px 0 0;
    overflow: auto;

    .share-form__field-wrapper {
      width: 100% !important;
    }

    .folder-modal-v2__heading {
      background: none;
      padding: 32px 16px 0;

      .buttons-row {
        display: none;
      }
    }

    .buttons-row-mobile {
      display: flex;
      margin-top: 10px;
      flex-direction: column;
      align-items: flex-start;
      gap: 10px;
    }

    .document-share__body {}
  }
}