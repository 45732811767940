@use '../abstracts/variables_new.scss' as *;

.file-upload-dnd {

  input[type="file"] {
    display: none;
  }

  &__box {
    border: 2px dashed $primary-color;
    border-radius: 5px;
    background-color: #fff;
    min-height: 84px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
    text-align: center;
    gap: 10px;
    padding: 0 16px;

    &.height-sm {
      height: 80px;
    }

    &.height-md {
      height: 116px;
    }

    &.height-lg {
      height: 152px;
    }

    @media (max-width: $break-md-max) {
      height: 128px;
      flex-wrap: wrap;
      align-items: center;
      justify-content: center;
      padding: 16px;
      height: initial !important;
    }

    button {
      .svg-icon {
        position: absolute;
        top: 0;
        left: 0;
        width: 26px;
        height: 26px;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: $primary-color;
        border-radius: 13px;

        svg {
          fill: #fff;
          width: 11px;
        }

        @media (max-width: $break-md-max) {
          width: 32px;
          height: 32px;
          border-radius: 20px;

          svg {
            width: 14px;
          }
        }
      }
    }

    p {
      font-size: 14px;
      line-height: 1.15;

      @media (max-width: $break-md-max) {
        display: none;
      }
    }

    .image-preview {
      width: 150px;
      height: 100px;
      position: relative;
      padding: 5px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 5px;
      background-color: $bg-color-1;

      @media (max-width: $break-md-max) {
        height: 80px;
      }

      img {
        display: block;
        max-width: 100%;
        max-height: 100%;
      }

      &__close {
        position: absolute;
        width: 24px;
        height: 24px;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: $highlighted-color-3;
        border-radius: 12px;
        right: -12px;
        top: -12px;
        cursor: pointer;

        svg {
          fill: #fff;
          width: 8px;
        }
      }
    }
  }

  &__note {
    margin-top: 4px;
    font-size: 11px;
    color: $text-purple;
  }

  &__title {
    font-size: 14px;
    font-weight: 600;
    line-height: 16px;
    color: $text-color-1;
  }

  &__description {
    font-size: 11px;
    font-weight: 500;
    line-height: 16px;
    text-align: left;
    color: $color-indigo;
  }

  &__info {
    margin: 0 17px 0 24px;
  }

  &__buttons {
    display: flex;
    gap: 8px;

    svg {
      width: 18px;
    }
  }
}