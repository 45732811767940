@use '../abstracts/variables_new.scss' as *;
@use '../abstracts/mixins.scss' as *;

.templates-content {
  @include collectionContent;
  overflow: auto;

  @include hideScrollbar();

  /* Firefox */
  &__main {
    padding: 16px 24px;
    height: 100%;

    @media (max-width: $break-md-max) {
      overflow: auto;
      padding: 16px;
      background: #fff;
      border-radius: 14px 14px 0 0;
      position: relative;
    }

    .templates-list {
      display: flex;
      flex-wrap: wrap;
      width: calc(100% + 14px);

      @media (max-width: $break-md-max) {
        display: none;
      }

      .template-box {
        width: 248px;
        min-width: 248px;
        margin-right: 14px;
        margin-bottom: 21px;
        background-color: #fff;
        box-shadow: 0 0 5px 0 rgba(#000, 0.16);
        border-radius: 5px;
        display: flex;
        flex-direction: column;
        position: relative;

        &__icon {
          background-color: $primary-color;
          border-radius: 5px 5px 0 0;
          padding: 24px;
          display: flex;
          align-items: center;
          justify-content: center;

          svg {
            box-shadow: 0 3px 25px 0 rgba(#000, 0.16);
          }
        }

        &__info {
          padding: 7px 12px;
          display: flex;
          flex-direction: column;
          justify-content: center;
          flex-grow: 1;

          h3 {
            font-weight: 600;
            font-size: 0.875rem;
            line-height: 1.17;
            color: $primary-color;
            margin-bottom: 1px;
          }

          p {
            font-weight: 300;
            font-size: 0.75rem;
            line-height: 1.4;
            color: $text-color-1;
          }
        }

        &__hover {
          background-color: rgba($primary-color, 0.5);
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          padding: 10px;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 5px;
          transition: opacity .3s ease;
          opacity: 0;
          backface-visibility: hidden;

          .icon-btn {

            &:not(:last-child) {
              margin-right: 10px;
            }
          }
        }

        &:hover {

          .template-box__hover {
            opacity: 1;
          }
        }
      }
    }

    &_mobile {
      display: none;
      padding-bottom: 64px;

      @media (max-width: $break-md-max) {
        display: block;
      }
    }
  }

  .breadcrumb {
    display: none !important;
    font-size: 12px;
    font-weight: 500;
    line-height: 16px;
    color: $color-indigo;
    flex-wrap: wrap;

    li {
      white-space: nowrap;
    }
  }

  @media (max-width: $break-md-max) {
    .breadcrumb {
      display: flex !important;
    }
  }

  &__sidebar {
    display: flex;
    flex-direction: column;

    .sidebar-folders {
      padding-bottom: 100px;
      height: 100%;

      @media (max-width: $break-md-max) {
        overflow-y: initial;
        padding-bottom: 0px;

      }
    }
  }
}