@use '../abstracts/variables_new.scss' as *;
@use '../abstracts/mixins.scss' as *;

.search-bar {
  max-width: 511px;
  width: 100%;
  margin-right: 16px;

  &.hide-in-homepage-desktop {
    @media (min-width: $break-md-min) {
      display: none;
    }
  }

  &.expandable {
    max-width: 221px;
    width: 40px;
    cursor: pointer;

    &.expanded {
      width: 100%;

      .search-bar__body:focus-within {
        background-color: $bg-selected;
      }

      input {
        caret-color: #E8025F;
      }
    }


  }

  @media (max-width: $break-md-max) {
    width: 0%;
  }

  &__mobile {
    display: none;

    &.active {
      width: 100%;
      right: 0px;
      display: flex;

    }

    position: absolute;
    right: 218px;
    top: 22px;

    @media (max-width: $break-md-max) {
      position: absolute;
      right: 66px;
      top: 16px;
      z-index: 100;
    }

    .link {
      z-index: 100;
      position: relative;
      left: 20px;
    }

    input {
      margin-left: -20px;
      padding-left: 50px;
      border-radius: 21px;
      border: none;
      z-index: 10;
      width: 100%;
      height: 40px;
      outline: 0;

      &:focus {
        border: none;
      }
    }

    .close-btn {
      position: absolute;
      top: 5px;
      right: 16px;
      width: 28px;
      height: 28px;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      transition: background-color 0.3s ease;
      z-index: 10;

      svg {
        width: 10px;
        transition: fill 0.3s ease;
      }

      &:hover {
        background-color: $primary-color;

        svg {
          fill: #fff;
        }
      }

      &:active {
        background-color: darken($primary-color, 5);

        svg {
          fill: #fff;
        }
      }
    }
  }

  &__body {
    position: relative;
    padding-left: 30px;
    background-color: #fff;
    border: 1px solid $bg-hover;
    border-radius: 50px;
    height: 40px;
    width: 100%;
    display: flex;
    align-items: center;

    &.expandable {
      background-color: $bg-default;

      &:hover {
        background-color: $bg-hover;
      }
    }

    &:active {
      background: $bg-selected;
    }

    @media (max-width: $break-lg-max) {
      display: none;
    }

    &_icon {
      position: absolute;
      top: 50%;
      left: 8px;
      width: 24px;
      height: 24px;
      display: flex;
      align-items: center;
      justify-content: center;
      transform: translateY(-50%);

      &.cursor-pointer {
        cursor: pointer;
      }

      svg {
        display: block;
        color: $color-indigo;
        width: 20px;
      }

      &_end {
        left: unset;
        right: 3px;
        width: 24px;
        height: 24px;
        display: flex;
        align-items: center;
        justify-content: center;
        transform: translateY(-50%);
        cursor: pointer;

        svg {
          display: block;
          color: $color-indigo;
        }
      }
    }

    input {
      padding: 9.5px 5px 9.5px 10px;
      border: 0;
      outline: 0;
      display: block;
      width: 100%;
      background-color: transparent;
      font-size: 14px;
      font-weight: 600;
      color: $color-indigo;

      // &:focus {
      //   background: $bg-selected;
      // }

      @include placeholder {
        color: $text-purple;
      }
    }
  }

  &__history-wrapper {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    padding-top: 40px;
    width: 100%;
    z-index: -1;
    border-radius: 13px;
    background-color: white;
    -webkit-box-shadow: 2px 2px 5px 0px rgba(0, 0, 0, 0.25);
    -moz-box-shadow: 2px 2px 5px 0px rgba(0, 0, 0, 0.25);
    box-shadow: 2px 2px 5px 0px rgba(0, 0, 0, 0.25);

    &_row {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      padding: 4px 12px;

      span {
        cursor: pointer;
      }
    }
  }
}

.search-trigger {
  z-index: 20;
  width: 40px;
  height: 40px;
  border-radius: 20px;
  background: $bg-hover;
  border: 2px solid $bg-hover;
  display: flex;
  align-items: center;
  justify-content: center;

  @media (max-width: $break-md-max) {
    display: none;
  }

  &.mobile {
    display: flex;
    margin-left: auto;

    @media (min-width: $break-md-min) {
      display: none;
    }
  }

  svg {
    width: 14px;
    stroke: $color-indigo;
  }

  &:hover {
    background: $color-indigo;
    border: 2px solid $color-indigo;

    svg {
      fill: #fff;
      stroke: #fff;

    }
  }
}

mark {
  background-color: #62FF8E;
}

.search-results {
  font-size: 14px;
  font-weight: 200;
}

.prev {
  svg {
    width: 8px;
  }
}

.next {
  svg {
    rotate: 180deg;
    width: 8px
  }
}