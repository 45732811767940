@use '../abstracts/variables' as *;

.signature-modal {

  .modal__content {
    padding: 40px 0 0;
    border-radius: 8px;
    max-width: 1191px;
  }

  &-inner {

    h2 {
      font-size: 1.4375rem;
      font-weight: 500;
      letter-spacing: 0.29px;
      color: $secondary-text-color;
      text-align: center;
      margin-bottom: 42px;
      padding: 0 10px;
    }

    &__head {
      padding: 0 40px;
      width: calc(100% + 80px);
      margin-left: -40px;

      @media (max-width: $break-sm-max) {
        padding: 0 20px;
        width: calc(100% + 40px);
        margin-left: -20px;
      }

      ul {
        display: flex;
        justify-content: center;
        align-items: center;
        border-bottom: 2px solid $nonary-border-color;

        li {
          color: rgba($tertiary-text-color, 0.69);
          font-size: 0.8125rem;
          font-weight: 500;
          padding-bottom: 13px;
          text-align: center;
          text-transform: uppercase;
          cursor: pointer;
          margin: 0 25px;
          position: relative;

          &.no-cursor {
            cursor: default;
          }

          &.active {
            color: $primary-color;

            &::after {
              content: '';
              position: absolute;
              bottom: -2px;
              left: 0;
              width: 100%;
              height: 2px;
              background-color: $primary-color;
            }
          }
        }
      }
    }

    &__body {
      padding: 30px 40px 0;

      @media (max-width: $break-sm-max) {
        padding: 30px 20px 0;
      }

      .form {
        padding-top: 16px;

        &__group {
          margin-bottom: 22px;
        }

        .form-input {
          margin-bottom: 0;

          &__field {
            height: 38px;
          }
        }

        &__subtitle {
          font-size: 1.125rem;
          color: $secondary-bg-color;
          margin-bottom: 20px;
          display: flex;
          align-items: center;
          justify-content: space-between;
          max-width: 711px;
          position: relative;

          &--2 {
            margin-bottom: 15px;
          }

          &--3 {
            margin-bottom: 10px;
          }

          .icon-button {
            width: 50px;
            height: 38px;
            align-self: flex-end;
          }

          .users-dropdown {
            min-width: 280px;
            max-height: 185px;
            overflow-y: auto;
            overflow-x: hidden;
            // padding-top: 50px !important;

            .form-input {
              position: sticky;
              top: -10px;
              width: 100%;
              margin-bottom: 0;
              margin-left: -10px;
              margin-top: -10px;
              width: calc(100% + 20px);
              padding: 10px;
              background-color: $primary-light-color;

              &__field {
                height: auto;
              }
            }

            p {
              font-size: 0.875rem;
              color: $secondary-text-color;
              font-weight: 500;
              padding: 5px;
              border-radius: 2px;
              cursor: pointer;
              transition: background-color .3s ease;

              &:hover {
                background-color: $primary-bg-color;
              }

              &.no-data {
                cursor: default;

                &:hover {
                  background-color: transparent;
                }
              }
            }
          }
        }

        &__actions {
          padding-top: 0;
          padding-bottom: 48px;

          .button {
            margin: 0 17px;

            &--primary {
              min-width: 142px;
              text-transform: uppercase;
              font-size: 0.75rem;
              letter-spacing: 0.3px;
            }

            &--transparent {
              font-size: 0.9375rem;
              font-weight: 400;
              color: $nonary-text-color;
            }
          }
        }

        .message {
          margin-bottom: 20px;
        }
      }
    }

    &__section {
      margin-bottom: 60px;

      &--checkbox {
        margin-bottom: 32px;
      }
    }

    .form {

      &__group {

        &--signature-modal {
          justify-content: space-between;
          max-width: 711px;
          margin-bottom: 25px !important;
          flex-wrap: wrap;

          &.form__group--dropdown {

            .custom-select {
              margin-bottom: 0;
              max-width: 340px;
              flex: 1;

              &__selected {
                height: 38px;
              }

              .select-dropdown {
                max-height: 167px;
                overflow-y: auto;
              }
            }
          }

          .form__error {
            width: 100%;
            margin-top: 10px;
          }

          @media (max-width: 900px) {
            display: block;

            .form-input {
              margin-bottom: 25px !important;
            }
          }
        }

        &_actions {
          max-width: 711px;


          &-bottom {
            display: flex;
            margin-top: 30px;
            justify-content: space-between;

            .align-right {
              margin-left: auto;
            }

            @media (max-width: $break-xs-max) {
              display: block;

              .delete-btn {
                margin-top: 10px;
              }
            }
          }

          .edit-btn {
            margin-right: 20px;
          }

          .delete-btn {
            width: 36px;
            height: 36px;
            color: $nonary-text-color;
            transition: color 0.3s ease, background-color 0.3s ease;

            &:hover {
              color: $secondary-color-light;
              background-color: rgba($secondary-color-light, 0.1);
            }
          }
        }

        .form-input {
          max-width: 340px;
        }
      }

      &__error {
        margin-bottom: -20px;
      }

      &__fields-group {
        margin-bottom: 30px;
      }

      .button--primary-light {
        font-weight: 400;
      }

      .field-positions {

        .delete-btn {
          width: 36px;
          height: 36px;
          color: $nonary-text-color;
          transition: color 0.3s ease, background-color 0.3s ease;

          &--2 {
            margin-left: auto;
          }

          &:hover {
            color: $secondary-color-light;
            background-color: rgba($secondary-color-light, 0.1);
          }
        }
      }
    }
  }

  .signatures-success {
    padding: 0 40px;
  }

  &__template {

    .checkbox-wrapper {
      margin-bottom: 16px;
    }

    &_save {

      .form-input {
        margin-bottom: 14px !important;
        max-width: 340px;
      }
    }

    &_choose {
      display: flex;
      align-items: center;

      .custom-select {
        max-width: 340px;
        width: 100%;
        margin-right: 15px;
        margin-bottom: 0;
      }

      a {
        color: $primary-color;
        font-size: 0.875rem;
      }
    }

    &_update {
      display: flex;
      align-items: center;
      flex-wrap: wrap;

      .button {

        &:first-child {
          margin-right: 15px;
        }
      }
    }
  }

  .credits-wrapper {
    display: flex;
    align-items: center;

    .loader {
      margin-left: 5px;
    }
  }
}