@use '../abstracts/variables' as *;

.auth {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 100px;

  &__box {
    max-width: 385px;
    width: 100%;

    &_top {
      margin-bottom: 30px;

      h1 {
        text-align: center;
        font-size: 32px;
        font-weight: 700;
        color: $tertiary-text-color;
      }
    }

    &_main {
      background-color: $primary-bg-color;
      padding: 35px 32px 31px;
      border-radius: 6px;

      p {
        font-weight: 500;
        color: $septenary-text-color;
        margin-bottom: 16px;
      }
    }
  }
}