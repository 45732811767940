@use '../abstracts/variables_new.scss' as *;

body {
  font-family: $ff-primary;
}

h1 {
  font-size: 2.25rem;
  font-weight: 500;
}

h2 {
  font-size: 1.375rem;
  font-weight: 700;
}

h3 {
  font-weight: 700;
  font-size: 1.25rem;
  color: $color-indigo;
}

h4 {
  font-size: 1.125rem;
  color: $tertiary-text-color;
}

h5 {
  font-size: 1rem;
  font-weight: 700;
}

h6 {
  font-size: 0.75rem;
  color: $nonary-text-color;
  text-transform: uppercase;
}

.note {
  font-size: 0.8rem;
  font-weight: 200;
}