@use '../abstracts/variables_new.scss' as *;

.signature-buttons {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;

  .btn {
    margin: 5px;
  }
}