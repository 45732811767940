@use '../abstracts/variables_new.scss' as *;

.qr-code-section-v2 {

  &__inner {
    display: flex;
    align-items: center;

    @media (max-width: $break-sm-max) {
      flex-wrap: wrap;
    }
  }

  &__left {
    flex: 1;
    margin-right: 15px;
    text-align: center;
    min-width: 50%;

    @media (max-width: $break-sm-max) {
      min-width: 100%;
      margin-bottom: 30px;
    }

    p {
      margin-bottom: 20px;
      word-break: break-all;

      @media (max-width: $break-md-max) {
        font-size: 0.9375rem;
      }
    }
  }

  &__right {
    flex: 1;
    min-width: 50%;

    @media (max-width: $break-sm-max) {
      min-width: 100%;
      margin-bottom: 30px;
    }

    p {
      line-height: 2;
      margin-bottom: 10px;
      text-align: center;

      @media (max-width: $break-md-max) {
        font-size: 0.9375rem;
      }
    }

    canvas {
      display: block;
      margin: 0 auto;
    }

    img {
      display: block;
      margin: 0 auto;
    }
  }
}