@use '../abstracts/variables_new.scss' as *;

.help-category {
  height: 100%;
  padding-bottom: 30px;
  background-color: $bg-purple2;
  padding: 16px 24px;
  gap: 24px;
  border-radius: 12px 12px 0px 0px;

  &__head {
    margin-bottom: 20px;
    position: relative;

    h2 {
      font-size: 1.875rem;
      margin-bottom: 15px;
      font-weight: 500;
      padding-right: 150px;
    }

    .sort-select {
      position: absolute;
      top: 3px;
      right: 0;
      width: 140px;
      margin-bottom: 0;

      .custom-select__selected {
        height: 28px;
        padding: 0 20px 0 5px;

        .text {
          font-size: 0.75rem;
        }

        .icon {

          svg {
            font-size: 1rem;
          }
        }
      }

      .select-dropdown {
        left: auto;
        right: 0;
      }
    }

    &_desc {
      border: 1px solid #EBECF0;
      background-color: #F5F6F8;
      padding: 10px;

      p {
        font-size: 0.875rem;
      }
    }
  }

  &__body {
    height: 100%;

    ul {

      li {
        margin-bottom: 5px;
        background: #FFFFFF;
        padding: 16px;
        gap: 16px;
        border-radius: 4px;

        @media (max-width: $break-xs-max) {
          display: block;
        }

        .title {
          display: inline-flex;
          align-items: center;
          //styleName: Header/H3;
          font-size: 18px;
          font-weight: 600;
          line-height: 24px;
          text-align: left;
          color: #000;

          &:hover {
            text-decoration: underline;
          }
        }

        .description {
          //styleName: Body/Body M - Bold;
          font-size: 12px;
          font-weight: 500;
          line-height: 16px;
          text-align: left;
          color: $text-color-1;
        }

      }
    }
  }
}