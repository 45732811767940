@use '../abstracts/variables_new.scss' as *;

.signatures-not-available-v2 {
  text-align: center;
  padding: 30px;

  h2 {
    font-weight: 600;
    font-size: 1.25rem;
    margin-bottom: 20px;
  }

  p {
    font-size: 0.875rem;
    margin-bottom: 5px;

    &:last-child {
      margin-bottom: 0;
    }
  }
}