@use '../abstracts/variables_new.scss' as *;
@use '../abstracts/mixins' as *;

.textarea {
  textarea:disabled {
    background-color: gainsboro !important;
  }

  &--edit-mode {

    .textarea__field {
      background-color: rgba($primary-color, 0.12);
      border-radius: 3px;
      padding: 5px 10px 4px;
      height: 94px;
      min-height: 46px;
      width: 100%;
      // resize: none;
    }
  }

  &__label {
    font-size: 18px;
    font-weight: 600;
    line-height: 24px;
    text-align: center;
    margin-bottom: 16px;
    color: $color-indigo;
    text-align: center;
    display: block;
  }

  &__field {
    border: 0;
    outline: 0;
    border-radius: 4px;
    background-color: #fff;
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
    color: $color-indigo;
    border: 2px solid $bg-hover;
    padding: 10px 16px;
    width: 100%;
    display: block;

    @include placeholder {
      color: rgba($text-purple, 0.4);
    }
  }

  &--dashboard-modal {

    .textarea__field {
      background-color: transparent;
      border: 1px solid $bg-hover;
      font-weight: 300;
      color: $primary-text-color;
      padding: 5px;
      min-height: 450px;
      max-height: 450px;

      @include placeholder {
        font-size: 0.75rem;
        color: $text-purple;
      }
    }
  }
}