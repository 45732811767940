@use '../abstracts/variables.scss' as *;

.pagination {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px 100px;
  font-size: 0.875rem;
  position: relative;

  @media (max-width: $break-xs-max) {
    justify-content: flex-end;
    padding: 20px;
  }

  &__body {
    margin: 0 10px;
    font-size: 0.875rem;

    @media (max-width: $break-xxs-max) {
      font-size: 0.75rem;
    }
  }

  button {
    cursor: pointer;
    background-color: transparent;
    border: 0;
    outline: 0;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: $secondary-text-color;

    &:disabled {
      color: $quaternary-text-color;
      cursor: not-allowed;
    }

    svg {
      display: block;
    }

    &:hover {
      background-color: #F5F6F8;
    }

    &.trigger {
      border: 1px solid rgba($primary-color, 0.3);
      padding: 5px 5px 5px 10px;
      width: 80px;
      justify-content: space-between;
      position: absolute;
      top: 50%;
      left: 20px;
      transform: translateY(-50%);

      @media (max-width: $break-xxs-max) {
        width: 60px;
      }

      span {
        display: block;
        border-left: 1px solid rgba($primary-color, 0.3);
        color: $quaternary-text-color;
        
        svg {
          font-size: 1.25rem;
        }
      }
    }
  }
}

.pagination-dropdown {

  .actions-dropdown__body {

    ul {
  
      li {
        margin-bottom: 4px;

        &:last-child {
          margin-bottom: 0;
        }
  
        .link a{
          justify-content: center;
          font-size: 0.75rem;
          min-height: 20px;
          padding: 4px;
        }
      }
    }
  }

}