@use '../abstracts/variables.scss' as *;

.agency-box {
  box-shadow: 0 0 10px rgba(0,0,0,0.1);
  border-radius: 10px;
  margin-bottom: 40px;
  display: flex;
  flex-direction: column;

  &__head {
    background: rgb(244,102,111);
    background: linear-gradient(90deg, rgba(244,102,111,1) 0%, rgba(230,177,105,1) 100%);
    padding: 30px 130px 30px 20px;
    border-radius: 10px 10px 0 0;
    position: relative;

    h3 {
      color: #fff;
      font-weight: 300;

      span {
        display: block;
      }
    }
    
    .button {
      position: absolute;
      top: 50%;
      right: 20px;
      transform: translateY(-50%);
    }

    @media (max-width: $break-xs-max) {
      padding-right: 20px;

      .button {
        position: static;
        transform: translateY(0);
        margin-top: 10px;
      }
    }
  }

  &__body {
    padding: 20px;
    border-radius: 0 0 10px 10px;
    background-color: #fff;
    flex: 1;

    p {
      margin-bottom: 5px;
      font-size: 0.875rem;

      strong {
        font-weight: 500;
      }

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}