@use '../abstracts/variables' as *;

.history-box {
  background-color: $primary-light-color;
  box-shadow: 0 1px 2px 0 rgba(0,0,0,0.21);
  padding: 26px 42px 20px 44px;
  border-radius: 3px;
  margin-bottom: 30px;
  position: relative;
  z-index: 1;

  header {
    display: flex;

    @media (max-width: $break-md-max) {
      display: block;

      .history-box__time {
        margin-bottom: 20px;
      }

      .history-box__progress {
        margin-bottom: 20px;
      }
    }
  }

  &__time {
    font-size: 0.8125rem;
    color: rgba($tertiary-text-color, 0.69);
    width: 86px;
    padding-right: 10px;
    padding-top: 9px;
  }

  &__progress {

    p {

      &.main {
        font-size: 0.9375rem;
        color: $octonary-text-color;
        margin-bottom: 4px;
      }

      &.number-of-entries {
        font-size: 0.8125rem;
        font-weight: 500;
      }
    }

    .progress-bar {
      width: 190px;
      max-width: 100%;
      height: 4px;
      border-radius: 3px;
      position: relative;
      background-color: rgba($primary-color, 0.2);
      margin-bottom: 12px;

      div {
        background-color: $primary-color;
        position: absolute;
        left: 0;
        top: 0;
        height: 100%;
        border-radius: 3px;
      }
    }
  }

  &__actions {
    margin-left: auto;
    padding-top: 9px;

    .button {
      margin-bottom: 10px;

      &:first-child {
        margin-right: 13px;
      }
    }
  }

  &__body {
    padding-top: 44px;
    padding-bottom: 20px;

    .group {
      display: flex;
      align-items: center;
      margin-bottom: 10px;

      @media (max-width: $break-md-max) {
        display: block;
      }

      &:last-child {
        margin-bottom: 0;
      }

      p {

        &.label {
          width: 220px;
          margin-right: 15px;
          font-weight: 400;
          font-size: 0.875rem;
          color: $septenary-text-color;
        }

        &.input {
          font-size: 1rem;
          font-weight: 700;
          color: $septenary-text-color;
          padding: 2px 0 1px;
          border-bottom: 1px solid $senary-border-color;
          flex: 1;
          padding: 8px 0;
        }
      }
    }
  }

  &--template {

    header {
      align-items: center;
    }

    .history-box__time {
      padding-top: 0;
    }

    .history-box__progress {

      p {
        margin-bottom: 0;
      }
    }
  }
}