@use '../abstracts/variables' as *;

.dashboard-inner {
  display: flex;
  flex-grow: 1;
  padding-left: 229px;

  @media (max-width: $break-lg-max) {
    padding-left: 0;
  }

  &.blured {

    >* {
      filter: blur(4px);
    }

    .dashboard-view__head {
      left: 0;
      width: 100%;
    }
  }

  &__left {
    position: fixed;
    top: 0;
    left: 52px;
    width: 229px;
    height: 100%;
    overflow-y: auto;
    transition: left .3s ease;
    z-index: 1;

    &_sidebar {
      background-color: $primary-bg-color;
      padding: 11px 18px;
      border-right: 1px solid $primary-border-color;
      height: 100%;
      z-index: 1;
      position: relative;
    }

    &_overlay {
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba($secondary-bg-color, 0.5);
      display: none;
    }

    h3 {
      margin-bottom: 13px;
    }

    .current {
      display: flex;
      align-items: center;
      margin: 30px 0;

      p {
        font-size: 0.875rem;
        font-weight: 700;
        color: $tertiary-text-color;
      }

      svg {
        display: block;
        margin-right: 8px;
      }
    }

    @media (max-width: $break-lg-max) {
      position: fixed;
      left: -229px;
      top: 0;
      height: 100%;
      z-index: 1004;

      &.opened {
        left: 0;

        .dashboard-inner__left_overlay {
          display: block;
        }
      }
    }
  }

  &__right {
    flex: 1;
  }
}
