@use '../abstracts/variables' as *;

.side-panel {
  display: flex;
  z-index: 10;

  &__menu {
    width: 55px;
    border-right: 1px solid $secondary-border-color;
    padding-top: 46px;
    padding: 46px 15px 5px;
    display: flex;
    flex-direction: column;

    .trigger {
      font-size: 1.5rem;
      color: rgba($primary-dark-color, 0.2);
      cursor: pointer;
      margin-bottom: 15px;
      transform: rotate(180deg);
      transition: color .3s ease, transform .9s ease;

      &:hover {
        color: $primary-color;
      }
    }

    ul {
      border-top: 1px solid rgba($primary-dark-color, 0.2);
      padding-top: 20px;

      li {
        font-size: 1.5rem;
        margin-bottom: 18px;

        svg {
          display: block;
        }

        a {
          color: rgba($primary-dark-color, 0.2);
          transition: color .3s ease;

          &:hover,
          &.active {
            color: $primary-color;

            span.badge {
              background-color: $primary-color;
            }
          }

          span.badge-wrap {
            position: relative;
            display: block;
          }

          span.badge {
            display: block;
            width: 14px;
            height: 14px;
            border-radius: 50%;
            background-color: $senary-color;
            display: flex;
            align-items: center;
            justify-content: center;
            position: absolute;
            top: 0;
            right: -3px;
            font-size: 9px;
            font-weight: 700;
            color: $primary-light-color;
            transform: translateY(-50%);
            transition: background-color .3s ease;
          }
        }
      }
    }

    .back-to-top {
      margin-top: auto;
    }
  }

  &__main {
    flex: 1;
    overflow-y: auto;
    /* SCROLLING TASK */
    // overflow-y: visible;
    // height: 100%; 
    /* SCROLLING TASK */
  }

  &--2 {

    .side-panel__menu {
      height: calc(100% - 75px);
    }
  }
}